hwecApp.controller('dashboardController',['$scope', '$http', '$location', function($scope, $http, $location) {
	 
    $http({
        method: 'GET',
        url: '/api/getAuthenticatedUser?token=' + localStorage.getItem('userToken'),
        headers: {'Content-Type': 'application/json'}
    }).success(function(data) {
 
    }).error(function(data) {        
            if(data.error) { window.location.href = "#/logout" };
        }); 
  
}]); 


 


 