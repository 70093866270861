hwecApp.controller('viewController', ['$http','$scope', '$location', 'FAILURE_CODE','PRODUCT_PICTURE_PATH', function($http, $scope, $location, FAILURE_CODE,PRODUCT_PICTURE_PATH) {

    $scope.checkUserRol = function(){

        if($scope.user_role_id === 7 || $scope.user_role_id === 5){
            return false;
        } else {
            return true;
        }

    };


///////////////////////////////Check current user logged //////////////////////////
///////////////////////////////////////////////////////////////////////////////////
    $scope.$user_id = localStorage.getItem('user_id');
    $http({
        method: 'GET',
        url: 'api/getAuthenticatedUser?token=' + localStorage.getItem('userToken'),
        data:null,
        headers: {'Content-Type': 'application/json'}
    }).success(function(data) {
        $scope.user_role_id = data["role"];
    }).error(function(data) {
        if(data.error) { window.location.href = "#/logout" };
    })

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

	
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader"); 

    $scope.getRealTimeSlot = ['08:00:00', '09:00:00' ,'10:00:00','11:00:00','11:30:00', '12:00:00', '13:00:00', '14:00:00','14:30:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00'];

    $scope.hide_button = false;
    $scope.hide_button_updating = true;
    $scope.message = false;

    $scope.addNoteCompletedAppointment = function(){

                    //$scope.hide_button = true;

                    $scope.items.scheduler_comments;
                    $scope.items.appointment_id;

                    $scope.addNoteCompletedAppointmentData = {
                        "method_name": "addNoteToCompletedAppointment",
                        "appointment_id":$scope.items.appointment_id,
                        "sheduller_comments":$scope.items.scheduler_comments
                    };
                    $scope.addNoteCompletedAppointmentData = JSON.stringify($scope.addNoteCompletedAppointmentData);
                    console.log($scope.addNoteCompletedAppointmentData);

                    if($scope.items.scheduler_comments == "") {
                        $scope.commentEmpty = true;

                    } else {
                        $scope.hide_button_updating = false;
                        $http({
                            method: 'POST',
                            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                            data: $scope.addNoteCompletedAppointmentData,
                            headers: {'Content-Type': 'application/json'}
                        }).success(function(response) {
                            alert("Comment updated successfully!")
                            $location.path('/');
                        }).error(function(response) {
                            console.log(response)
                            if(response.error) { window.location.href = "#/logout" };
                        });
                    };


    }

    $scope.curentUrl = $location.path().replace('/',"").split('/')[0];
    $scope.product_picture_path = '/' + PRODUCT_PICTURE_PATH;    
	$scope.backLink = "#/" + $scope.curentUrl; 


	$scope.pageTitle = $scope.curentUrl.slice(0, -1) + " view";
	var itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);
    $scope.itemId = itemId;
       
	if($scope.pageTitle === "categorie view") 
    {
        $scope.pageTitle = "View Category Details";
        
        $http({
            method: 'POST',
            url: '/' + $scope.curentUrl + '/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name" : "viewData",
                "product_category_id" : $scope.itemId
                }
        }).success(function(response) {
            if(response.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            $scope.items = response['data'];

        }).error(function(response) { 
            if(response.error) { window.location.href = "#/logout" }
        });
          
    }
    else if($scope.pageTitle === "product view") 
    {
        $http({
            method: 'POST',
            url: '/' + $scope.curentUrl + '/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name" : "viewData",
                "product_id" : $scope.itemId
                }
        }).success(function(response) {
            if(response.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            $scope.items = response['data'];
            angular.forEach(response['data'], function(value, key)            
            {
                $scope.items = value;
            });
        }).error(function(response) {
            if(response.error) { window.location.href = "#/logout" }
        });
        
    }
    else
    {
        $http({
            method: 'POST',
            url: '/' + $scope.curentUrl + '/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name" : "viewData",
                "appointment_id" : itemId
                }
        }).success(function(response) {
            if(response.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            $scope.items = response['data'];

            if(response ['data']['status'] == "Cancelled"){
                    $scope.status = true;
            }else{
                $scope.status = false; 
            }

            if(response ['data']['status'] == "Completed"){
                    $scope.completed = true;
            }else{
                $scope.completed = false;
            }  
                      

            
        }).error(function(response) {
            if(response.error) { window.location.href = "#/logout" }
        });
    }
	
}]);