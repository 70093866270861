
hwecApp.controller('freepackReportController', ['PROVIDER','$filter','$interval', '$scope','$routeParams', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', '$q', '$CHECK_PROVIDER_ID', function (PROVIDER,$filter,$interval, $scope, $routeParams, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL, $q, $CHECK_PROVIDER_ID) {

    $scope.visible=false;
   var loader = document.getElementById("loading-content");
   loader.classList.add("hide-loader");
   $scope.topMonthOrdersVisible = false;
   $scope.topTotalOrdersVisible = false;
   console.log(localStorage.getItem('provider_id'));  

   // Start : load "how heard primary" options by provider -- //
    $scope.provider_details = null;


               
                switch(parseInt(localStorage.getItem('provider_id'))) 
                {

                   case 15:  $scope.providerName = 'affinity';
                    break;
                   case 18: $scope.providerName = 'anglian';
                    break;
                   case 11: $scope.providerName = 'bournemouth';
                    break;
                   case 4: $scope.providerName = 'bristol';
                    break;
                   case 5: $scope.providerName = 'cambridge';
                    break;
                   case 6: $scope.providerName = 'deevalley';
                    break;
                   case 7: $scope.providerName = 'essexsuffolk';
                    break;
                   case 8: $scope.providerName = 'jersey';
                    break;
                   case 9: $scope.providerName = 'northumbrian';
                    break;
                   case 10: $scope.providerName = 'portsmouth';
                    break;
                   case 3: $scope.providerName = 'severntrent';
                    break;
                   case 12: $scope.providerName = 'southeast';
                    break;
                   case 13: $scope.providerName = 'southstaffs';
                    break;
                   case 14: $scope.providerName = 'southwest';
                    break;
                   case 1: $scope.providerName = 'suttoneastsurrey';
                    break;
                   case 16: $scope.providerName = 'thames';
                    break;
                   case 17: $scope.providerName = 'unitedutilities';
                    break;
                   case 2: $scope.providerName = 'wessex';
                    break;
                   case 19: $scope.providerName = 'yorkshire';
                    break;
                   case 20: $scope.providerName = 'hafrendyfrdwy';
                    break;
                   case 21: $scope.providerName = 'yorkshirewaste';
                        break;
               };   
                   
        if(localStorage.getItem('user_id')==undefined || localStorage.getItem('user_id')=='') 
                        window.location.href = "#/logout";;
        if($scope.providerName!='suttoneastsurrey')
            $('.navbar-header img').parent().attr('href','#/freepack-report').html('<img class="img-responsive" src="images/logo/'+$scope.providerName+'.png" alt="Home Water Efficiency" style="opacity: 1;">');
      $scope.visible=true;         
    $scope.months = {
      "null": "Select Month",
      "4": "April",
      "5": "May",
      "6": "June",
      "7": "July",
      "8": "August",
      "9": "September",
      "10": "October",
      "11": "November",
      "12": "December",
      "1": "January",
      "2": "February",
      "3": "March"
    };
    $scope.month = 'null';
    $scope.years = {
      "null": "Select Year"
    };
    $scope.year = 'null';
    $scope.ThisMonth = ($routeParams.month !=undefined)?$scope.months[$routeParams.month]:$scope.months[new Date().getUTCMonth() + 1];
    $scope.ThisYear = ($routeParams.year !=undefined)?$routeParams.year:new Date().getFullYear();
   //full width page 
   $scope.fullWidth = true;
   $scope.descreseSize = false; 

   //get this year start and end
    var thisYear = (new Date()).getFullYear();
    for (year = thisYear; year >= 2010; year-- ) {
        $scope.years[String(year)] = String(year);
    }

   //get Mondays and Sundays of THIS week
    var today = new Date();
    var dayOfWeekStartingSundayZeroIndexBased = today.getDay(); // 0 : Sunday ,1 : Monday,2,3,4,5,6 : Saturday
    var mondayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+1);
    var sundayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+7);

    //Mondays
    var Mmonth   = mondayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Mday     = mondayOfWeek.getDate();
    var Myear    = mondayOfWeek.getUTCFullYear();
 
    //Sundays
    var Smonth   = sundayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Sday     = sundayOfWeek.getDate();
    var Syear    = sundayOfWeek.getUTCFullYear();

    //date from current month
    var beginCurentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var endCurentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);


    var dayBM = beginCurentMonth.getDate();
    var monthBM = beginCurentMonth.getMonth() + 1;
    var yearBM = beginCurentMonth.getUTCFullYear();

    var dayEM = endCurentMonth.getDate();
    var monthEM = endCurentMonth.getMonth() + 1;
    var yearEM = endCurentMonth.getUTCFullYear();

    if(monthEM>=4){ 
      var since_start_date = thisYear + "-04-01";
    $scope.since_start_date = '1st of April, '+thisYear;
    } else {
      var since_start_date = parseInt(thisYear-1) + "-04-01";
    $scope.since_start_date = '1st of April, '+parseInt(thisYear-1);
    }
    var monthSince = parseInt(today.getMonth())-1+2;
    // if(monthSince<10) monthSince = monthSince;
    var since_end_date = formatDate(today.getDate(),monthSince,today.getUTCFullYear());
    
    var today_start_date = formatDate(today.getDate(),today.getMonth()+1,today.getUTCFullYear());
    var today_end_date = formatDate(today.getDate(),today.getMonth()+1,today.getUTCFullYear());
    
    var week_start_date = formatDate(Mday,Mmonth,Myear);
    var week_end_date = formatDate(Sday,Smonth,Syear);
    
    var month_start_date = formatDate(dayBM,monthBM,yearBM);

    var month_end_date = formatDate(dayEM,monthEM,yearEM);
   
    if( $routeParams.month !=undefined ){
        month_start_date = formatDate(dayBM,$routeParams.month,$routeParams.year);
        month_end_date = formatDate(getDaysInMonth($routeParams.month,yearEM),$routeParams.month,$routeParams.year);
       $scope.month = $routeParams.month; 
       $scope.year = $routeParams.year; 
    }
    $scope.changeMonthYear = function(){
        $scope.visible=false;
        if($scope.month == 'null' ){
            alert('Please select month'); return;
        }
        if($scope.year == 'null' ){
            alert('Please select year'); return;
        }
        var absUrl =  $location.host();
        window.location.href ='#/freepack-report/'+$scope.year+'/'+$scope.month;
        //console.log('http://'+absUrl+'/#/freepack-report/'+$scope.year+'/'+$scope.month);
         //$route.reload();
    }


    //format from long date to YYYY-MM-DD
    function formatDate(day, month, year) {
        day = day < 10 ? "0"+day : day;
        month = month < 10 ? "0"+month : month;
        year = year;
        return newdate = year + "-" + month + "-" + day;
    }
    function getDaysInMonth(m, y) {
       return /8|3|5|10/.test(--m)?30:m==1?(!(y%4)&&y%100)||!(y%400)?29:28:31;
    }

    Array.prototype.sum = function() {
        return this.reduce(function(a,b){return a+b;});
    };
    
   //ORDERED PRODUCTS 
    $scope.reloadMonthlyReport = function(){
        loadTotalReceivedOrders(month_start_date,month_end_date,'monthly');
        
        loadTotalDispatchedOrders(month_start_date,month_end_date,'monthly');
        loadProviderCurrentSLASummary(month_start_date);
        loadAverageNumberOfProductsPerOrder(month_start_date,month_end_date,'monthly');
        
        loadPaidForItemsTotalSales(month_start_date,month_end_date,'monthly');
        
        loadPieChartForOrderByChannelMonth(month_start_date,month_end_date);
        
        loadTotalNumberOfCustomerOptingInForFutureContact(month_start_date,month_end_date,'monthly');
        
        var ctxProductsPurchasedPieChart = [];
        var ctxProductsPurchasedPieChart1 = [];
        loadProductsPurchasedPieChart(month_start_date,month_end_date,'monthly','show_top_6_purchased_products_month'); 
        
        var ctxRejectedOrdersPieChart = [];    
        loadRejectedOrdersPieChart(month_start_date,month_end_date,'monthly','show_rejected_orders_month');
        
        
        
        loadTotalNumberOfReturns(month_start_date,month_end_date,'monthly');
       
        loadAverageTimeDispatch(month_start_date,month_end_date,'monthly');
        
        var ctxOrderBySourcePieChart = [];    
        loadOrderBySourcePieChart(month_start_date,month_end_date,'monthly','show_order_by_source_month');
        
        loadMaxTimeToDispatch(month_start_date,month_end_date,'monthly');   
            
        var ctxReturnByReasonPieChart = [];    
        //loadReturnByReasonPieChart(month_start_date,month_end_date,'monthly','show_return_by_reason_month');
        getNumberCustomersPostcodes(month_start_date,month_end_date,'monthly');
        getFreeProducts(month_start_date,month_end_date,'monthly');

    }
    $scope.reloadMonthlyReport();
    $scope.reloadAnnualReport = function(){
            loadTotalReceivedOrders(since_start_date,since_end_date,'since');
            
            loadTotalDispatchedOrders(since_start_date,since_end_date,'since');
            loadAnnualSlaSummary(since_start_date,since_end_date,'since');
            loadAverageNumberOfProductsPerOrder(since_start_date,since_end_date,'since');
            
            loadPaidForItemsTotalSales(since_start_date,since_end_date,'since');
            
            loadPieChartForOrderByChannelSince();
            
            loadTotalNumberOfCustomerOptingInForFutureContact(since_start_date,since_end_date,'since');
            
            loadProductsPurchasedPieChart(since_start_date,since_end_date,'since','show_top_6_purchased_products_since');   
            
            loadRejectedOrdersPieChart(since_start_date,since_end_date,'since','show_rejected_orders_since');
            
            loadAverageLitresSavedPerOrder(since_start_date,since_end_date,'since');
            
            loadTotalNumberOfReturns(since_start_date,since_end_date,'since');
           
            loadAverageTimeDispatch(since_start_date,since_end_date,'since');
            
            loadOrderBySourcePieChart(since_start_date,since_end_date,'since','show_order_by_source_since');

            loadMaxTimeToDispatch(since_start_date,since_end_date,'since');
                
            //loadReturnByReasonPieChart(since_start_date,since_end_date,'since','show_return_by_reason_since');
            getNumberCustomersPostcodes(since_start_date,since_end_date,'since');
            getFreeProducts(since_start_date,since_end_date,'since');


    }

    function loadAverageNumberOfProductsPerOrder(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                $scope.loading_average_number_of_products_since = true;
                $scope.load_total_orders = false;
                $scope.average_number_of_products_per_order_since = 0;
                break;
            case "weekly": 
                $scope.loading_average_number_of_products_week = true;
                $scope.load_total_orders_weekly = false;
                $scope.average_number_of_products_per_order_week = 0;
                break;
            case "monthly": 
                $scope.loading_average_number_of_products_month = true;
                $scope.load_total_orders_monthly = false;
                $scope.average_number_of_products_per_order_month = 0;
                break;
           default: 
               break; 
        };   
           
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                 "method_name":"getAverageNumberOfItemsByDate" ,
                 "created_date": start_date,
                 "end_date": end_date,
                 "provider": $scope.providerName
            }
        }).success(function(response) 
        {
            for(var key in response.data) 
            {
                if(!isNaN(parseFloat(response.data.data_count)))
                {
                    switch (duration_type)
                    {
                        case "since":
                            $scope.loading_average_number_of_products_since = false;
                            $scope.average_number_of_products_per_order_since = parseFloat(response.data.data_count).toFixed(2);                        
                            break;
                        case "weekly":
                            $scope.loading_average_number_of_products_week = false;
                            $scope.average_number_of_products_per_order_week = parseFloat(response.data.data_count).toFixed(2);
                            break;
                        case "monthly": 
                            $scope.loading_average_number_of_products_month = false;
                            $scope.average_number_of_products_per_order_month = parseFloat(response.data.data_count).toFixed(2);
                            break;
                       default: 
                           break;
                    };
                }
            };
        });
    }
   
    function loadPaidForItemsTotalSales(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_paid_for_items_total_sales_since_loading_icon = true;
                break;
            case "today":
                $scope.show_paid_for_items_total_sales_today_loading_icon = true;
                break;
            case "weekly": 
                $scope.show_paid_for_items_total_sales_week_loading_icon = true;
                break;
            case "monthly": 
                $scope.show_paid_for_items_total_sales_month_loading_icon = true;
                break;
           default: 
               break;
        }
            
        var start_date = start_date;    
        var end_date = end_date;
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                 "method_name":"getTotalSalesNumberOrdersWithPaidItems",
                 "created_date": start_date,
                 "end_date": end_date,
                 "provider": $scope.providerName
            }
        }).success(function(response) 
        {
            switch (duration_type)
            {
                case "since":
                    $scope.show_paid_for_items_total_sales_since_loading_icon = false;
                    $scope.paid_for_items_total_sales_since = (response.data[0].data_count);
                    break;
                case "today":
                    $scope.show_paid_for_items_total_sales_today_loading_icon = false;
                    $scope.paid_for_items_total_sales_today = (response.data[0].data_count);
                    break;
                case "weekly": 
                    $scope.show_paid_for_items_total_sales_week_loading_icon = false;
                    $scope.paid_for_items_total_sales_week = (response.data[0].data_count);
                    break;
                case "monthly":
                    $scope.show_paid_for_items_total_sales_month_loading_icon = false;
                    $scope.paid_for_items_total_sales_month = (response.data[0].data_count);
                    break;
               default: 
                   break;
            }            
        });
    }; 
    
    function loadTotalReceivedOrders(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                $scope.loading_total_orders = true;
                $scope.load_total_orders = false;
                var total_received_orders_since = 0;
                break;
            case "weekly": 
                $scope.loading_total_orders_weekly = true;
                $scope.load_total_orders_weekly = false;
                var total_received_orders_weekly = 0;
                break;
            case "monthly": 
                $scope.loading_total_orders_monthly = true;
                $scope.load_total_orders_monthly = false;
                var total_received_orders_monthly = 0;
                break;
           default: 
               break;
        };
        
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                 "method_name":"getTotalOrders" ,
                 "created_date": start_date,
                 "end_date": end_date,
                 "provider": $scope.providerName
            }
        }).success(function(response) 
        {

            if(response.data[0]!=undefined) 
            {
                switch (duration_type) 
                {
                    case "since":
                        $scope.totalProductReceived = response.data[0].data_count;
                        break;
                    case "monthly": 
                        $scope.totalProductReceivedMonthly = response.data[0].data_count;
                        break;
                   default: 
                       break;
                };                
            };

            $('.total-orders-in').animate({'opacity':1},500);
        });
    };  
    
    function loadTotalDispatchedOrders(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                var total_dispatched_orders_since = 0;
                break;
            case "weekly":
                var total_dispatched_orders_weekly = 0;
                break;
            case "monthly":
                var total_dispatched_orders_monthly = 0;
                break;
           default:
               break;
        };
        
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                 "method_name":"getTotalDispatches" ,
                 "created_date": start_date,
                 "end_date": end_date,
                 "provider": $scope.providerName
            }
        }).success(function(response) 
        {    
            if(response.data[0]!=undefined) 
            {
                if(duration_type === "since")
                {
                    $scope.topTotalOrdersVisible = true;
                    $scope.loading_total_orders = false;
                    $scope.load_total_orders = true;
                    $scope.totalProductDispatched = response.data[0].data_count;
                }
                else if(duration_type === "monthly")
                {
                    $scope.topMonthOrdersVisible = true;
                    $scope.loading_total_orders_monthly = false;
                    $scope.load_total_orders_monthly = true;
                    $scope.totalProductDispatchedMonthly = response.data[0].data_count;;
                }
                 
                loadAverageLitresSavedPerOrder(month_start_date,month_end_date,'monthly');
            };
        });
    };
    function loadAnnualSlaSummary(start_date,end_date,duration_type)
    {
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name":"getProviderAnnualSLASummary" ,
                "start_date": start_date,
                "end_date": end_date,
                "provider": $scope.providerName
            }
        }).success(function(response)
        {

            if(response.data!=undefined)
            {
                    // $scope.loading_annual_sla_summary = false;
                    $scope.load_annual_sla_summary = true;
                    $scope.annualSlaSummary = response.data.annual_sla_measure;


            };
        });
    };
    function loadPieChartForOrderByChannelSince()
    {
        $scope.loadPieChartForOrderByChannelSince = true;
        $http
        ({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: 
            {
                "method_name":"getOrderesByChannel" ,
                "created_date": since_start_date,
                "end_date": since_end_date,
                "provider": $scope.providerName
            }
        })
        .success(function(response) 
        {
            $scope.loadPieChartForOrderByChannelSince = false;
            $scope.water_calculator_channel = response.data.water_calculator_channel;
            $scope.free_pack_channel = response.data.free_pack_channel;
            $scope.coupon_channel = response.data.coupon_channel;
            $scope.call_centre_channel = response.data.call_centre_channel;
            $scope.savingsengine_channel = response.data.savingsengine_channel;

            var ctxOrderByChannelSince = document.getElementById('order-by-channel-since');            
            $scope.modalOrderByChannelSince = function(ctxOrderByChannelSince) 
            {

                    var chart = echarts.init(ctxOrderByChannelSince);
                    var total = parseInt(response.data.water_calculator_channel) + parseInt(response.data.free_pack_channel)+parseInt(response.data.coupon_channel)+parseInt(response.data.call_centre_channel);
                    console.log(total);
                    var watercalc = (parseInt(response.data.water_calculator_channel)/total*100).toFixed(0);
                    $scope.wcc_percentage = watercalc+' %';
                    var percfree = (parseInt(response.data.free_pack_channel)/total*100).toFixed(0);
                    $scope.fpc_percentage = percfree+' %';
                    var perccoupon = (parseInt(response.data.coupon_channel)/total*100).toFixed(0);
                    $scope.cc_percentage = perccoupon+' %';
                    var perccallcenter = (parseInt(response.data.call_centre_channel)/total*100).toFixed(0);
                    $scope.ccc_percentage = perccallcenter+' %';
                    var percsavingsengine = (parseInt(response.data.savingsengine_channel)/total*100).toFixed(0);
                    $scope.ccv_percentage = percsavingsengine+' %';

                    //console.log(total);
                    //console.log(response.data.call_centre_channel);
                    //console.log(total);
                    //console.log(parseInt(response.data.coupon_channel)/total);
                    var order_channel_data = [];
                    if(!isNaN(watercalc)) order_channel_data.push({value:response.data.water_calculator_channel, name:'Water Calculator ('+watercalc+'%)'});
                    if(!isNaN(percfree)) order_channel_data.push({value:response.data.free_pack_channel, name:'Freepack Webpage ('+percfree+'%)'});
                    if(!isNaN(perccoupon)) order_channel_data.push({value:response.data.coupon_channel, name:'Coupon ('+perccoupon+'%)'});
                    if(!isNaN(perccallcenter)) order_channel_data.push({value:response.data.call_centre_channel, name:'Call Center ('+perccallcenter+'%)'});
                    if(!isNaN(percsavingsengine)) order_channel_data.push({value:response.data.savingsengine_channel, name:'Aqkwa Savings Engine ('+percsavingsengine+'%)'});

                    var itemStyle = {
                        normal: {
                            // shadowBlur: 10,
                            // shadowOffsetX: 0,
                            // shadowOffsetY: 5,
                            // shadowColor: 'rgba(0, 0, 0, 0.4)'
                                label: {
                                            show: true,
                                            textStyle : {
                                                color: '#222222',
                                                fontWeight: 'bold'
                                            }
                                        }
                        },
                        emphasis: {
                          label: {
                            show: true,
                            position: 'center',
                            textStyle: {
                              fontSize: '14',
                              fontWeight: 'normal',
                              color: '#222222'
                            }
                          }
                        }
                    };

                    chart.setOption({
                        legend: {
                            data:[
                            "Water Calculator",
                            "Freepack Webpage",
                            "Coupon",
                            "Call Center", "Aqkwa Savings Engine"
                            ],
                            x : 'center',
                            y:0
                        },
                        toolbox: {
                            left: 'left',
                            itemSize: 25,
                            effectiveColor: 'red',
                            color: ['#1e90ff','#22bb22','#4b0082','#d2691e'],
                            showTitle: false,
                            borderWidth: 1,
                            borderColor: '#cccccc',
                            feature: { 
                                saveAsImage: {
                                    show : true,
                                    title : 'Download',
                                },
                            }
                        }, 
                        tooltip: {
                            trigger: 'item',
                            formatter:"<italic>{b}</italic><br>Total : {c}<br><strong>Percentage : {d}%</strong>"
                        },
                        series: [{
                            name: 'pie',
                            type: 'pie',
                            radius: ['35%', '75%'],
                            selectedMode: 'single',
                            selectedOffset: 30,
                            clockwise: true,
                            data:order_channel_data,
                            itemStyle: itemStyle
                        }],
                        color : [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2']
                    });

                    var config = {
                        labelPosition: 'outside',
                        clockwise: true,
                        labelLineLen: 20,
                        labelLineLen2: 5
                    };



            };
            $scope.modalOrderByChannelSince(ctxOrderByChannelSince);

            var ctxOrderByChannelSince1 = document.getElementById('order-by-channel-since1');
            $scope.show_order_by_channel_since_popup = function() 
            {
                $('#order-by-channel-since-popup').modal('show');
                setTimeout(function() 
                {
                    $scope.modalOrderByChannelSince(ctxOrderByChannelSince1);
                },300);
            };         
        });
    };

 
    function loadPieChartForOrderByChannelMonth(start_date,end_date)
    {
        
        $scope.loadPieChartForOrderByChannelMonth = true;
        $http
        ({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: 
            {
                "method_name":"getOrderesByChannel" ,
                "created_date": start_date,
                "end_date": end_date,
                "provider": $scope.providerName
            }
        })
        .success(function(response) 
        {   console.log(response);
            $scope.loadPieChartForOrderByChannelMonth = false;
            $scope.water_calculator_channel_month = response.data.water_calculator_channel;
            $scope.free_pack_channel_month = response.data.free_pack_channel;
            $scope.coupon_channel_month = response.data.coupon_channel;
            $scope.call_centre_channel_month = response.data.call_centre_channel;
            $scope.savingsengine_channel_month = response.data.savingsengine_channel;
        
            if($scope.water_calculator_channel_month == 0 && $scope.free_pack_channel_month == 0 && $scope.coupon_channel_month == 0 && $scope.call_centre_channel_month == 0 && $scope.savingsengine_channel_month == 0 )
            {
                $scope.show_zero_order_by_channel_month = 1;
            }
            else
            {            
                var ctxOrderByChannelMonth = document.getElementById('order-by-channel-month');            
                $scope.modalOrderByChannelMonth = function(ctxOrderByChannelMonth)
                {
 
 
                    var chart = echarts.init(ctxOrderByChannelMonth);
                    var total = parseInt(response.data.water_calculator_channel) + parseInt(response.data.free_pack_channel)+parseInt(response.data.coupon_channel)
                                 +parseInt(response.data.call_centre_channel)+parseInt(response.data.savingsengine_channel);
                    var watercalc = parseInt(parseInt(response.data.water_calculator_channel)/total*100).toFixed(0);
                    $scope.wccm_percentage = watercalc+' %';
                    var percfree = parseInt(parseInt(response.data.free_pack_channel)/total*100).toFixed(0);
                    $scope.fpcm_percentage = percfree+' %';
                    var perccoupon = parseInt(parseInt(response.data.coupon_channel)/total*100).toFixed(0);
                    $scope.ccm_percentage = perccoupon+' %';
                    var perccallcenter = parseInt(parseInt(response.data.call_centre_channel)/total*100).toFixed(0);
                    $scope.cccm_percentage = perccallcenter+' %';
                    var percsavingsengine = parseInt(parseInt(response.data.savingsengine_channel)/total*100).toFixed(0);
                    $scope.ccvm_percentage = percsavingsengine+' %';

                    var order_channel_data = [];
                    if(!isNaN(watercalc)) order_channel_data.push({value:response.data.water_calculator_channel, name:'Water Calculator ('+watercalc+'%)'});
                    if(!isNaN(percfree)) order_channel_data.push({value:response.data.free_pack_channel, name:'Freepack Webpage ('+percfree+'%)'});
                    if(!isNaN(perccoupon)) order_channel_data.push({value:response.data.coupon_channel, name:'Coupon ('+perccoupon+'%)'});
                    if(!isNaN(perccallcenter)) order_channel_data.push({value:response.data.call_centre_channel, name:'Call Center ('+perccallcenter+'%)'});
                    if(!isNaN(percsavingsengine)) order_channel_data.push({value:response.data.savingsengine_channel, name:'Aqkwa Savings Engine ('+percsavingsengine+'%)'});

                    var itemStyle = {
                        normal: {
                            // shadowBlur: 10,
                            // shadowOffsetX: 0,
                            // shadowOffsetY: 5,
                            // shadowColor: 'rgba(0, 0, 0, 0.4)'
                             label: {
                                            show: true,
                                            textStyle : {
                                                color: '#222222',
                                                fontWeight: 'bold'
                                            }
                                        }
                        },
                        emphasis: {
                          label: {
                            show: true,
                            position: 'center',
                            textStyle: {
                              fontSize: '14',
                              fontWeight: 'normal',
                              color: '#222222'
                            }
                          }
                        }
                    };

                    chart.setOption({
                        legend: {
                            data:[
                            "Water Calculator",
                            "Freepack Webpage",
                            "Coupon",
                            "Call Center",
                            "Aqkwa Saving Engine"],
                            x : 'center',
                            y:0
                        },
                        toolbox: {
                            left: 'left',
                            itemSize: 25,
                            effectiveColor: 'red',
                            color: ['#1e90ff','#22bb22','#4b0082','#d2691e'],
                            showTitle: false,
                            borderWidth: 1,
                            borderColor: '#cccccc',
                            feature: {
                                saveAsImage: {
                                    show : true,
                                    title : 'Download',
                                },
                            }
                        },       
                        tooltip: {
                            trigger: 'item',
                            formatter:"<italic>{b}</italic><br>Total : {c}<br><strong>Percentage : {d}%</strong>"
                        },
                        series: [{
                            name: 'pie',
                            type: 'pie',
                            radius: ['35%', '75%'],
                            selectedMode: 'single',
                            selectedOffset: 30,
                            clockwise: true,
                            data:order_channel_data,
                            itemStyle: itemStyle
                        }],
                       color : [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69   ',' #666666','#8C8C8C','#B2B2B2']
                    });

                    var config = {
                        labelPosition: 'outside',
                        clockwise: true,
                        labelLineLen: 20,
                        labelLineLen2: 5
                    }; 
                };
                $scope.modalOrderByChannelMonth(ctxOrderByChannelMonth);

                var ctxOrderByChannelMonth1 = document.getElementById('order-by-channel-month1');
                $scope.show_order_by_channel_month_popup = function() 
                {
                    $('#order-by-channel-month-popup').modal('show');
                    setTimeout(function() 
                    {
                        $scope.modalOrderByChannelMonth(ctxOrderByChannelMonth1);
                    },300);
                };         
            }
        });
    };


    function loadTotalNumberOfCustomerOptingInForFutureContact(start_date,end_date,duration_type)
    {        
        switch (duration_type)
        {
            case "since":
                $scope.loading_total_customer_optin_since = true;
                $scope.load_total_customer_optin_since = false;
                $scope.total_number_of_customers_opting_in_for_future_contact_since = 0;
                break;
            case "today":
                $scope.loading_total_customer_optin_today = true;
                $scope.load_total_customer_optin_today = false;
                $scope.total_number_of_customers_opting_in_for_future_contact_today = 0;
                break;
            case "weekly": 
                $scope.loading_total_customer_optin_week = true;
                $scope.load_total_customer_optin_week = false;
                $scope.total_number_of_customers_opting_in_for_future_contact_week = 0;
                break;
            case "monthly": 
               $scope.loading_total_customer_optin_month = true;
                $scope.load_total_customer_optin_month = false;
                $scope.total_number_of_customers_opting_in_for_future_contact_month = 0;
                break;
           default: 
               break;
        }
          
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                 "method_name":"getNumberCustomersOptingSimple" ,
                 "created_date": start_date,
                 "end_date": end_date,
                 "provider": $scope.providerName
            }
        }).success(function(response) 
        {
            
            if(response.data!=undefined) {
                switch (duration_type)
                {
                    case "since":
                        $scope.total_number_of_customers_opting_in_for_future_contact_since = response.data.data_count;
                        $scope.loading_total_customer_optin_since = false;
                        $scope.load_total_customer_optin_since = true;
                        break;
                    case "today":
                        $scope.total_number_of_customers_opting_in_for_future_contact_today = response.data.data_count;
                        $scope.loading_total_customer_optin_today = false;
                        $scope.load_total_customer_optin_today = true;
                        break;
                    case "weekly": 
                        $scope.total_number_of_customers_opting_in_for_future_contact_week = response.data.data_count;
                        $scope.loading_total_customer_optin_week = false;
                        $scope.load_total_customer_optin_week = true;
                        break;
                    case "monthly": 
                        $scope.total_number_of_customers_opting_in_for_future_contact_month = response.data.data_count;
                        $scope.loading_total_customer_optin_month = false;
                        $scope.load_total_customer_optin_month = true;
                        break;
                   default: 
                       break;
                }
            }
        });
    };

    function loadProductsPurchasedPieChart(start_date,end_date,duration_type,element_name)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_purchased_product_since_loading_icon = true;
                $scope.purchased_product_since_data_not_found = false;
                break;
            case "today":
                $scope.show_purchased_product_today_loading_icon = true;
                $scope.purchased_product_today_data_not_found = false;
                break;
            case "weekly": 
                $scope.show_purchased_product_week_loading_icon = true;
                $scope.purchased_product_week_data_not_found = false;
                break;
            case "monthly": 
                $scope.show_purchased_product_month_loading_icon = true;
                $scope.purchased_product_month_data_not_found = false;
                break;
           default: 
               break;
        };
                    
        $scope.modalProductsPurchasedPieChart = function(ctxProductsPurchasedPieChart) 
        {
            $scope.data_for_popup_product_purchased = [];
            var label_array_product_purchased = [];
            var data_array_product_purchased = [];
            $scope.background_color_array_product_purchased =  [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2'];

            var combined_data = {};
            $http
            ({
                method: 'POST',
                url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
                data: 
                {
                    "method_name":"getProductPurchased" ,
                    "created_date": start_date,
                    "end_date": end_date,
                    "provider": $scope.providerName
                }
            })
            .success(function(response) 
            {
                if(response['data'].length !== 0)
                {
                    var response_counter = 0;
                    var total = 0;
                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            total = total + parseInt(response['data'][response_key].data_count);
                        }
                    }
                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            label_array_product_purchased.push(response['data'][response_key].reportingname);
                            var perc = (parseInt(response['data'][response_key].data_count)/parseInt(total)*100).toFixed(0);
                            data_array_product_purchased.push({value:response['data'][response_key].data_count,name:response['data'][response_key].reportingname+'('+perc+'%)'});
                            combined_data[response_counter] = {};
                            combined_data[response_counter]['label'] = response['data'][response_key].reportingname;
                            combined_data[response_counter]['data_count'] = response['data'][response_key].data_count;
                            combined_data[response_counter]['productid'] = response['data'][response_key].productid;
                            combined_data[response_counter]['background_colour'] = $scope.background_color_array_product_purchased[response_counter];

                            response_counter++;
                        }
                    }
                    $scope.data_for_popup_product_purchased[duration_type] = combined_data;


                   // ctxProductsPurchasedPieChart[duration_type] = document.getElementById(element_name + "1");
                    //$scope.loadPurchasedChart(ctxProductsPurchasedPieChart[duration_type]);
                
                    $scope.loadPurchasedChart = function(chart_element)
                    {
 

                        var chart = echarts.init(chart_element);

                            var itemStyle = {
                                normal: {
                                    // shadowBlur: 10,
                                    // shadowOffsetX: 0,
                                    // shadowOffsetY: 5,
                                    // shadowColor: 'rgba(0, 0, 0, 0.4)'
                                    color: function(params) {
                                        // build a color map as your need.
                                        var colorList = [
                                          '#336699','#7f9ef4', '#486b0b', '#38BD38','#666666','#8C8C8C'
                                        ];
                                        return colorList[params.dataIndex]
                                    }
                                }, 
                                emphasis: {
                                  label: {
                                    textStyle: {
                                      fontSize: '14',
                                      fontWeight: 'bold',
                                      color: '#222222'
                                    }
                                  }
                                }
                            }; 

                            chart.setOption({
                               grid: {
                                    borderWidth: 0,
                                    y: 50,
                                    y2: 85,
                                    x2: 160

                                },
                                legend: {
                                    data: label_array_product_purchased,
                                    x : 'center',
                                    y:'bottom'
                                },
                                toolbox: {
                                    left: 'left',
                                    itemSize: 25,
                                    effectiveColor: 'red',
                                    color: ['#1e90ff','#22bb22','#4b0082','#d2691e'],
                                    showTitle: false,
                                    borderWidth: 1,
                                    borderColor: '#cccccc',
                                    feature: {
                                        saveAsImage: {
                                            show : true,
                                            title : 'Download',
                                        },
                                    }
                                },  
                                tooltip: {
                                    trigger: 'item',
                                    formatter: "<italic>{b}</italic><br>Total : {c}<br>"
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        show: true,
                                        data: label_array_product_purchased, 
                                        axisLabel: {
                                          interval: 0,
                                          rotate:-11,
                                          margin:12,
                                          textStyle: {
                                            align : 'left',
                                            fontSize :12
                                          }
                                        }
                                    }
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        show: true
                                    }
                                ], 
                                series: [{
                                    name: 'pie',
                                    type: 'bar',
                                    data: data_array_product_purchased,
                                    itemStyle: itemStyle
                                }],
                                color : [ '#336699', '#6978CD', '#38BD38', '#69CD69','#666666','#8C8C8C']
                            });

                            var config = {
                                labelPosition: 'outside',
                                clockwise: true,
                                labelLineLen: 20,
                                labelLineLen2: 5
                            };

                    };
                      console.log(element_name);
                    $scope.loadPurchasedChart(document.getElementById(element_name));
             
                    
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_purchased_product_since_loading_icon = false;
                            break;
                        case "today":
                            $scope.show_purchased_product_today_loading_icon = false;
                            break;
                        case "weekly": 
                            $scope.show_purchased_product_week_loading_icon = false;
                            break;
                        case "monthly": 
                            $scope.show_purchased_product_month_loading_icon = false;
                            break;
                       default: 
                           break;
                    }
                }
                else
                {
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_purchased_product_since_loading_icon = false;
                            $scope.purchased_product_since_data_not_found = true;
                            break;
                        case "today":
                            $scope.show_purchased_product_today_loading_icon = false;
                            $scope.purchased_product_today_data_not_found = true;
                            break;
                        case "weekly": 
                            $scope.show_purchased_product_week_loading_icon = false;
                            $scope.purchased_product_week_data_not_found = true;
                            break;
                        case "monthly": 
                            $scope.show_purchased_product_month_loading_icon = false;
                            $scope.purchased_product_month_data_not_found = true;
                            break;
                       default: 
                           break;
                    }
                }
            });
        };
        
        $scope.modalProductsPurchasedPieChart(document.getElementById(element_name));
        
        $scope.show_top_6_purchased_products_since_popup = function() 
        {
            $('#top_6_purchased_products_since_popup').modal('show'); 
            setTimeout(function(){
                $scope.loadPurchasedChart(document.getElementById(element_name+'1'));           
            },300);
        };
        $scope.show_top_6_purchased_products_month_popup = function() 
        {
            $('#top_6_purchased_products_month_popup').modal('show');
             setTimeout(function(){
                $scope.loadPurchasedChart(document.getElementById(element_name+'1'));       
            },300);
        };
    };

        
    function loadRejectedOrdersPieChart(start_date,end_date,duration_type,element_name)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_rejected_orders_since_loading_icon = true;
                $scope.rejected_orders_since_data_not_found = false;
                break;
            case "today":
                $scope.show_rejected_orders_today_loading_icon = true;
                $scope.rejected_orders_today_data_not_found = false;
                break;
            case "weekly": 
                $scope.show_rejected_orders_week_loading_icon = true;
                $scope.rejected_orders_week_data_not_found = false;
                break;
            case "monthly": 
                $scope.show_rejected_orders_month_loading_icon = true;
                $scope.rejected_orders_month_data_not_found = false;
                break;
           default: 
               break;
        };
                    
        $scope.modalRejectedOrdersPieChart = function(ctxRejectedOrdersPieChart) 
        {
            $scope.data_for_popup_rejected_orders = [];
            var label_array_rejected_orders = [];
            var data_array_rejected_items = [];
            $scope.background_color_array_product_purchased = [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2'];

            var combined_data = {};
            $http
            ({
                method: 'POST',
                url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
                data: 
                {
                    "method_name":"getTotalRejectedOrders" ,
                    "created_date": start_date,
                    "end_date": end_date,
                    "provider": $scope.providerName
                }
            })
            .success(function(response) 
            {
                if(response['data']['items'] !=='undefined' && response['data']['items'].length !== 0 )
                {
                    var response_counter = 0;
                    var total = 0;
                    for (var response_key in response['data']['items'])
                    {
                        if(response['data']['items'][response_key].data_count !== undefined )
                        {
                            total = total + response['data']['items'][response_key].data_count;
                        }
                    }

                    for (var response_key in response['data']['items'])
                    {

                        if(response['data']['items'][response_key].data_count !== undefined )
                        {
                            label_array_rejected_orders.push(response['data']['items'][response_key].shorttitle);
                            var perc = (parseInt(response['data']['items'][response_key].data_count)/parseInt(total)*100).toFixed(0);
                            var rejectedName = response['data']['items'][response_key].shorttitle;
                            data_array_rejected_items.push({value:response['data']['items'][response_key].data_count, name:rejectedName+' ('+perc+'%)'});
                            combined_data[response_counter] = {};
                            combined_data[response_counter]['label'] = rejectedName;
                            combined_data[response_counter]['data_count'] = response['data']['items'][response_key].data_count;
                            combined_data[response_counter]['percentage'] = perc + '%';
                            combined_data[response_counter]['background_colour'] = $scope.background_color_array_product_purchased[response_counter];

                            response_counter++;
                        }  
                    }
                    $scope.data_for_popup_rejected_orders[duration_type] = combined_data;

                    $scope.totalNumberOfRejectedOrders = response['data']['total_rejected_orders'].data_count;
                    var totalNumberOfRejectedItems = 0;
                    if(data_array_rejected_items !== undefined){
                        for (i = 0; i < data_array_rejected_items.length; i++) {
                            totalNumberOfRejectedItems = totalNumberOfRejectedItems + parseInt(data_array_rejected_items[i].value);
                        }
                    }
                    switch (duration_type)
                    {
                        case "since":
                        $scope.totalNumberOfRejectedItemsSinceStart = totalNumberOfRejectedItems;
                        break;
                        case "monthly":
                        $scope.totalNumberOfRejectedItemsMonthlyStart = totalNumberOfRejectedItems;
                        break;
                    }

                    if(total>0){
                      $scope.loadChartRejectedOrders = function(chart_element)
                      { 

                          var chart = echarts.init(chart_element);

                          var itemStyle = {
                              normal: {
                                  // shadowBlur: 10,
                                  // shadowOffsetX: 0,
                                  // shadowOffsetY: 5,
                                  // shadowColor: 'rgba(0, 0, 0, 0.4)'
                                  label: {
                                              show: true,
                                              textStyle : {
                                                  color: '#222222',
                                                  fontWeight: 'bold'
                                              }
                                          }
                              },
                              emphasis: {
                                label: {
                                  show: true,
                                  position: 'center',
                                  textStyle: {
                                    fontSize: '14',
                                    fontWeight: 'normal',
                                    color: '#222222'
                                  }
                                }
                              }
                          };

                          chart.setOption({
                              legend: {
                                  data:label_array_rejected_orders,
                                  x : 'center',
                                  y:'bottom'
                              },
                              toolbox: {
                                  left: 'left',
                                  itemSize: 25,
                                  effectiveColor: 'red',
                                  color: ['#1e90ff','#22bb22','#4b0082','#d2691e'],
                                  showTitle: false,
                                  borderWidth: 1,
                                  borderColor: '#cccccc',
                                  feature: {
                                      saveAsImage: {
                                          show : true,
                                          title : 'Download',
                                      },
                                  }
                              }, 
                              tooltip: {
                                  trigger: 'item',
                                  formatter: "<italic>{b}</italic><br>Total : {c}<br><strong>Percentage : {d}%</strong>"
                              },
                              series: [{
                                  name: 'pie',
                                  type: 'pie',
                                  radius: ['35%', '68%'],
                                  selectedMode: 'single',
                                  selectedOffset: 30,
                                  clockwise: true,
                                  data:data_array_rejected_items,
                                  itemStyle: itemStyle
                              }],
                              color : [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2']
                          });

                          var config = {
                              labelPosition: 'outside',
                              clockwise: true,
                              labelLineLen: 20,
                              labelLineLen2: 5
                          };
                          

                      };
                      
                      $scope.loadChartRejectedOrders(document.getElementById(element_name));
                    }else {
                      document.getElementById(element_name).innerHTML= '<h4 class="noreturnsfound">No rejected orders for this period</h4>';
                    }
                    
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_rejected_orders_since_loading_icon = false;
                            break;
                        case "monthly": 
                            $scope.show_rejected_orders_month_loading_icon = false;
                            break;
                       default: 
                           break;
                    }
                }
                else
                {
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_rejected_orders_since_loading_icon = false;
                            $scope.rejected_orders_since_data_not_found = true;
                            break;
                        case "monthly": 
                            $scope.show_rejected_orders_month_loading_icon = false;
                            $scope.rejected_orders_month_data_not_found = true;
                            break;
                       default: 
                           break;
                    }
                }
            });
        };
        
        $scope.modalRejectedOrdersPieChart(document.getElementById(element_name));
        
        $scope.show_rejected_orders_since_popup = function() 
        {
            $('#rejected_orders_since_popup').modal('show');     
            setTimeout(function(){
                $scope.loadChartRejectedOrders(document.getElementById(element_name+'1'));
            },300);       
        };
        $scope.show_rejected_orders_month_popup = function() 
        {
            $('#rejected_orders_month_popup').modal('show');
            setTimeout(function(){
                $scope.loadChartRejectedOrders(document.getElementById(element_name+'1'));
            },300);   
        };
    };
     
    function loadAverageLitresSavedPerOrder(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_average_litres_saved_since_loading_icon = true;                
                break;
            case "weekly": 
                $scope.show_average_litres_saved_week_loading_icon = true;
                break;
            case "monthly": 
                $scope.show_average_litres_saved_month_loading_icon = true;
                break;
           default: 
               break;
        }
            
        //AVERAGE LITRES SAVED PER ORDER
        $http
        ({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name":"getAverageLitersSavedPerOrder",
                "created_date": start_date,
                "end_date": end_date,
                "provider": $scope.providerName
            }
        }).success(function(response) 
        {
            switch (duration_type)
            {
                case "since":
                    $scope.show_average_litres_saved_since_loading_icon = false;
                    $scope.averageLitresPerOrderSince = parseFloat(response.data.data_count).toFixed(2);
                    $scope.totalLitresSaved = $scope.averageLitresPerOrderSince.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    $scope.averageLitresPerOrderSince = parseFloat($scope.averageLitresPerOrderSince/$scope.totalProductDispatched).toFixed(2);
                    break;
                case "weekly": 
                    $scope.show_average_litres_saved_week_loading_icon = false;
                    $scope.averageLitresPerOrderWeek = parseFloat(response.data.data_count).toFixed(2);
                    break;
                case "monthly": 
                    $scope.show_average_litres_saved_month_loading_icon = false;
                    $scope.averageLitresPerOrderMonth = parseFloat(response.data.data_count).toFixed(2);
                    $scope.totalLitresSavedMonth = $scope.averageLitresPerOrderMonth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    $scope.averageLitresPerOrderMonth = parseFloat($scope.averageLitresPerOrderMonth/$scope.totalProductDispatchedMonthly).toFixed(2);
                    break;
               default: 
                   break;
            }
        });
    };
    
    function loadTotalNumberOfReturns(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_total_number_of_returns_since_loading_icon = true;
                break;
            case "weekly": 
                $scope.show_total_number_of_returns_week_loading_icon = true;
                break;
            case "monthly": 
                $scope.show_total_number_of_returns_month_loading_icon = true
                break;
           default: 
               break;
        };
        
        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                 "method_name":"getNumbersReturns",
                 "created_date": start_date,
                 "end_date": end_date,
                 "provider": $scope.providerName
            }
        }).success(function(response) 
        {
            switch (duration_type)
            {
                case "since":
                    $scope.totalNumberOfReturnsSinceStart = parseFloat(response.data[0].data_count);
                    $scope.percentage_against_orders_dispatched_since = parseFloat($scope.totalNumberOfReturnsSinceStart / ($scope.totalProductDispatched)).toFixed(6);
                    if($scope.percentage_against_orders_dispatched_since=='NaN' || $scope.percentage_against_orders_dispatched_since=='')
                      $scope.percentage_against_orders_dispatched_since=='0';
                    $scope.show_total_number_of_returns_since_loading_icon = false;
                    break;
                case "monthly": 
                    $scope.totalNumberOfReturnsMonth = parseFloat(response.data[0].data_count);
                    $scope.percentage_against_orders_dispatched_month = parseFloat($scope.totalNumberOfReturnsMonth / ($scope.totalProductDispatchedMonthly)).toFixed(6);
                    if($scope.percentage_against_orders_dispatched_month=='NaN' || $scope.percentage_against_orders_dispatched_month=='')
                      $scope.percentage_against_orders_dispatched_month ='0';
                    $scope.show_total_number_of_returns_month_loading_icon = false;
                    break;
               default: 
                   break;
            };
        }).error(function(response) {})
    };
    
    function loadAverageTimeDispatch(start_date,end_date,duration_type)
    {
        //AVERAGE TIME FRO ORDER TO BE DISPACHED
        switch (duration_type)
        {
            case "since":
                $scope.show_average_time_dispatch_since_loading_icon = true;
                $scope.averageTimeDispatchSinceStart = 0;
                break;
            case "weekly": 
                $scope.show_average_time_dispatch_week_loading_icon = true;
                $scope.averageTimeDispatchWeek = 0;
                break;
            case "monthly": 
                $scope.show_average_time_dispatch_month_loading_icon = true;
                $scope.averageTimeDispatchMonth = 0;
                break;
           default: 
               break;
        };
        
        $http
        ({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: 
            {
                "method_name":"getAverageTimeDispatchWeekDays",
                "created_date": start_date,
                "end_date": end_date,
                "provider": $scope.providerName
            }
        })
        .success(function(response) 
        {
            switch (duration_type)
            {
                case "since":
                    $scope.averageTimeDispatchSinceStart = parseFloat(response.data[0].data_count).toFixed(2);
                    $scope.show_average_time_dispatch_since_loading_icon = false;
                    break;
                case "weekly":
                    $scope.averageTimeDispatchWeek = parseFloat(response.data[0].data_count).toFixed(2);                    
                    $scope.show_average_time_dispatch_week_loading_icon = false;
                    break;
                case "monthly": 
                    $scope.averageTimeDispatchMonth = parseFloat(response.data[0].data_count).toFixed(2);
                    $scope.show_average_time_dispatch_month_loading_icon = false
                    break;
               default: 
                   break;
            };
        });
    };

    function loadOrderBySourcePieChart(start_date,end_date,duration_type,element_name)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_order_by_source_since_loading_icon = true;
                $scope.order_by_source_since_data_not_found = false;
                break;
            case "weekly": 
                $scope.show_order_by_source_week_loading_icon = true;
                $scope.order_by_source_week_data_not_found = false;
                break;
            case "monthly": 
                $scope.show_order_by_source_month_loading_icon = true;
                $scope.order_by_source_month_data_not_found = false;
                break;
           default: 
               break;
        };
                    
        $scope.modalOrderBySourcePieChart = function(ctxOrderBySourcePieChart) 
        {
            $scope.data_for_popup_order_by_source = [];
            var label_array_order_by_source = [];
            $scope.data_array_order_by_source = [];
            $scope.allLabelsOrderBySource = [];
            $scope.background_color_array_order_by_source =  [  '#336699','#7f9ef4','#b5c8ff', '#486b0b', '#38BD38', '#69CD69','#666666','#8C8C8C','#cccccc'];

            var combined_data = {};
            $http
            ({
                method: 'POST',
                url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
                data: 
                {
                    "method_name":"getOrdersBySource" ,
                    "created_date": start_date,
                    "end_date": end_date,
                    "provider": $scope.providerName
                }
            })
            .success(function(response) 
            {
                if(response['data'].length !== 0)
                {
                    var response_counter = 0;
                    var total = 0;
                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            total = total +parseInt(response['data'][response_key].data_count);
                        }
                    }

                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            var percentage =( parseInt(response['data'][response_key].data_count)/parseInt(total)*100).toFixed(0);
                            if(response['data'][response_key].reportingname!='B2BApp'){
                                $scope.allLabelsOrderBySource.push(response['data'][response_key].reportingname);
                                $scope.data_array_order_by_source.push({value:response['data'][response_key].data_count,name:response['data'][response_key].reportingname+' ('+percentage+' %)'});
                                combined_data[response_counter] = {};
                                combined_data[response_counter]['label'] = response['data'][response_key].reportingname;
                                combined_data[response_counter]['data_count'] = response['data'][response_key].data_count;
                                combined_data[response_counter]['background_colour'] = $scope.background_color_array_order_by_source[response_counter];
                                combined_data[response_counter]['percentage'] = percentage;

                                response_counter++;
                            } else {
                                $scope.allLabelsOrderBySource.push('Miscellaneous');
                                $scope.data_array_order_by_source.push({value:response['data'][response_key].data_count,name:'Miscellaneous ('+percentage+' %)'});
                                
                            }
                        }  
                    }
                    $scope.data_for_popup_order_by_source[duration_type] = combined_data;

                    var ctxOrderBySourcePieChart = document.getElementById(element_name);
                    $scope.loadChartOrderBySource(ctxOrderBySourcePieChart);

                    ctxOrderBySourcePieChart= document.getElementById(element_name + "1");
                    $scope.loadChartOrderBySource(ctxOrderBySourcePieChart);
                
                    
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_order_by_source_since_loading_icon = false;
                            break;
                        case "weekly": 
                            $scope.show_order_by_source_week_loading_icon = false;
                            break;
                        case "monthly": 
                            $scope.show_order_by_source_month_loading_icon = false;
                            break;
                       default: 
                           break;
                    }
                }
                else
                {
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_order_by_source_since_loading_icon = false;
                            break;
                        case "weekly": 
                            $scope.show_order_by_source_week_loading_icon = false;
                            $scope.show_order_by_source_week_data_not_found = true;
                            break;
                        case "monthly": 
                            $scope.show_order_by_source_month_loading_icon = false;
                            $scope.show_order_by_source_month_data_not_found = true;
                            break;
                       default: 
                           break;
                    }
                }
            });
        };

        $scope.loadChartOrderBySource = function(chart_element){

                            var chart = echarts.init(chart_element);

                            var itemStyle = {
                                normal: {
                                    // shadowBlur: 10,
                                    // shadowOffsetX: 0,
                                    // shadowOffsetY: 5,
                                    // shadowColor: 'rgba(0, 0, 0, 0.4)'
                                    color: function(params) {
                                        // build a color map as your need.
                                        var colorList = [
                                          '#336699','#7f9ef4','#b5c8ff', '#486b0b', '#38BD38', '#69CD69','#666666','#8C8C8C','#cccccc'
                                        ];
                                        return colorList[params.dataIndex]
                                    }
                                }, 
                                emphasis: {
                                  label: {
                                    textStyle: {
                                      fontSize: '14',
                                      fontWeight: 'bold',
                                      color: '#222222'
                                    }
                                  }
                                }
                            }; 

                            chart.setOption({
                               grid: {
                                    borderWidth: 0,
                                    y: 50,
                                    y2: 90
                                },
                                legend: {
                                    data: $scope.allLabelsOrderBySource,
                                    x : 'center',
                                    y:'bottom'
                                },
                                toolbox: {
                                    left: 'left',
                                    itemSize: 25,
                                    effectiveColor: 'red',
                                    color: ['#1e90ff','#22bb22','#4b0082','#d2691e'],
                                    showTitle: false,
                                    borderWidth: 1,
                                    borderColor: '#cccccc',
                                    feature: {
                                        saveAsImage: {
                                            show : true,
                                            title : 'Download',
                                        },
                                    }
                                }, 
                                tooltip: {
                                    trigger: 'item',
                                    formatter: "<italic>{b}</italic><br>Total : {c}<br>"
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        show: true,
                                        data: $scope.allLabelsOrderBySource, 
                                        axisLabel: {
                                          interval: 0,
                                          rotate:-55
                                        }
                                    }
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        show: true
                                    }
                                ],
                                series: [{
                                    name: 'pie',
                                    type: 'bar',
                                    data: $scope.data_array_order_by_source,
                                    itemStyle: itemStyle
                                }],
                                color : [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2']
                            });

                            var config = {
                                labelPosition: 'outside',
                                clockwise: true,
                                labelLineLen: 20,
                                labelLineLen2: 5
                            };

                           $('#show_order_by_source_since_popup .modal-body .col-sm-8 .fa-spinner, #show_order_by_source_month_popup .modal-body .col-sm-8 .fa-spinner').hide();
                 

                    };

        //alert(element_name);
        $scope.modalOrderBySourcePieChart(document.getElementById(element_name));
        
        $scope.show_order_by_source_since_popup = function() 
        {
            $('#show_order_by_source_since_popup').modal('show');     
            $('#show_order_by_source_since_popup .modal-body .col-sm-8').prepend('<i class="fa fa-spinner fa-pulse fa-5x fa-fw fa-top-spinner"></i>');              
            setTimeout(function() 
                {

                    ctxOrderBySourcePieChart= document.getElementById(element_name + "1");
                    $scope.loadChartOrderBySource(ctxOrderBySourcePieChart);
                    //$scope.modalOrderBySourcePieChart(document.getElementById(element_name+'1'));
                },300);        
        };
        $scope.show_order_by_source_week_popup = function() 
        {
            $('#show_order_by_source_week_popup').modal('show');            
        };
        $scope.show_order_by_source_month_popup = function() 
        {
            $('#show_order_by_source_month_popup').modal('show');    
            $('#show_order_by_source_month_popup .modal-body .col-sm-8').prepend('<i class="fa fa-spinner fa-pulse fa-5x fa-fw fa-top-spinner"></i>');                 
            setTimeout(function() 
                {

                    ctxOrderBySourcePieChart= document.getElementById(element_name + "1");
                    $scope.loadChartOrderBySource(ctxOrderBySourcePieChart);
                    //$scope.modalOrderBySourcePieChart(document.getElementById(element_name+'1'));
                },300);           
        };
    };
    
    function loadMaxTimeToDispatch(start_date,end_date,duration_type)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_max_time_to_dispatch_since_loading_icon = true;
                $scope.longestTimeDispatchSinceStart =0;
                break;
            case "weekly": 
                $scope.show_max_time_to_dispatch_week_loading_icon = true;
                $scope.longestTimeDispatchWeek = 0;
                break;
            case "monthly": 
                $scope.show_max_time_to_dispatch_month_loading_icon = true;
                $scope.longestTimeDispatchMonth = 0;
                break;
           default: 
               break;
        };
        var data = 
            {
                "method_name":"getMaxTimeToDispatch",
                "created_date": start_date,
                "end_date": end_date,
                "provider": $scope.providerName
            };
        var url = 'freeproductstatistics/api?token=' + localStorage.getItem('userToken');    

        $http
        ({
            method: 'POST',
            url: url,
            data: data
        }).success(function(response) 
        {
            if(response.data[0] != undefined)            
            {
                switch (duration_type)
                {
                    case "since":
                        $scope.show_max_time_to_dispatch_since_loading_icon = false;
                        $scope.longestTimeDispatchSinceStart = parseFloat(response.data[0].data_count).toFixed(2);
                        if($scope.longestTimeDispatchSinceStart >7) $scope.longestTimeDispatchSinceStart ='> 7';
                        break;
                    case "weekly": 
                        $scope.show_max_time_to_dispatch_week_loading_icon = false;
                        $scope.longestTimeDispatchWeek = parseFloat(response.data[0].data_count).toFixed(2);
                        break;
                    case "monthly": 
                        $scope.show_max_time_to_dispatch_month_loading_icon = false;
                        $scope.longestTimeDispatchMonth = parseFloat(response.data[0].data_count).toFixed(2);
                        if($scope.longestTimeDispatchMonth>7)$scope.longestTimeDispatchMonth ='> 7';
                        break;
                   default: 
                       break;
                };
            }
            else
            {
                switch (duration_type)
                {
                    case "since":
                        $scope.show_max_time_to_dispatch_since_loading_icon = false;
                        $scope.longestTimeDispatchSinceStart = 0;
                        break;
                    case "weekly": 
                        $scope.show_max_time_to_dispatch_week_loading_icon = false;
                        $scope.longestTimeDispatchWeek = 0;
                        break;
                    case "monthly": 
                        $scope.show_max_time_to_dispatch_month_loading_icon = false;
                        $scope.longestTimeDispatchMonth = 0;
                        break;
                   default: 
                       break;
                };
            }
        });

    };


    function loadReturnByReasonPieChart(start_date,end_date,duration_type,element_name)
    {
        switch (duration_type)
        {
            case "since":
                $scope.show_return_by_reason_since_loading_icon = true;
                $scope.return_by_reason_since_data_not_found = false;
                break;
            case "weekly": 
                $scope.show_return_by_reason_week_loading_icon = true;
                $scope.return_by_reason_week_data_not_found = false;
                break;
            case "monthly": 
                $scope.show_return_by_reason_month_loading_icon = true;
                $scope.return_by_reason_month_data_not_found = false;
                break;
           default: 
               break;
        };
                    
        $scope.modalReturnByReasonPieChart = function(ctxReturnByReasonPieChart) 
        {
            $scope.data_for_popup_return_by_reason = [];
            $scope.label_array_return_by_reason = [];
            $scope.data_array_return_by_reason = [];
            $scope.background_color_array_return_by_reason = [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2'];

            var combined_data = {};
            $http
            ({
                method: 'POST',
                url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
                data: 
                {
                    "method_name":"getReturnByReason" ,
                    "created_date": start_date,
                    "end_date": end_date,
                    "provider": $scope.providerName
                }
            })
            .success(function(response) 
            {
                if(response['data'].length !== 0)
                {
                    var response_counter = 0;
                    var total = 0;
                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            total = total +parseInt(response['data'][response_key].data_count);
                        }
                    }
                    

                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            if(response['data'][response_key].data_count>0){
                                $scope.label_array_return_by_reason.push(response['data'][response_key].reportingname);
                                var percentage = (parseInt(response['data'][response_key].data_count)/parseInt(total)*100).toFixed(0);
                                $scope.data_array_return_by_reason.push({value:response['data'][response_key].data_count, name:response['data'][response_key].reportingname+' ('+percentage+'%)'});
                                combined_data[response_counter] = {};
                                combined_data[response_counter]['label'] = response['data'][response_key].reportingname;
                                combined_data[response_counter]['data_count'] = response['data'][response_key].data_count;
                                combined_data[response_counter]['background_colour'] = $scope.background_color_array_return_by_reason[response_counter];
                                combined_data[response_counter]['percentage'] = percentage;
                                response_counter++;
                            }
                            
                        }  
                    }
                    $scope.data_for_popup_return_by_reason[duration_type] = combined_data;

                        if(total!=0){ 
                            $scope.loadChartReturnByReason = function(chart_element)
                            {
                                
                                var chart = echarts.init(chart_element);

                                var itemStyle = {
                                    normal: {
                                        // shadowBlur: 10,
                                        // shadowOffsetX: 0,
                                        // shadowOffsetY: 5,
                                        // shadowColor: 'rgba(0, 0, 0, 0.4)'
                                        label: {
                                                show: true,
                                                textStyle : {
                                                    color: '#222222',
                                                    fontWeight: 'bold'
                                                }
                                            }
                                    }, 
                                    emphasis: {
                                      label: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                          fontSize: '14',
                                          fontWeight: 'normal',
                                          color: '#222222'
                                        }
                                      }
                                    }
                                }; 

                                chart.setOption({
                                    legend: {
                                        data: $scope.label_array_return_by_reason,
                                        x : 'center',
                                        y:'bottom'
                                    },
                                    toolbox: {
                                        left: 'left',
                                        itemSize: 25,
                                        effectiveColor: 'red',
                                        color: [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2'],
                                        showTitle: false,
                                        borderWidth: 1,
                                        borderColor: '#cccccc',
                                        feature: {
                                            saveAsImage: {
                                                show : true,
                                                title : 'Download',
                                            },
                                        }
                                    }, 
                                    tooltip: {
                                        trigger: 'item',
                                        formatter: "<italic>{b}</italic><br>Total : {c}<br><strong>Percentage : {d}%</strong>"
                                    },
                                    series: [{
                                        name: 'pie',
                                        type: 'pie',
                                        radius: ['35%', '75%'],
                                        selectedMode: 'single',
                                        selectedOffset: 30,
                                        clockwise: true,
                                        data: $scope.data_array_return_by_reason,
                                        itemStyle: itemStyle
                                    }],
                                    color : [ '#336699', '#6978CD', '#9BA5DE', '#38BD38', '#69CD69','#666666','#8C8C8C','#B2B2B2']
                                });

                                var config = {
                                    labelPosition: 'outside',
                                    clockwise: true,
                                    labelLineLen: 20,
                                    labelLineLen2: 5
                                };
                            };

                            $scope.loadChartReturnByReason(document.getElementById(element_name));
                        } else {  
                          document.getElementById(element_name).innerHTML = '<h4 class="noreturnsfound">No return reason records found in the system</h4>';
                        }
                        switch (duration_type)
                        {
                            case "since":
                                $scope.show_return_by_reason_since_loading_icon = false;
                                break;
                            case "weekly": 
                                $scope.show_return_by_reason_week_loading_icon = false;
                                break;
                            case "monthly": 
                                $scope.show_return_by_reason_month_loading_icon = false;
                                break;
                           default: 
                               break;
                        }
                    
                }
                else
                {
                    noDataFoundReturnByReason(duration_type);
                }
                
                function noDataFoundReturnByReason(duration_type)
                {
                    switch (duration_type)
                    {
                        case "since":
                            $scope.show_return_by_reason_since_loading_icon = false;
                            break;
                        case "weekly": 
                            $scope.show_return_by_reason_week_loading_icon = false;
                            $scope.show_return_by_reason_week_data_not_found = true;
                            break;
                        case "monthly": 
                            $scope.show_return_by_reason_month_loading_icon = false;
                            $scope.show_return_by_reason_month_data_not_found = true;
                            break;
                       default: 
                           break;
                    }
                }
            
            }); 
        };
         
        $scope.modalReturnByReasonPieChart(document.getElementById(element_name));
        
        $scope.show_return_by_reason_since_popup = function() 
        {
            $('#show_return_by_reason_since_popup').modal('show');       
            setTimeout(function(){
                $scope.loadChartReturnByReason(document.getElementById(element_name+'1'));
            },300);     
        };

        $scope.show_return_by_reason_month_popup = function() 
        {
            $('#show_return_by_reason_month_popup').modal('show');            
            setTimeout(function(){
                $scope.loadChartReturnByReason(document.getElementById(element_name+'1'));
            },300);   
        };
    };   

    function loadProviderCurrentSLASummary(start_date)
    {
        var monthDate = new Date(start_date);
        var month = monthDate.getMonth() + 1;
        var year = monthDate.getUTCFullYear();

        $http({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name":"getProviderCurrentSLASummary",
                "order_month": month,
                "order_year":  year,
                "provider": $scope.providerName
            }
        }).success(function(response)
        {
            if(response.data!=undefined)
            {
                $scope.loading_sla_summary = false;
                $scope.load_provider_current_sla_summary = true;

                $scope.orders = response.data.orders;
                $scope.slaMeasure = response.data.sla_measure;

            }
        });
    }
    var heatmapPoints = [];
    function getNumberCustomersPostcodes(start_date,end_date,duration_type)
    {
       
            
        //AVERAGE LITRES SAVED PER ORDER
        $http
        ({
            method: 'POST',
            url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name":"getNumberCustomersPostcodes",
                "created_date": start_date,
                "end_date": end_date,
                "provider": $scope.providerName
            }
        }).success(function(response) 
        {
          var map, heatmap;

          function initMap() {

            getPoints();

          }

          $scope.toggleHeatmap = function() {
            heatmap.setMap(heatmap.getMap() ? null : map);
          }

          $scope.changeGradient = function() {
            var gradient = [
              'rgba(0, 255, 255, 0)',
              'rgba(0, 255, 255, 1)',
              'rgba(0, 191, 255, 1)',
              'rgba(0, 127, 255, 1)',
              'rgba(0, 63, 255, 1)',
              'rgba(0, 0, 255, 1)',
              'rgba(0, 0, 223, 1)',
              'rgba(0, 0, 191, 1)',
              'rgba(0, 0, 159, 1)',
              'rgba(0, 0, 127, 1)',
              'rgba(63, 0, 91, 1)',
              'rgba(127, 0, 63, 1)',
              'rgba(191, 0, 31, 1)',
              'rgba(255, 0, 0, 1)'
            ]
            heatmap.set('gradient', heatmap.get('gradient') ? null : gradient);
          }

          $scope.changeRadius = function() {
            heatmap.set('radius', heatmap.get('radius') ? null : 20);
          }

         $scope.changeOpacity = function() {
            heatmap.set('opacity', heatmap.get('opacity') ? null : 0.2);
          }

          // Heatmap data: 500 Points
          function getPoints() {
            var  intlength = response.data.length;

            //console.log(response.data);
            var  startint =0;
            var latitude =0; var longitude =0;
              for (var i = intlength - 1; i >= 0; i--) {
                if(response.data[i]['latitude']!=null){
                  latitude = response.data[i]['latitude'];
                  longitude = response.data[i]['longitude'];
                  heatmapPoints.push(new google.maps.LatLng(latitude,longitude));
                }
              };
            //console.log(parseInt(latitude),parseInt(longitude));
            map = new google.maps.Map(document.getElementById('map'+duration_type), {
              zoom: 7,
              center: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
              mapTypeId:google.maps.MapTypeId.HYBRID
            });
            $('#mapparent'+duration_type).animate({'opacity':1},300);
              //console.log(heatmapPoints);
              heatmap = new google.maps.visualization.HeatmapLayer({
                data: heatmapPoints,
                map: map
              });

            heatmap.set('opacity', heatmap.get('opacity') ? null : 0.9);
          }
         initMap();
          //console.log(response);
        });
    };

    function getFreeProducts(start_date,end_date,duration_type){
        $scope.background_color_array_product_free =[ '#336699','#7f9ef4', '#486b0b', '#38BD38','#666666','#8C8C8C', '#666633', '#4d6633', '#336659'];
              switch (duration_type)
              {
                case "since":
                $scope.show_freeproducts_since_loading_icon = true;
                  break;
                  case "monthly": 
                $scope.show_freeproducts_monthly_loading_icon = true;
                  break;
                default: 
                  break;
              }

            $http
            ({
                method: 'POST',
                url: 'freeproductstatistics/api?token=' + localStorage.getItem('userToken'),
                data: 
                {
                    "method_name":"getFreeProducts" ,
                    "created_date": start_date,
                    "end_date": end_date,
                    "provider": $scope.providerName
                }
            })
            .success(function(response) 
            {
              var combined_data = {};
              var labels_free_products = [];
              var data_array_product_free = [];
              var response_counter =0;
              $scope.data_for_popup_product_free = [];
                if(response['data'].length !== 0)
                {
                    var response_counter = 0;
                    var total = 0;
                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            total = total + parseInt(response['data'][response_key].data_count);
                        }
                    }
                    for (var response_key in response['data']) 
                    {
                        if(response['data'][response_key].data_count !== undefined )
                        {
                            labels_free_products.push(response['data'][response_key].shorttitle);
                            var perc = (parseInt(response['data'][response_key].data_count)/parseInt(total)*100).toFixed(0);
                            data_array_product_free.push({value:response['data'][response_key].data_count,name:response['data'][response_key].reportingname+'('+perc+'%)'});
                            combined_data[response_counter] = {};
                            combined_data[response_counter]['label'] = response['data'][response_key].shorttitle;
                            combined_data[response_counter]['data_count'] = response['data'][response_key].data_count;
                            combined_data[response_counter]['productid'] = response['data'][response_key].productid;
                            combined_data[response_counter]['shorttitle'] = response['data'][response_key].shorttitle;
                            combined_data[response_counter]['background_colour'] = $scope.background_color_array_product_free[response_counter];
                            combined_data[response_counter]['autosyncstocklevel'] = response['data'][response_key].autosyncstocklevel;
                            console.log(response['data'][response_key]);
                            response_counter++;
                        }
                    }
                    $scope.data_for_popup_product_free[duration_type] = combined_data;
                    var chart_element = document.getElementById('freeproducts-'+duration_type);
                    $scope.getFreeProducts = function(chart_element){
                        var chart = echarts.init(chart_element);
  
                            var itemStyle = {
                                normal: {
                                    color: function(params) {
                                        var colorList = [
                                          '#336699','#7f9ef4', '#486b0b', '#38BD38','#666666','#8C8C8C','#666633', '#4d6633', '#336659'
                                        ];
                                        return colorList[params.dataIndex]
                                    }
                                }, 
                                emphasis: {
                                  label: {
                                    textStyle: {
                                      fontSize: '14',
                                      fontWeight: 'bold',
                                      color: '#222222'
                                    }
                                  }
                                }
                            }; 

                            chart.setOption({
                               grid: {
                                    borderWidth: 0,
                                    y: 50,
                                    y2: 85,
                                    x2: 160

                                },
                                legend: {
                                    data: labels_free_products,
                                    x : 'center',
                                    y:'bottom'
                                },
                                toolbox: {
                                    left: 'left',
                                    itemSize: 25,
                                    effectiveColor: 'red',
                                    color: ['#1e90ff','#22bb22','#4b0082','#d2691e'],
                                    showTitle: false,
                                    borderWidth: 1,
                                    borderColor: '#cccccc',
                                    feature: {
                                        saveAsImage: {
                                            show : true,
                                            title : 'Download',
                                        },
                                    }
                                },  
                                tooltip: {
                                    trigger: 'item',
                                    formatter: "<italic>{b}</italic><br>Total : {c}<br>"
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        show: true,
                                        data: labels_free_products, 
                                        axisLabel: {
                                          interval: 0,
                                          rotate:-11,
                                          margin:12,
                                          textStyle: {
                                            align : 'left',
                                            fontSize :12
                                          }
                                        }
                                    }
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        show: true
                                    }
                                ], 
                                series: [{
                                    name: 'pie',
                                    type: 'bar',
                                    data: data_array_product_free,
                                    itemStyle: itemStyle
                                }],
                                color : [ '#336699','#7f9ef4', '#486b0b', '#38BD38','#666666','#8C8C8C']
                            });

                            var config = {
                                labelPosition: 'outside',
                                clockwise: true,
                                labelLineLen: 20,
                                labelLineLen2: 5
                            };
                        }
                        $scope.getFreeProducts(document.getElementById('freeproducts-'+duration_type));
                    }
                        
                        switch (duration_type)
                        {
                            case "since":
                                $scope.show_freeproducts_since_loading_icon = false;
                                break;
                            case "monthly": 
                                $scope.show_freeproducts_monthly_loading_icon = false;
                                break;
                           default: 
                               break;
                        }
            });
    }
    $scope.show_freeproducts_monthly_popup = function() 
        {
            $('#show_freeproducts_monthly_popup').modal('show');       
            setTimeout(function(){
                $scope.getFreeProducts(document.getElementById('freeproducts1-monthly'));
            },300);     
        };

    $scope.show_freeproducts_since_popup = function() 
        {
            $('#show_freeproducts_since_popup').modal('show');       
            setTimeout(function(){
                $scope.getFreeProducts(document.getElementById('freeproducts1-since'));
            },300);     
        };

}]); 
