hwecApp.controller('manageDoorknockerAddressController', ['$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH','PROVIDER_ID', function ($filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH,PROVIDER_ID) {

        $scope.isActive = true;


         var loader = document.getElementById("loading-content");
        loader.classList.remove("hide-loader");

        $scope.paginatePage = true; 
        $scope.hideNewBtn = false;
        $scope.manage_door_knocker_schedule_button = true;
        $scope.curentUrl = $location.path().replace('/', "");
        $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);

        $scope.pageTitle = $scope.curentUrl.slice(0, -1) + " list";

        $scope.pageTitle = "Doorknocker schedule";
        $scope.backLink = '#/';
        
        //hide success message div 
        angular.element('#success_message_div').hide();
        
        //hide error message div
        angular.element('#error_message_div').hide();
        
        angular.element('#generating_button').hide(); 
         
        // Start : pre-load all door_knocker in multi-select box -- //
        $http({
            method: 'POST',
            url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "listData"},
            headers: {'Content-Type': 'application/json'}
        }).then(function successCallback(response)
        {
            loader.classList.add("hide-loader");
            $scope.doorknocker_list = response['data'];                        
            $scope.doorknocker_list = $scope.doorknocker_list['data'];
        }, function errorCallback(response) {
            loader.classList.add("hide-loader");
           if(response.error) { window.location = "#/logout"; };
        });
        // End : pre-load all door_knocker in multi-select box -- //
          
        var selected_door_knockers = [];
        $scope.selectDoorknocker = function (user_id, door_knocker_name, index)
        {          
            if (!angular.element('#td_door_knocker_' + user_id).hasClass("selected_door_knocker"))
            {
                angular.element('#td_door_knocker_' + user_id).addClass("selected_door_knocker");
                angular.element('#td_door_knocker_' + user_id).css("background-color", "#E6E6E6");
                               
                selected_door_knockers.push(user_id);
                
            } else
            {                
                angular.element('#td_door_knocker_' + user_id).removeClass("selected_door_knocker");
                angular.element('#td_door_knocker_' + user_id).css("background-color", "");

                // Start : remove this doorknocker from array for de-selected door_knocker -- PRIYA BHOJANI -- //
                for (var i = 0, len = selected_door_knockers.length; i < len; i++) 
                {
                    if (selected_door_knockers[i].user_id === user_id) {
                        selected_door_knockers.splice(i,1);                        
                        break;
                    }
                }
                // Start : remove this doorknocker from array for de-selected door_knocker -- PRIYA BHOJANI -- //
            }            
        }; 
        
        $scope.doorKnokerSelect = false;

        $scope.addDoorknockerAddresses = function (user_id, door_knocker_name, index)
        {


            
            if($(".selected_door_knocker:visible").length < 1 ) {
            $scope.doorKnokerSelect = true;

           } else {
               $scope.doorKnokerSelect = false;
               loader.classList.add("hide-loader");   
               angular.element('#generating_button').show();            
               angular.element('#save_button').hide();

               $scope.door_knocker_address_data = {
                    "method_name": "addDoorknockerAddresses",                
                    "door_knocker_id_array" : JSON.stringify(selected_door_knockers),                
                    "user_id": localStorage.getItem('user_id'),
                    "provider_id":PROVIDER_ID,
                    "file" : {
                        "filetype" : $scope.files[0].filetype,
                        "filename" : $scope.files[0].filename,
                        "filesize" : $scope.files[0].filesize,
                        "csv_base64_encrypted_string" : $scope.files[0].base64
                    }
                };

  
                $http({
                    method: 'POST',
                    url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
                    data: $scope.door_knocker_address_data,
                    headers: {'Content-Type': undefined}
                }).success(function(data) 
                {

                    loader.classList.add("hide-loader");

                if (data.response_code === FAILURE_CODE)
                    {
                    angular.element('#error_message_div').show(); 
                    angular.element('#error_message_span').html(data.response_message);
                    angular.element('#generating_button').hide();
                    angular.element('#save_button').show();
                    }
                    else
                    {
                        angular.element('#success_message_div').show();
                        angular.element('#error_message_div').hide(); 
                        angular.element('#error_message_span').html("");                   
                        angular.element('#generating_button').hide();
                        angular.element('#save_button').show();
                    }
                }).error(function(data) {
                    loader.classList.add("hide-loader");
                    if(data.error) { window.location = "#/logout" };
                });


                
           }
        };         
}]);