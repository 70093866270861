var hwecApp = angular.module('hwecApp', ['ngRoute','restangular', 'ngMaterial', 'ui.bootstrap', 'ui.calendar', 'ngMessages', 'ngFileUpload', 'naif.base64','angularUtils.directives.dirPagination','720kb.datepicker'])

    .config(function($mdThemingProvider) {
         $mdThemingProvider.theme('default')
        .primaryPalette('blue')
        .accentPalette('orange');
    })

    .constant('AUTHENTICATION_FAILURE_CODE', 403)
    .constant('PRODUCT_PICTURE_PATH', 'images/product_pictures/')
    .constant('$records_per_page', 20)
    .constant('SUCCESS_CODE', 200)
    .constant('FAILURE_CODE', 300)
    .constant('NOT_FOUND_CODE', 404)
    .constant('PROVIDER_ID', 2)
    .constant('PROVIDER', {
        NAME : 'Wessex Water',
        LOGO : "images/wessex.png",
        PHONE_NUMBER : "0330 066 9559",
        EMAIL_ID : "homecheck@wessexwater.co.uk"
    })
    .constant('$CHECK_PROVIDER_ID',localStorage.getItem('provider_id'))
    .constant('$TIME_SLOT', {
        1 : "T08:00",
        2 : "T09:00",
        3 : "T10:00",
        4 : "T11:00",
        5 : "T11:30",
        6 : "T12:00",
        7 : "T13:00",
        8 : "T14:00",
        9 : "T14:30",
        10 : "T15:00",
        11 : "T16:00",
        12 : "T17:00",
        13 : "T18:00",
        14 : "T19:00",
        15 : "T20:00",
        16 : "T21:00",
        1000 : "AM",
        2000 : "PM",
        
    })  
    .constant('$APPOINTMENT_TIME_DETAIL', {
        1 : "08:00",
        2 : "09:00",
        3 : "10:00",
        4 : "11:00",
        5 : "11:30",
        6 : "12:00",
        7 : "13:00",
        8 : "14:00",
        9 : "14:30",
        10 : "15:00",
        11 : "16:00",
        12 : "17:00",
        13 : "18:00",
        14 : "19:00",
        15 : "20:00",
        16 : "21:00",
        1000 : "AM",
        2000 : "PM",
    });

