hwecApp.controller('installerReportController', ['PROVIDER','$filter', '$scope', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', '$q', function (PROVIDER,$filter, $scope, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL, $q) { 

   
      var loader = document.getElementById("loading-content");
      var pageContent = document.getElementById("installs-section");
      
    loader.classList.remove("hide-loader");
    pageContent.classList.add("hide");
   // get percentage 
   function getPercentage(total, amount) {
        return  ((amount / total) * 100).toFixed(2) + '%';

    };
 
   //full width page 
   $scope.fullWidth = true;
   $scope.descreseSize = false;

   //get this year start and end
    var thisYear = (new Date()).getFullYear();    
   

   //get Mondays and Sundays of THIS week
    var today = new Date();
    var dayOfWeekStartingSundayZeroIndexBased = today.getDay(); // 0 : Sunday ,1 : Monday,2,3,4,5,6 : Saturday
    var mondayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+1);
    var sundayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+7);

    //Mondays
    var Mmonth   = mondayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Mday     = mondayOfWeek.getDate();
    var Myear    = mondayOfWeek.getUTCFullYear();

    //Sundays
    var Smonth   = sundayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Sday     = sundayOfWeek.getDate();
    var Syear    = sundayOfWeek.getUTCFullYear();

    //date from current month
    var beginCurentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var endCurentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);


    var dayBM = beginCurentMonth.getDate();
    var monthBM = beginCurentMonth.getMonth() + 1;
    var yearBM = beginCurentMonth.getUTCFullYear();

    var dayEM = endCurentMonth.getDate();
    var monthEM = endCurentMonth.getMonth() + 1;
    var yearEM = endCurentMonth.getUTCFullYear();


    //format from long date to YYYY-MM-DD
    function formatDate(day, month, year) {
        day = day < 10 ? "0"+day : day;
        month = month < 10 ? "0"+month : month;
        year = year;
        return newdate = year + "-" + month + "-" + day;
    }


    //check lenght of Number
    function checkLength(el) {
        var stringifyNumber = el.toString();

        return stringifyNumber.length;   
    };


    Array.prototype.sum = function() {
    return this.reduce(function(a,b){return a+b;});
    };


   // Start : get completed appointment statistics ----------- PRIYA BHOJANI -- //   
   $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getCompletedAppointmentStatistics",
            "start_date"    : "2016-09-01",
            "end_date" : formatDate(31, 12, thisYear)
        }
    }).success(function(response) {
        $scope.totalAuditsCompleted = response.data.total_completed_appointments;
        $scope.averageWaterSaved = response.data.average_water_saved;
        $scope.averageTimeSpent = response.data.average_appointment_duration;
        $scope.averageScore = response.data.average_SIM_score;
        
        if(checkLength($scope.totalAuditsCompleted) > 3) {
            $scope.descreseSize = true;
        }
        
        getTotalProductsInstalledByDateByProduct();
        
    }).error(function(response) {
       
    });
    
    // End : get completed appointment statistics ----------- PRIYA BHOJANI -- //
    

    /* ========================================================== *
    *            get completed audits statistics by week
    * ========================================================== */
    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getAppointmentStatistics" ,
            "start_date"  : formatDate(Mday, Mmonth, Myear), // this week Monday
	        "finish_date" : formatDate(Sday, Smonth, Syear) // this week Sunday 	
        }
    }).success(function(response) {
        if (response.response_code === FAILURE_CODE) { window.location = "#/logout" }
        $scope.totalAuditsThisWeek = response.appointment_completed;

    }).error(function(response) {
        if (response.response_code === FAILURE_CODE) {
            window.location = "#/logout";     
        } else if(response.error) {
            window.location = "#/logout"; 
        }
    });


   /* ========================================================== *
    *               get audits status by week
    * ========================================================== */
    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name": "getAppointmentCountByCompletedRejectedNoAnswerStatus",
            "from": formatDate(Mday, Mmonth, Myear), // this week Monday
            "to": formatDate(Sday, Smonth, Syear) // this week Sunday
        }  
    }).success(function(response) {

        console.log(response);



        var totalAuditCompWeek     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].completed_appointments) });
        var AuditNoanswerWeek      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].no_answer_appointments) });
        var AuditRejectedWeek      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].rejected_appointments) });
        var totalAuditCanceledWeek = [];


        for( var i = 0; i < AuditNoanswerWeek.length; i++) {
            totalAuditCanceledWeek.push(AuditNoanswerWeek[i]+AuditRejectedWeek[i]);
        }
        
        var totalAuditCompWeekSum     = totalAuditCompWeek.sum();
        var totalAuditCanceledWeekSum = totalAuditCanceledWeek.sum();


        var totalAuditCompAndCancelled = parseInt(totalAuditCompWeekSum) +  parseInt(totalAuditCanceledWeekSum);



        if(totalAuditCompWeekSum !== 0)
        {
            $scope.completeStatusWeek  = getPercentage(totalAuditCompAndCancelled, totalAuditCompWeekSum);
        }
        else
        {            
            $scope.completeStatusWeek  = '0.00%';
        }
          
        if(totalAuditCanceledWeekSum !== 0)
        {
            
            $scope.cancelledStatusWeek = getPercentage(totalAuditCompAndCancelled, totalAuditCanceledWeekSum);
        }        
        else
        {
            $scope.cancelledStatusWeek = '0.00%';
        }

        var largestCompWeek = Math.max.apply(Math, totalAuditCompWeek);
        var largestCancelledWeek = Math.max.apply(Math, totalAuditCanceledWeek);

        totalLargestNumberArrayWeek = [];
        totalLargestNumberArrayWeek.push(largestCompWeek,largestCancelledWeek);
        var totalLargestNumberWeek = Math.max.apply(Math, totalLargestNumberArrayWeek);
        
 
       var ctxWeekAuditStatus = document.getElementsByClassName("audits-status-week");
       var auditsStatusWeekData = {
            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            datasets: [{
                label: 'Completed',
                backgroundColor: "#60bb46",
                yAxisID: "y-axis-1", 
                data: totalAuditCompWeek
            }, {
                label: 'Cancelled', 
                backgroundColor: "#ff0000", 
                yAxisID: "y-axis-2",
                data: totalAuditCanceledWeek
            }]

        };
        
        $scope.modalWeekAuditChart = function(ctxWeekAuditStatus) { 
            window.myBar = Chart.Bar(ctxWeekAuditStatus, {
                data: auditsStatusWeekData, 
                options: {
                    responsive: true,
                    hoverMode: 'label',
                    hoverAnimationDuration: 400,
                    stacked: false,
                    title:{ 
                        display:false   
                    },
                    scales: {
                        yAxes: [{
                            type: "linear", 
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                beginAtZero: true, 
                                max: totalLargestNumberWeek
                            }
                        }, {
                            type: "linear", 
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            ticks: {
                                beginAtZero: true,
                                max: totalLargestNumberWeek
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }]
                    }
                }
            });
        };
        $scope.modalWeekAuditChart(ctxWeekAuditStatus);

    }).error(function(response) {
        if (response.response_code === FAILURE_CODE) {
            window.location = "#/logout";     
        } else if(response.error) {
            window.location = "#/logout"; 
        }
    });

    var ctxWeekAuditStatus1 = document.getElementsByClassName("audits-status-week1");
    $scope.showWeekAuditStatus = function() {
        $('#modalWeekAuditStatus').modal('show');
        setTimeout(function() {
            $scope.modalWeekAuditChart(ctxWeekAuditStatus1);
        },300)
    };


    /* ========================================================== *
    w*               get audits status by month
    * ========================================================== */

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
             "method_name": "getAppointmentCountByCompletedRejectedNoAnswerStatusByYear",
             "from": formatDate(1,1,thisYear), 
             "to": formatDate(31,12,thisYear) 
        } 
    }).success(function(response) {
     

        var totalJan = parseInt(response.data.data_array.January.rejected) + parseInt(response.data.data_array.January.no_answer);
        var totalFeb = parseInt(response.data.data_array.February.rejected) + parseInt(response.data.data_array.February.no_answer);
        var totalMar = parseInt(response.data.data_array.March.rejected) + parseInt(response.data.data_array.March.no_answer);
        var totalApr = parseInt(response.data.data_array.April.rejected) + parseInt(response.data.data_array.April.no_answer);
        var totalMay = parseInt(response.data.data_array.May.rejected) + parseInt(response.data.data_array.May.no_answer);
        var totalJun = parseInt(response.data.data_array.June.rejected) + parseInt(response.data.data_array.June.no_answer);
        var totalJul = parseInt(response.data.data_array.July.rejected) + parseInt(response.data.data_array.July.no_answer);
        var totalAug = parseInt(response.data.data_array.August.rejected) + parseInt(response.data.data_array.August.no_answer);
        var totalSep = parseInt(response.data.data_array.September.rejected) + parseInt(response.data.data_array.September.no_answer);
        var totalOct = parseInt(response.data.data_array.October.rejected) + parseInt(response.data.data_array.October.no_answer);
        var totalNov = parseInt(response.data.data_array.November.rejected) + parseInt(response.data.data_array.November.no_answer);
        var totalDec = parseInt(response.data.data_array.December.rejected) + parseInt(response.data.data_array.December.no_answer);

        var totalYearSourceCancelled  = totalJan +
                                        totalFeb +
                                        totalMar + 
                                        totalApr +
                                        totalMay + 
                                        totalJun + 
                                        totalJul + 
                                        totalAug + 
                                        totalSep + 
                                        totalOct + 
                                        totalNov + 
                                        totalDec ;

        var totalYearStatusComp       =  parseInt(response.data.data_array.January.completed) +
                                         parseInt(response.data.data_array.February.completed) + 
                                         parseInt(response.data.data_array.March.completed) +
                                         parseInt(response.data.data_array.April.completed) + 
                                         parseInt(response.data.data_array.May.completed) + 
                                         parseInt(response.data.data_array.June.completed) + 
                                         parseInt(response.data.data_array.July.completed) + 
                                         parseInt(response.data.data_array.August.completed) + 
                                         parseInt(response.data.data_array.September.completed) + 
                                         parseInt(response.data.data_array.October.completed) + 
                                         parseInt(response.data.data_array.November.completed) + 
                                         parseInt(response.data.data_array.December.completed);                        

        var totalYearStatusCompAndCancelled = parseInt(totalYearStatusComp + totalYearSourceCancelled);
                                              
        $scope.yearSourceCompletedPercentage = getPercentage( totalYearStatusCompAndCancelled,  totalYearStatusComp);
        $scope.yearSourceCancelledPercentage = getPercentage( totalYearStatusCompAndCancelled,  totalYearSourceCancelled);

        var numberComp = [];
        var numberCancelled = [];

        numberCancelled.push(
            totalJan,
            totalFeb,
            totalMar,
            totalApr,
            totalMay,
            totalJun,
            totalJul,
            totalAug,
            totalSep,
            totalOct,
            totalNov,
            totalDec
        );
        
        numberComp.push(
            parseInt(response.data.data_array.January.completed),
            parseInt(response.data.data_array.February.completed),
            parseInt(response.data.data_array.March.completed),
            parseInt(response.data.data_array.April.completed),
            parseInt(response.data.data_array.May.completed),
            parseInt(response.data.data_array.June.completed),
            parseInt(response.data.data_array.July.completed),
            parseInt(response.data.data_array.August.completed),
            parseInt(response.data.data_array.September.completed),
            parseInt(response.data.data_array.October.completed),
            parseInt(response.data.data_array.November.completed),
            parseInt(response.data.data_array.December.completed)
        );
        

       var largestCancelled = Math.max.apply(Math, numberCancelled);
       var largestComp = Math.max.apply(Math, numberComp);

       var totalLargestNumberArray = [];
       totalLargestNumberArray.push(largestCancelled,largestComp);
       var totalLargestNumber = Math.max.apply(Math, totalLargestNumberArray);


        var dateInit = new Date();
        var getCurrentMonthV = dateInit.getMonth();

        var monthPositionCompleted = [];
        var monthPositionCancelled = [];
        var completedThisM = [];
        var cancelledThisM = [];

        switch (getCurrentMonthV)
            {
               case 0: 
                   monthPositionCompleted = [ response.data.data_array.January.completed, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
                   monthPositionCancelled = [ totalJan, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
                   completedThisM.push(response.data.data_array.January.completed);
                   cancelledThisM.push(totalJan);
               break;
               case 1: 
                     monthPositionCompleted = [ 0, response.data.data_array.February.completed, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
                     monthPositionCancelled = [ 0, totalFeb, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
                     completedThisM.push(response.data.data_array.February.completed);
                     cancelledThisM.push(totalFeb);
               break;     
              case 2: 
                     monthPositionCompleted = [ 0, 0, response.data.data_array.March.completed, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                     monthPositionCancelled = [ 0, 0, totalMar, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                     completedThisM.push(response.data.data_array.March.completed);
                     cancelledThisM.push(totalMar);
              break;       
              case 3: 
                     monthPositionCompleted = [ 0, 0, 0, response.data.data_array.April.completed, 0, 0, 0, 0, 0, 0, 0, 0];
                     monthPositionCancelled = [ 0, 0, 0, totalApr, 0, 0, 0, 0, 0, 0, 0, 0];
                     completedThisM.push(response.data.data_array.April.completed);
                     cancelledThisM.push(totalApr);
              break;      
              case 4: 
                     monthPositionCompleted = [ 0, 0, 0, 0, response.data.data_array.May.completed, 0, 0, 0, 0, 0, 0, 0];  
                     monthPositionCancelled = [ 0, 0, 0, 0, totalMay, 0, 0, 0, 0, 0, 0, 0];
                     completedThisM.push(response.data.data_array.May.completed);
                     cancelledThisM.push(totalMay);
              break;                      
              case 5: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, response.data.data_array.June.completed, 0, 0, 0, 0, 0, 0];  
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, totalJun, 0, 0, 0, 0, 0, 0];
                     completedThisM.push(response.data.data_array.June.completed);
                     cancelledThisM.push(totalJun);
              break;       
              case 6: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, 0, response.data.data_array.July.completed, 0, 0, 0, 0, 0];
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, 0, totalJul, 0, 0, 0, 0, 0];
                     completedThisM.push(response.data.data_array.July.completed);
                     cancelledThisM.push(totalJul);
              break;        
              case 7: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, 0, 0, response.data.data_array.August.completed, 0, 0, 0, 0]; 
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, 0, 0, totalAug, 0, 0, 0, 0];
                     completedThisM.push(response.data.data_array.August.completed);
                     cancelledThisM.push(totalAug);
              break;
              case 8: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, 0, 0, 0, response.data.data_array.September.completed, 0, 0, 0];  
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, 0, 0, 0, totalSep, 0, 0, 0]; 
                     completedThisM.push(response.data.data_array.September.completed);
                     cancelledThisM.push(totalSep);    
              break;
              case 9: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, response.data.data_array.October.completed, 0, 0];  
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, totalOct, 0, 0];   
                     completedThisM.push(response.data.data_array.October.completed);
                     cancelledThisM.push(totalOct);      
              break;
              case 10: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, response.data.data_array.November.completed, 0]; 
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, totalNov, 0];   
                     completedThisM.push(response.data.data_array.November.completed);
                     cancelledThisM.push(totalNov); 
              break;          
              case 11: 
                     monthPositionCompleted = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, response.data.data_array.December.completed];                
                     monthPositionCancelled = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, totalDec ]; 
                     completedThisM.push(response.data.data_array.December.completed);
                     cancelledThisM.push(totalDec);        
            };

          
            var totalMonthNum = parseInt(completedThisM) + parseInt(cancelledThisM);

            $scope.MSourceCompletedPercentage = getPercentage( totalMonthNum,  completedThisM);
            $scope.MSourceCancelledPercentage = getPercentage( totalMonthNum,  cancelledThisM);

       var ctxMonthAuditStatus = document.getElementsByClassName("audits-status-month");
       var ctxYAuditStatus = document.getElementsByClassName("audits-status-y");
       var auditsStatusMonthData = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
                label: 'Completed',
                backgroundColor: "#60bb46",
                yAxisID: "y-axis-1", 
                 data: monthPositionCompleted
            }, {
                label: 'Rejected', 
                backgroundColor: "#ff0000", 
                yAxisID: "y-axis-2",
                data: monthPositionCancelled
            }]
        };
        

        var auditsStatusYData = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
                label: 'Completed',
                backgroundColor: "#60bb46",
                yAxisID: "y-axis-1", 
                 data:[
                    response.data.data_array.January.completed,
                    response.data.data_array.February.completed,
                    response.data.data_array.March.completed,
                    response.data.data_array.April.completed,
                    response.data.data_array.May.completed,
                    response.data.data_array.June.completed,
                    response.data.data_array.July.completed,
                    response.data.data_array.August.completed,
                    response.data.data_array.September.completed,
                    response.data.data_array.October.completed,
                    response.data.data_array.November.completed,
                    response.data.data_array.December.completed,
                ]
            }, {
                label: 'Rejected', 
                backgroundColor: "#ff0000", 
                yAxisID: "y-axis-2",
                data: [
                    totalJan,
                    totalFeb,
                    totalMar,
                    totalApr,
                    totalMay,
                    totalJun,
                    totalJul,
                    totalAug,
                    totalSep,
                    totalOct,
                    totalNov,
                    totalDec
                ]
            }]
        };
        
        $scope.modalMonthAuditChart = function(ctxMonthAuditStatus) { 
            window.myBar = Chart.Bar(ctxMonthAuditStatus, {
                data: auditsStatusMonthData, 
                options: {
                    responsive: true,
                    hoverMode: 'label',
                    hoverAnimationDuration: 400,
                    stacked: false,
                    title:{ 
                        display:false   
                    },
                    scales: {
                        yAxes: [{
                            type: "linear", 
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                beginAtZero: true,
                                max: totalLargestNumber
                            }
                        }, {
                            type: "linear", 
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                             ticks: {
                                beginAtZero: true,
                                max: totalLargestNumber
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }],
                    }
                }
            });
        };
        $scope.modalMonthAuditChart(ctxMonthAuditStatus);

        $scope.modalYAuditChart = function(ctxYAuditStatus) { 
            window.myBar = Chart.Bar(ctxYAuditStatus, {
                data: auditsStatusYData, 
                options: {
                    responsive: true,
                    hoverMode: 'label',
                    hoverAnimationDuration: 400,
                    stacked: false,
                    title:{ 
                        display:false   
                    },
                    scales: {
                        yAxes: [{
                            type: "linear", 
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                beginAtZero: true,
                                max: totalLargestNumber
                            }
                        }, {
                            type: "linear", 
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                             ticks: {
                                beginAtZero: true,
                                max: totalLargestNumber
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }],
                    }
                }
            });
        };
        $scope.modalYAuditChart(ctxYAuditStatus);

    }).error(function(response) {
        if (response.response_code === FAILURE_CODE) {
            window.location = "#/logout";     
        } else if(response.error) {
            window.location = "#/logout"; 
        }
    });

    var ctxMonthAuditStatus1 = document.getElementsByClassName("audits-status-month1");
    $scope.showMonthAuditStatus = function() {
        $('#modalMonthAuditStatus').modal('show');
        setTimeout(function() {
            $scope.modalMonthAuditChart(ctxMonthAuditStatus1);
        },300)
    };

    var ctxYAuditStatus1 = document.getElementsByClassName("audits-status-y1");
    $scope.showYAuditStatus = function() {
        $('#modalYAuditStatus').modal('show');
        setTimeout(function() {
            $scope.modalYAuditChart(ctxYAuditStatus1);
        },300)
    };
    

    /* ========================================================== *
    *               get audids statistics by month
    * ========================================================== */

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name"   : "getAppointmentStatistics" ,
            "start_date"    : formatDate(dayBM, monthBM, yearBM), // current month start
            "finish_date" : formatDate(dayEM, monthEM, yearEM) // current month end	
        }
    }).success(function(response) {
        if (response.response_code === FAILURE_CODE) { window.location = "#/logout" }

        $scope.totalAuditsThisMonth = response.appointment_completed;

    }).error(function(response) {
        if (response.response_code === FAILURE_CODE) {
            window.location = "#/logout";     
        } else if(response.error) {
            window.location = "#/logout"; 
        }
    });


    /* ========================================================== *
    *               get products installed this week
    * ========================================================== */

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name"   : "getTotalProductsInstalledByDateByProduct", 
            "from": formatDate(Mday, Mmonth, Myear), // this week Monday
            "to": formatDate(Sday, Smonth, Syear) // this week Sunday
        }
    }).success(function(response) { 

        var tapBathroom     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['5 L Tap Inserts Bathroom']) });
        var tapKitchen      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['5 L Tap Inserts Kitchen']) });
        var bubble          = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['BubbleStream']) });
        var cistern         = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Cistern Displacement Device']) });
        var dualFlush       = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Dual flush reminder sticker']) });
        var ecoBeta         = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['EcoBeta']) });
        var flowSaver       = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['FlowSaver']) });
        var fourMinute      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Four Minute Shower Timer']) });
        var showerChrome    = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Shower Head Chrome']) });
        var showerWhite     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Shower Head White']) });
        var showerSave      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['ShowerSave']) });
        var tootyTimer      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Toothy Timer']) });



        $scope.tapBathroomEl    = tapBathroom.sum();
        $scope.tapKitchenEl     = tapKitchen.sum();
        $scope.bubbleEl         = bubble.sum();
        $scope.cisternEl        = cistern.sum();
        $scope.dualFlushEl      = dualFlush.sum();
        $scope.ecoBetaEl        = ecoBeta.sum(); 
        $scope.flowSaverEl      = flowSaver.sum();
        $scope.fourMinuteEl     = fourMinute.sum();
        $scope.showerChromeEl   = showerChrome.sum();
        $scope.showerWhiteEl    = showerWhite.sum();
        $scope.showerSaveEl     = showerSave.sum();
        $scope.tootyTimerEl     = tootyTimer.sum();


        

        var ctxProductsWeek = document.getElementsByClassName("products-fitted-week");
        $scope.modalProductsWeek = function(ctxProductsWeek) {

        var statusMonthChart = new Chart(ctxProductsWeek, {
            type: 'doughnut',
            data: {
                labels: [
                    "5 L Tap Inserts Bathroom ", 
                    "5 L Tap Inserts Kitchen", 
                    "BubbleStream", 
                    "Cistern Displacement Device", 
                    "Dual flush reminder sticker", 
                    "EcoBeta",
                    "Shower Head Chrome",
                    "Shower Head White",
                    "ShowerSave",
                    "FlowSaver",
                    "Toothy Timer", 
                    "Four Minute Shower Timer"
                    ],
                datasets: [{
                    label: 'Satus',
                    data:[
                        $scope.tapBathroomEl,
                        $scope.tapKitchenEl,
                        $scope.bubbleEl,
                        $scope.cisternEl,
                        $scope.dualFlushEl,
                        $scope.ecoBetaEl,
                        $scope.showerChromeEl,
                        $scope.showerWhiteEl,
                        $scope.showerSaveEl,
                        $scope.flowSaverEl,
                        $scope.tootyTimerEl,
                        $scope.fourMinuteEl
                        
                        ],
                    backgroundColor: [
                        // '#ff0000',
                        // '#0083c6',
                        // '#00aeef',
                        // '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        // 'rgba(259, 80, 30, 1)',
                        // 'rgba(15, 79, 93, 1)',
                        // 'rgba(177, 84, 75, 1)',
                        // '#60bb46',
                        // '#ff00ff',
                        // '#3a3a3a',
                        // '#6ac39a'
                        '#ae9c02',
                        '#d3be44',
                        '#f5df83',
                        '#096523',
                        '#0f8932',
                        '#15aa3f',
                        '#00005b',
                        '#122da1', 
                        '#445fd3',
                        '#94afff',
                        '#7c0d0d', 
                        '#a4322a'

                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });

        }
        $scope.modalProductsWeek(ctxProductsWeek); 

    }).error(function(response) {
     
    });

    var ctxProductsWeek1 = document.getElementsByClassName("products-fitted-week1");
    $scope.showProductsFittedWeek = function() {
        $('#modalProductsFittedWeek').modal('show');
        setTimeout(function() {
            $scope.modalProductsWeek(ctxProductsWeek1);
        },300)
    };


   /* ========================================================== *
    *               get products installed this month
    * ========================================================== */

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name"   : "getTotalProductsInstalledByDateByProduct", 
            "from"    : formatDate(dayBM, monthBM, yearBM), // current month start
            "to" : formatDate(dayEM, monthEM, yearEM) // current month end	
        }
    }).success(function(response) { 

        var tapBathroomM     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['5 L Tap Inserts Bathroom']) });
        var tapKitchenM      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['5 L Tap Inserts Kitchen']) });
        var bubbleM          = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['BubbleStream']) });
        var cisternM         = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Cistern Displacement Device']) });
        var dualFlushM       = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Dual flush reminder sticker']) });
        var ecoBetaM         = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['EcoBeta']) });
        var flowSaverM       = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['FlowSaver']) });
        var fourMinuteM      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Four Minute Shower Timer']) });
        var showerChromeM    = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Shower Head Chrome']) });
        var showerWhiteM     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Shower Head White']) });
        var showerSaveM      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['ShowerSave']) });
        var tootyTimerM      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Toothy Timer']) });


        $scope.tapBathroomElM    = tapBathroomM.sum();
        $scope.tapKitchenElM     = tapKitchenM.sum();
        $scope.bubbleElM         = bubbleM.sum();
        $scope.cisternElM        = cisternM.sum();
        $scope.dualFlushElM      = dualFlushM.sum();
        $scope.ecoBetaElM        = ecoBetaM.sum();
        $scope.flowSaverElM      = flowSaverM.sum();
        $scope.fourMinuteElM     = fourMinuteM.sum();
        $scope.showerChromeElM   = showerChromeM.sum();
        $scope.showerWhiteElM    = showerWhiteM.sum();
        $scope.showerSaveElM     = showerSaveM.sum();
        $scope.tootyTimerElM     = tootyTimerM.sum();
        

        var ctxProductsMonth = document.getElementsByClassName("products-fitted-month");
        $scope.modalProductsMonth = function(ctxProductsMonth) {

        var statusMonthChart = new Chart(ctxProductsMonth, {
            type: 'doughnut',
            data: {
                labels: [
                    "5 L Tap Inserts Bathroom ", 
                    "5 L Tap Inserts Kitchen", 
                    "BubbleStream", 
                    "Cistern Displacement Device", 
                    "Dual flush reminder sticker", 
                    "EcoBeta",
                    "Shower Head Chrome",
                    "Shower Head White",
                    "ShowerSave",
                    "FlowSaver",
                    "Toothy Timer", 
                    "Four Minute Shower Timer"
                    ],
                datasets: [{
                    label: 'Satus',
                    data:[
                        $scope.tapBathroomElM,
                        $scope.tapKitchenElM,
                        $scope.bubbleElM,
                        $scope.cisternElM,
                        $scope.dualFlushElM,
                        $scope.ecoBetaElM,
                        $scope.showerChromeElM,
                        $scope.showerWhiteElM,
                        $scope.showerSaveElM,
                        $scope.flowSaverElM,
                        $scope.tootyTimerElM,
                        $scope.fourMinuteElM
                        ],
                      backgroundColor: [
                         // '#ff0000',
                        // '#0083c6',
                        // '#00aeef',
                        // '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        // 'rgba(259, 80, 30, 1)',
                        // 'rgba(15, 79, 93, 1)',
                        // 'rgba(177, 84, 75, 1)', 
                        // '#60bb46',
                        // '#ff00ff',
                        // '#3a3a3a',
                        // '#6ac39a'
                        '#ae9c02',
                        '#d3be44',
                        '#f5df83',
                        '#096523',
                        '#0f8932',
                        '#15aa3f',
                        '#00005b',
                        '#122da1', 
                        '#445fd3',
                        '#94afff',
                        '#7c0d0d', 
                        '#a4322a'
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });

        }
        $scope.modalProductsMonth(ctxProductsMonth); 

    }).error(function(response) {
    
    });

    var ctxProductsMonth1 = document.getElementsByClassName("products-fitted-month1");
    $scope.showProductsFittedMonth = function() {
        $('#modalProductsFittedMonth').modal('show');
        setTimeout(function() {
            $scope.modalProductsMonth(ctxProductsMonth1);
        },300)
    };


    function getTotalProductsInstalledByDateByProduct()
    {
        /* ========================================================== *
        *              get products installed since start
        * ========================================================== */



        $http({
            method: 'POST',
            url: 'statistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name"   : "getTotalProductsInstalledByDateByProduct", 
                "from"    : "2016-09-01",
                "to" : formatDate(31, 12, thisYear)
            }
        }).success(function(response) { 

            var tapBathroomSinceStart     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['5 L Tap Inserts Bathroom']) });
            var tapKitchenSinceStart      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['5 L Tap Inserts Kitchen']) });
            var bubbleSinceStart          = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['BubbleStream']) });
            var cisternSinceStart         = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Cistern Displacement Device']) });
            var dualFlushSinceStart       = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Dual flush reminder sticker']) });
            var ecoBetaSinceStart         = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['EcoBeta']) });
            var flowSaverSinceStart       = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['FlowSaver']) });
            var fourMinuteSinceStart      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Four Minute Shower Timer']) });
            var showerChromeSinceStart    = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Shower Head Chrome']) });
            var showerWhiteSinceStart     = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Shower Head White']) });
            var showerSaveSinceStart      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['ShowerSave']) });
            var tootyTimerSinceStart      = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k]['Toothy Timer']) });


            $scope.tapBathroomElSinceStart    = tapBathroomSinceStart.sum();
            $scope.tapKitchenElSinceStart     = tapKitchenSinceStart.sum();
            $scope.bubbleElSinceStart         = bubbleSinceStart.sum();
            $scope.cisternElSinceStart        = cisternSinceStart.sum();
            $scope.dualFlushElSinceStart      = dualFlushSinceStart.sum();
            $scope.ecoBetaElSinceStart        = ecoBetaSinceStart.sum();
            $scope.flowSaverElSinceStart      = flowSaverSinceStart.sum();
            $scope.fourMinuteElSinceStart     = fourMinuteSinceStart.sum();
            $scope.showerChromeElSinceStart   = showerChromeSinceStart.sum();
            $scope.showerWhiteElSinceStart    = showerWhiteSinceStart.sum();
            $scope.showerSaveElSinceStart     = showerSaveSinceStart.sum();
            $scope.tootyTimerElSinceStart     = tootyTimerSinceStart.sum();
console.log($scope.fourMinuteElSinceStart);  
            $scope.totalSumProductsInstalled = $scope.tapBathroomElSinceStart + 
                                               $scope.tapKitchenElSinceStart +
                                               $scope.bubbleElSinceStart +
                                               $scope.cisternElSinceStart +
                                               $scope.dualFlushElSinceStart +
                                               $scope.ecoBetaElSinceStart +
                                               $scope.flowSaverElSinceStart + 
                                               $scope.fourMinuteElSinceStart + 
                                               $scope.showerChromeElSinceStart + 
                                               $scope.showerWhiteElSinceStart + 
                                               $scope.showerSaveElSinceStart + 
                                               $scope.tootyTimerElSinceStart;

          //percentages
           $scope.showerHeadsInstallP = getPercentage($scope.totalAuditsCompleted, ($scope.showerChromeElSinceStart + $scope.showerWhiteElSinceStart) );
           $scope.showerSaveInstallP  = getPercentage($scope.totalAuditsCompleted, $scope.showerSaveElSinceStart);
           $scope.flowSaverInstallP   = getPercentage($scope.totalAuditsCompleted, $scope.flowSaverElSinceStart);
           $scope.ecoBetaInstallP     = getPercentage($scope.totalAuditsCompleted, $scope.ecoBetaElSinceStart); 
           $scope.cisternBagsInstallP = getPercentage($scope.totalAuditsCompleted, $scope.cisternElSinceStart);
           $scope.tapInsertsInstallP  = getPercentage($scope.totalAuditsCompleted, ($scope.tapBathroomElSinceStart + $scope.tapKitchenElSinceStart));
           $scope.bubbleSteamInstallP = getPercentage($scope.totalAuditsCompleted, $scope.bubbleElSinceStart);
           $scope.showerTimerInstallP = getPercentage($scope.totalAuditsCompleted, $scope.fourMinuteElSinceStart);
           $scope.toothyTimerInstallP = getPercentage($scope.totalAuditsCompleted, $scope.tootyTimerElSinceStart);



        //get unique products fitted 

        $http({
            method: 'POST',
            url: 'statistics/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name" : "getProductUniqueFitted",
                "from"    : "2016-09-01",
                "to" : formatDate(31, 12, thisYear)
            }
        }).success(function(response) {

            if (response.response_code === FAILURE_CODE) { window.location = "#/logout" }

            $scope.showerHeadsUnique = parseInt(response.data['Shower Head Chrome']) + parseInt(response.data['Shower Head White']);
            $scope.showerSaveUnique = parseInt(response.data['ShowerSave']);
            $scope.flowSaverUnique = parseInt(response.data['FlowSaver']);
            $scope.ecoBetaUnique = parseInt(response.data['EcoBeta']);
            $scope.cisternBagsUnique = parseInt(response.data['Cistern Displacement Device']);
            $scope.tapInsertsUnique = parseInt(response.data['5 L Tap Inserts Bathroom']) + parseInt(response.data['5 L Tap Inserts Kitchen']);
            $scope.bubbleSteamUnique = parseInt(response.data['BubbleStream']);
            $scope.showerTimerUnique = parseInt(response.data['Four Minute Shower Timer']);
            $scope.toothyTimerUnique = parseInt(response.data['Toothy Timer']);

            $scope.showerHeadsUniquePercentage  = getPercentage($scope.totalAuditsCompleted, $scope.showerHeadsUnique);
            $scope.showerSaveUniquePercentage   = getPercentage($scope.totalAuditsCompleted, $scope.showerSaveUnique);
            $scope.flowSaverUniquePercentage    =  getPercentage($scope.totalAuditsCompleted, $scope.flowSaverUnique);
            $scope.ecoBetaUniquePercentage      = getPercentage($scope.totalAuditsCompleted, $scope.ecoBetaUnique);
            $scope.cisternBagsUniquePercentage  = getPercentage($scope.totalAuditsCompleted, $scope.cisternBagsUnique);
            $scope.tapInsertsUniquePercentage   = getPercentage($scope.totalAuditsCompleted, $scope.tapInsertsUnique);
            $scope.bubbleSteamUniquePercentage  = getPercentage($scope.totalAuditsCompleted, $scope.bubbleSteamUnique);
            $scope.showerTimerUniquePercentage  = getPercentage($scope.totalAuditsCompleted, $scope.showerTimerUnique);
            $scope.toothyTimerUniquePercentage  = getPercentage($scope.totalAuditsCompleted, $scope.toothyTimerUnique);

        }).error(function(response) {
             if (response.response_code === FAILURE_CODE) {
                window.location = "#/logout";     
            } else if(response.error) {
                window.location = "#/logout"; 
            }
        });


            var ctxProductsSinceStart = document.getElementsByClassName("products-fitted-since-start");
            $scope.modalProductsSinceStart = function(ctxProductsSinceStart) {

            var statusSinceStartChart = new Chart(ctxProductsSinceStart, {
                type: 'doughnut',
                data: {
                     labels: [
                        "5 L Tap Inserts Bathroom ", 
                        "5 L Tap Inserts Kitchen", 
                        "BubbleStream", 
                        "Cistern Displacement Device", 
                        "Dual flush reminder sticker", 
                        "EcoBeta",
                        "Shower Head Chrome",
                        "Shower Head White",
                        "ShowerSave",
                        "FlowSaver",
                        "Toothy Timer", 
                        "Four Minute Shower Timer"
                        ],
                    datasets: [{
                        label: 'Satus',
                        data:[
                            $scope.tapBathroomElSinceStart,
                            $scope.tapKitchenElSinceStart,
                            $scope.bubbleElSinceStart,
                            $scope.cisternElSinceStart,
                            $scope.dualFlushElSinceStart,
                            $scope.ecoBetaElSinceStart,
                            $scope.showerChromeElSinceStart,
                            $scope.showerWhiteElSinceStart,
                            $scope.showerSaveElSinceStart,
                            $scope.flowSaverElSinceStart,
                            $scope.tootyTimerElSinceStart,
                            $scope.fourMinuteElSinceStart
                            ],

                        backgroundColor: [
                            // '#ff0000',
                            // '#0083c6',
                            // '#00aeef',
                            // '#f8991d',
                            // 'rgba(153, 102, 255, 1)',
                            // 'rgba(259, 80, 30, 1)',
                            // 'rgba(15, 79, 93, 1)',
                            // 'rgba(177, 84, 75, 1)',
                            // '#60bb46',
                            // '#ff00ff',
                            // '#3a3a3a',
                            // '#6ac39a'
                            '#ae9c02', 
                            '#d3be44',
                            '#f5df83',
                            '#096523',
                            '#0f8932',
                            '#15aa3f',
                            '#00005b',
                            '#122da1', 
                            '#445fd3',
                            '#94afff',
                            '#7c0d0d', 
                            '#a4322a'

                        ] 
                    }]
                },
                options: {
                    cutoutPercentage: 50,
                    title:{
                        display:false,
                        text:"Monday"
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero:true,
                                display: false
                            }
                        }]
                    }
                }
            });

            }
            $scope.modalProductsSinceStart(ctxProductsSinceStart); 
            loader.classList.add("hide-loader");
            pageContent.classList.remove("hide");
        }).error(function(response) {

        });

        var ctxProductsSinceStart1 = document.getElementsByClassName("products-fitted-since-start1");
        $scope.showProductsFittedSinceStart = function() {
            $('#modalProductsFittedSinceStart').modal('show');
            setTimeout(function() {
                $scope.modalProductsSinceStart(ctxProductsSinceStart1);
            },300);
        };
    }

   

    /* ========================================================== *
    *                 get liks statistics per week 
    * ========================================================== */ 
    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getLeaksStaticsByDate",
            "from"    : formatDate(Mday, Mmonth, Myear), // this week Monday
            "to" : formatDate(Sday, Smonth, Syear) // this week Sunday 
        }
    }).success(function(response) {
       
        var foundToiletLeaksW = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_toilet_leaks) });
        var foundTapLeaksW    = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_tap_leaks) }); 
        var foundShowerLeaksW = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_shower_leaks) });

        var fixedToiletLeaksW = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_toilet_leaks) });
        var fixedTapLeaksW = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_tap_leaks) });
        var fixedShowerLeaksW = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_shower_leaks) });

        $scope.totalToiletLeaksFoundW = foundToiletLeaksW.sum();
        $scope.totalTapLeaksFoundW = foundTapLeaksW.sum();
        $scope.totalShowerLeaksFoundW = foundShowerLeaksW.sum();

        $scope.totalToiletLeaksFixedW = fixedToiletLeaksW.sum();
        $scope.totalTapLeaksFixedW = fixedTapLeaksW.sum();
        $scope.totalShowerLeaksFixedW = fixedShowerLeaksW.sum();

    }).error(function(response) {
       
    });
 
    /* ========================================================== *
    *                 get liks statistics per month 
    * ========================================================== */
    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getLeaksStaticsByDate",
            "from"    : formatDate(dayBM, monthBM, yearBM), // current month start
            "to" : formatDate(dayEM, monthEM, yearEM) // current month end
        }
    }).success(function(response) {

        var foundToiletLeaksM = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_toilet_leaks) });
        var foundTapLeaksM    = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_tap_leaks) }); 
        var foundShowerLeaksM = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_shower_leaks) });

        var fixedToiletLeaksM = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_toilet_leaks) });
        var fixedTapLeaksM = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_tap_leaks) });
        var fixedShowerLeaksM = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_shower_leaks) });

        $scope.totalToiletLeaksFoundM = foundToiletLeaksM.sum();
        $scope.totalTapLeaksFoundM = foundTapLeaksM.sum();
        $scope.totalShowerLeaksFoundM = foundShowerLeaksM.sum();

        $scope.totalToiletLeaksFixedM = fixedToiletLeaksM.sum();
        $scope.totalTapLeaksFixedM = fixedTapLeaksM.sum();
        $scope.totalShowerLeaksFixedM = fixedShowerLeaksM.sum();

    }).error(function(response) {
       
    });

     /* ========================================================== *
    *                get liks statistics since start 
    * ========================================================== */
    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getLeaksStaticsByDate",
            "from"    : "2016-09-01",
            "to" : formatDate(31, 12, thisYear)
        }
    }).success(function(response) {

        var foundToiletLeaksSinceStart = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_toilet_leaks) });
        var foundTapLeaksSinceStart    = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_tap_leaks) }); 
        var foundShowerLeaksSinceStart = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].found_shower_leaks) });

        var fixedToiletLeaksSinceStart = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_toilet_leaks) });
        var fixedTapLeaksSinceStart = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_tap_leaks) });
        var fixedShowerLeaksSinceStart = Object.keys(response.data.data_array).map(function(k){ return parseInt(response.data.data_array[k].fixed_shower_leaks) });

        $scope.totalToiletLeaksFoundSinceStart = foundToiletLeaksSinceStart.sum();
        $scope.totalTapLeaksFoundSinceStart = foundTapLeaksSinceStart.sum();
        $scope.totalShowerLeaksFoundSinceStart = foundShowerLeaksSinceStart.sum();

        $scope.totalToiletLeaksFixedSinceStart = fixedToiletLeaksSinceStart.sum();
        $scope.totalTapLeaksFixedSinceStart = fixedTapLeaksSinceStart.sum();
        $scope.totalShowerLeaksFixedSinceStart = fixedShowerLeaksSinceStart.sum();

    }).error(function(response) {
       
    });
    
    
   

}]);