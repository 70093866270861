hwecApp.controller('overrideDoorknockerAddressController', ['$route','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH','PROVIDER_ID', function ($route,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH,PROVIDER_ID) {

         var loader = document.getElementById("loading-content");
        loader.classList.remove("hide-loader");
 
        $scope.paginatePage = true; 
        $scope.hideNewBtn = false;
        $scope.manage_door_knocker_schedule_button = true;
        $scope.curentUrl = $location.path().replace('/', "");
        $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);
 
        $scope.pageTitle = $scope.curentUrl.slice(0, -1) + " list";

        $scope.pageTitle = "Override Doorknocker Addresses";
        $scope.backLink = '#/';
        
        $scope.doorknocker_addresses = [];
        $scope.parent_object = {};
        
        $scope.show_error_message = false;
        $scope.error_message_text = "";
        $scope.show_success_message = false;
        $scope.show_overriding_button = false;
        $scope.show_save_button = true;
        
        // Start : pre-load all door_knocker in multi-select box -- //
        $http({
            method: 'POST',
            url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "listData"},
            headers: {'Content-Type': 'application/json'}
        }).then(function successCallback(response)
        {
            loader.classList.add("hide-loader");
            $scope.doorknocker_list = response['data'];                        
            $scope.doorknocker_list = $scope.doorknocker_list['data'];
        }, function errorCallback(response) {
            loader.classList.add("hide-loader");
           if(response.error) { window.location = "#/logout"; };
        });
        // End : pre-load all door_knocker in multi-select box -- //
        
        // Start : pre-load all door_knocker addresses -- //
        $http
        ({
            method: 'POST',
            url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "getDoorknockerAddresses"},
            headers: {'Content-Type': 'application/json'}
        })
        .then(function successCallback(response)
        {
            $scope.doorknocker_addresses = response.data.data;
            console.log($scope.doorknocker_addresses);
        },
        function errorCallback(response) 
        {
            loader.classList.add("hide-loader");
            if(response.error) { window.location = "#/logout"; };
        });
        // End : pre-load all door_knocker in multi-select box -- //
        
        
        
        $scope.overrideDoorknockerAddresses = function ()
        {
            // Start : get selected doorknockers -- PRIYA BHOJANI -- //
            $scope.selected_doorknockers = [];

            angular.forEach($scope.doorknocker_list, function(doorknocker)
            {
                if (doorknocker.selected) 
                {
                    $scope.selected_doorknockers.push(doorknocker.user_id);
                }
            });
            // End : get selected doorknockers -- PRIYA BHOJANI -- //
           
            if(!$scope.selected_file_name)
            {
                // throw error if no file is selected to override
                $scope.show_error_message = true;
                $scope.show_success_message = false;                
                $scope.error_message_text = "Select address file name to override";
            }            
            else if($scope.selected_doorknockers.length === 0)
            {
                // throw error if no doorknockers are selected to override
                $scope.show_error_message = true;
                $scope.show_success_message = false;                
                $scope.error_message_text = "Select doorknockers to override";
            }
            else if(window.confirm("Do you really want to override these addresses with selected addresses ?"))
            {
                // hide message DIVs
                $scope.show_error_message = false;
                $scope.show_success_message = false;
                
                
                $scope.show_overriding_button = true; // show overriding button
                $scope.show_save_button = false; // hide save button
            
                // Start : override selected file with selected doorknockers -- PRIYA BHOJANI -- //
                $http
                ({
                    method: 'POST',
                    url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
                    data: {"method_name": "overrideDoorknockers","selected_doorknockers" : $scope.selected_doorknockers,"selected_file_name" : $scope.selected_file_name},
                    headers: {'Content-Type': 'application/json'}
                })
                .then(function successCallback(response)
                {
                    $scope.show_error_message = false;
                    $scope.show_success_message = true;
                    
                    setTimeout(function(){ $route.reload(); }, 1500);                    
                },
                function errorCallback(response) 
                {
                    loader.classList.add("hide-loader");
                    if(response.error) { window.location = "#/logout"; };
                });
                // End : override selected file with selected doorknockers -- PRIYA BHOJANI -- //
            }        
        };
        
}]); 