hwecApp.controller('acquisitionDashboardController', ['PROVIDER','$filter', '$scope', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', '$q', function (PROVIDER,$filter, $scope, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL, $q) { 
   
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");

    //full width page 
    $scope.fullWidth = true;

    var thisYear = (new Date()).getFullYear();    

    /* ========================================================== *
     *                get totals since start
     * ========================================================== */
   
    function getPercentage(total, amount) {
        return  ((amount / total) * 100).toFixed(2) + '%';
    };
      
    $http({
        method: 'POST',
       url: 'acquisition/api?token=' + localStorage.getItem('userToken'), 
       data: {
           "method_name" : "totalSinceNumbers" ,
           "start_date": "",
	       "finish_date": ""	
       }
    }).success(function(response) {
        if (response.response_code === FAILURE_CODE) {
            window.location = "#/logout";     
        }
        
        $scope.appointmentsTotal = response.total_bookings;
        $scope.appointment_booked_number = response.total_planned_appointments;
        $scope.appointment_canceled_number = response.total_rejected_appointments;
        $scope.appointment_completed_number = response.total_completed_appointments;
        
        $scope.appointment_booked = response.average_planned_appointments;
        $scope.appointment_canceled = response.average_rejected_appointments;
        $scope.appointment_completed = response.average_completed_appointments;

    }); 


    //get Mondays and Sundays of THIS week
    var today = new Date();
    var dayOfWeekStartingSundayZeroIndexBased = today.getDay(); // 0 : Sunday ,1 : Monday,2,3,4,5,6 : Saturday
    var mondayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+1);
    var sundayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+7);

    //Mondays
    var Mmonth   = mondayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Mday     = mondayOfWeek.getDate();
    var Myear    = mondayOfWeek.getUTCFullYear();

    //Sundays
    var Smonth   = sundayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Sday     = sundayOfWeek.getDate();
    var Syear    = sundayOfWeek.getUTCFullYear();

    //date from current month
    var beginCurentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var endCurentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);


    var dayBM = beginCurentMonth.getDate();
    var monthBM = beginCurentMonth.getMonth() + 1;
    var yearBM = beginCurentMonth.getUTCFullYear();

    var dayEM = endCurentMonth.getDate();
    var monthEM = endCurentMonth.getMonth() + 1;
    var yearEM = endCurentMonth.getUTCFullYear();

    
    Array.prototype.sum = function() {
    return this.reduce(function(a,b){return a+b;});
    };

    //format from long date to YYYY-MM-DD
    function formatDate(day, month, year) {
        day = day < 10 ? "0"+day : day;
        month = month < 10 ? "0"+month : month;
        year = year;
        return newdate = year + "-" + month + "-" + day;
    }


    // get previos weeks from current week
    var fromThisMonday = formatDate(Mday, Mmonth, Myear);
    var fromThisSunday = formatDate(Sday, Smonth, Syear);

    

    /* ========================================================== *
     *             get appointment for this week
     * ========================================================== */
        $scope.request0 = $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getTotalNumberOfAppointmentsByDate",
            "from"        : formatDate(Mday, Mmonth, Myear), //this week Monday
            "to"          : formatDate(Sday, Smonth, Syear) // this week Sunday
        }
    }).success(function(response) {
        
       

    }).error(function(response) {
        
    });

       
    // Start : This week reports ------------------------------------------------------ PRIYA BHOJANI -- //
    $http({
       method: 'POST',
       url: 'acquisition/api?token=' + localStorage.getItem('userToken'),
       data: {
           "method_name"  : "dataForThisWeek"
       }
   }).success(function(response) 
   {
        loadWeeklyPieChartForAppointmentByStatus(response);
        loadWeeklyBarChart(response);
        loadWeeklyPieChartForAppointmentByChannel(response);
        loadYearlyBarChartForAppointmentByChannel();
        
   }).error(function(response) {
       
   });
    // End : This week reports ------------------------------------------------------ PRIYA BHOJANI -- //
    
    // Start : This month reports ------------------------------------------------------ PRIYA BHOJANI -- //
    $http({
       method: 'POST',
       url: 'acquisition/api?token=' + localStorage.getItem('userToken'),
       data: {
           "method_name"  : "dataForThisMonth",
            "from"        : formatDate(dayBM, monthBM, yearBM), // current month start
            "to"          : formatDate(dayEM, monthEM, yearEM) // current month end
       }
    }).success(function(response) 
    {
        loadMonthlyPieChartForAppointmentByStatus(response);
        loadMonthlyPieChartForAppointmentByChannel(response);        
    }).error(function(response) {

    });
    // End : This month reports ------------------------------------------------------ PRIYA BHOJANI -- //


   //since start
   $http({
       method: 'POST',
       url: 'acquisition/api?token=' + localStorage.getItem('userToken'),
       data: {
           "method_name"  : "dataFromBeginning",
            "from"        : "2016-09-01",
            "to"          : formatDate(31, 12, thisYear)
       }
   }).success(function(response) 
   {
       loadPieChartForAppointmentByStatusFromBeginning(response);
       loadPieChartForAppointmentByChannelFromBeginning(response);

   }).error(function(response) {

   });
   


// chart modals
var canvas1 = document.getElementsByClassName('total-appointments-line1');
var ctx1 = document.getElementsByClassName("total-appointments-chart1");
var ctxStatus1 = document.getElementsByClassName("total-appointments-status1");
var ctxSourceWeek1 = document.getElementsByClassName("total-appointments-source1");
 var ctxStatusMonth1 = document.getElementsByClassName("total-appointments-status-month1");
 var ctxStatusSinceStart1 = document.getElementsByClassName("total-appointments-status-since-start1");
 var ctxSourceMonth1 = document.getElementsByClassName("total-appointments-source-month1");
 var ctxSourceSinceStart1 = document.getElementsByClassName("total-appointments-source-since-start1");
 var ctxYear = document.getElementsByClassName("year-status-chart1");


$scope.showModal1 = function() {
    $('#chartModal1').modal('show');
    setTimeout(function() {
        $scope.modal1(canvas1);
    },300) 
};      

$scope.showModal2 = function() {
  $('#chartModal2').modal('show');
  setTimeout(function() {
      $scope.modal2(ctx1);
  },300)
};

$scope.showModal3 = function() {
  $('#chartModal3').modal('show');
  setTimeout(function() {
      $scope.modal3(ctxStatus1);
  },300)
};

$scope.showModal4 = function() {
  $('#chartModal4').modal('show');
  setTimeout(function() {
      $scope.modal4(ctxSourceWeek1);
  },300)
};

$scope.showModal5 = function() {
  $('#chartModal5').modal('show');
  setTimeout(function() {
      $scope.modal5(ctxStatusMonth1);
  },300)
};

$scope.showModal5SinceStart = function() {
  $('#chartModal5SinceStart').modal('show');
  setTimeout(function() {
      $scope.modal5SinceStart(ctxStatusSinceStart1);
  },300)
};

$scope.showModal6 = function() {
  $('#chartModal6').modal('show');
  setTimeout(function() { 
      $scope.modal6(ctxSourceMonth1);
  },300)
};

$scope.showModal6SinceStart = function() {
  $('#chartModal6SinceStart').modal('show');
  setTimeout(function() { 
      $scope.modal6SinceStart(ctxSourceSinceStart1);
  },300)
};

$scope.showModal7 = function() {
  $('#chartModal7').modal('show');
  setTimeout(function() { 
      $scope.modal7(ctxYear);
  },300)
};



// This week reports : Appointments by status

function loadWeeklyPieChartForAppointmentByStatus(response)
{
    $scope.cancelledAppointmentsWeekTotal = response.data.total_rejected_appointments;
    $scope.completedAppointmentsWeekTotal = response.data.total_completed_appointments;
    $scope.plannedAppointmentsWeekTotal   = response.data.total_planned_appointments;
    $scope.weekStatusTotal = response.data.total_bookings;

    var ctxStatus = document.getElementsByClassName("total-appointments-status");
    $scope.modal3 = function(ctxStatus) 
    {
        var channelMondayChart = new Chart(ctxStatus, 
        {
            type: 'doughnut',
            data: {
                labels: ["Completed ", "Cancelled", "Planned"],
                datasets: 
                [{
                    label: 'Satus',
                    data:
                    [
                        $scope.completedAppointmentsWeekTotal,
                        $scope.cancelledAppointmentsWeekTotal,
                        $scope.plannedAppointmentsWeekTotal
                    ],
                    backgroundColor: 
                    [
                        '#60bb46',
                        '#ff0000',
                        '#0083c6' 
                    ] 
                }]
        },
        options: {
            cutoutPercentage: 50,
            title:{
                display:false,
                text:"Monday"
            },
            legend: {
                display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero:true,
                        display: false
                    }
                }]
            }
        }
    });


    }
    $scope.modal3(ctxStatus);    
};


// This week reports : This weeks acquired bookings

function loadWeeklyBarChart(response)
{
    var dataResponse = response.data.bar_chart;
    var dataResponseKeys = Object.keys(dataResponse).map(function(k){return dataResponse[k]});
    var ctx = document.getElementsByClassName("total-appointments-chart");
    
    $scope.modal2 = function(ctx) 
    {
        var myChart = new Chart(ctx, 
        {
            type: 'bar',
            data: 
            {
                labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sunday"],
                datasets: 
                [{
                    label: 'Appointments',
                    data: dataResponseKeys,
                    backgroundColor: 
                    [
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                    ],
                    borderWidth: 1
                }]
            },
            options: 
            {
                legend: 
                {
                    display: false
                },
                title:
                {
                    display:false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: 
                {
                    yAxes: 
                    [{
                        ticks: 
                        {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
    };
    $scope.modal2(ctx);
};
         


// This week reports : Appointment by channel

function loadWeeklyPieChartForAppointmentByChannel(response)
{
    $scope.doorKnockingWeekTotal = response.data.appointments_by_channel['Door Knocking'];
    $scope.inboundCallWeekTotal = response.data.appointments_by_channel['Inbound Call'];
    $scope.employeeWeekTotal = response.data.appointments_by_channel['Employee'];
    $scope.websiteWeekTotal = response.data.appointments_by_channel['Website'];
    $scope.letterWeekTotal = response.data.appointments_by_channel['Letter'];
    $scope.leafletWeekTotal = response.data.appointments_by_channel['Leaflet'];
    $scope.footfallWeekTotal = response.data.appointments_by_channel['Footfall'];
    $scope.outboundCallCouponWeekTotal = response.data.appointments_by_channel['Outbound Call/Coupon'];
    $scope.outboundCallDataWeekTotal = response.data.appointments_by_channel['Outbound Call/Data'];
    
    
    
    var ctxSourceWeek = document.getElementsByClassName("total-appointments-source");
    $scope.modal4 = function(ctxSourceWeek) 
    {
        var sourceWeek = new Chart(ctxSourceWeek, 
        {
            type: 'doughnut',
            data: 
            {
                labels: ["Door Knocking", /*"Employee", "Footfall",*/ "Inbound Call", /*"Leaflet", "Letter",*/ "Outbound Call/Coupon", "Outbound Call/Data", "Website"],
                datasets: 
                [{
                    label: 'Source',
                    data:
                    [
                        $scope.doorKnockingWeekTotal,      
                        // $scope.employeeWeekTotal,          
                        //$scope.footfallWeekTotal,
                        $scope.inboundCallWeekTotal,       
                        // $scope.leafletWeekTotal,           
                        // $scope.letterWeekTotal,            
                        $scope.outboundCallCouponWeekTotal,
                        $scope.outboundCallDataWeekTotal,
                        $scope.websiteWeekTotal
                    ],
                    backgroundColor: 
                    [
                        '#ff0000',
                        // '#0083c6',
                        //  '#00aeef',
                        '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        // 'rgba(259, 80, 30, 1)',
                        'rgba(15, 79, 93, 1)',
                        'rgba(177, 84, 75, 1)',
                        '#60bb46' 
                    ] 
                }]
            },
            options: 
            {
                cutoutPercentage: 50,
                title:
                {
                    display:false,
                    text:"Monday"
                },
                legend: 
                {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: 
                {
                    yAxes: 
                    [{
                        display: true,
                        ticks: 
                        {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
    }
    $scope.modal4(ctxSourceWeek); 
};


function loadYearlyBarChartForAppointmentByChannel()
{
    $http({
         method: 'POST',
        url: 'acquisition/api?token=' + localStorage.getItem('userToken'),
        data: {
           "method_name" : "getAppointmentStatisticsPerStatusGroupedPerMonth"
        }
    }).success(function(response) {
       
       $scope.yearChannels = response.channels;

        var yearChartCanvas = document.getElementsByClassName("year-status-chart");
        
            var yearChartData = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
                label: 'Door Knocking',
                backgroundColor: "#ff0000",
                 data: [
                     $scope.yearChannels[1]['Door Knocking'],
                     $scope.yearChannels[2]['Door Knocking'],
                     $scope.yearChannels[3]['Door Knocking'],
                     $scope.yearChannels[4]['Door Knocking'],
                     $scope.yearChannels[5]['Door Knocking'],
                     $scope.yearChannels[6]['Door Knocking'],
                     $scope.yearChannels[7]['Door Knocking'],
                     $scope.yearChannels[8]['Door Knocking'],
                     $scope.yearChannels[9]['Door Knocking'],
                     $scope.yearChannels[10]['Door Knocking'],
                     $scope.yearChannels[11]['Door Knocking'],
                     $scope.yearChannels[12]['Door Knocking']    
                 ]
            }, { 
                label: 'Footfall',
                backgroundColor: "#00aeef",
                data: [
                     $scope.yearChannels[1]['Footfall'],
                     $scope.yearChannels[2]['Footfall'],
                     $scope.yearChannels[3]['Footfall'],
                     $scope.yearChannels[4]['Footfall'],
                     $scope.yearChannels[5]['Footfall'],
                     $scope.yearChannels[6]['Footfall'],
                     $scope.yearChannels[7]['Footfall'],
                     $scope.yearChannels[8]['Footfall'],
                     $scope.yearChannels[9]['Footfall'],
                     $scope.yearChannels[10]['Footfall'],
                     $scope.yearChannels[11]['Footfall'],
                     $scope.yearChannels[12]['Footfall']
                 ]
            }, {
                label: 'Inbound Call',
                backgroundColor: "#f8991d",
                data: [
                     $scope.yearChannels[1]['Inbound Call'],
                     $scope.yearChannels[2]['Inbound Call'],
                     $scope.yearChannels[3]['Inbound Call'],
                     $scope.yearChannels[4]['Inbound Call'],
                     $scope.yearChannels[5]['Inbound Call'],
                     $scope.yearChannels[6]['Inbound Call'],
                     $scope.yearChannels[7]['Inbound Call'],
                     $scope.yearChannels[8]['Inbound Call'],
                     $scope.yearChannels[9]['Inbound Call'],
                     $scope.yearChannels[10]['Inbound Call'],
                     $scope.yearChannels[11]['Inbound Call'],
                     $scope.yearChannels[12]['Inbound Call']
                 ]
            }, {
                label: 'Outbound Call/Coupon',
                backgroundColor: "#0f4f5d",
                data: [
                     $scope.yearChannels[1]['Outbound Call/Coupon'],
                     $scope.yearChannels[2]['Outbound Call/Coupon'],
                     $scope.yearChannels[3]['Outbound Call/Coupon'],
                     $scope.yearChannels[4]['Outbound Call/Coupon'],
                     $scope.yearChannels[5]['Outbound Call/Coupon'],
                     $scope.yearChannels[6]['Outbound Call/Coupon'],
                     $scope.yearChannels[7]['Outbound Call/Coupon'],
                     $scope.yearChannels[8]['Outbound Call/Coupon'],
                     $scope.yearChannels[9]['Outbound Call/Coupon'],
                     $scope.yearChannels[10]['Outbound Call/Coupon'], 
                     $scope.yearChannels[11]['Outbound Call/Coupon'],
                     $scope.yearChannels[12]['Outbound Call/Coupon']
                 ]
            }, {
                label: 'Outbound Call/Data',
                backgroundColor: "#b1544b",
                data: [
                     $scope.yearChannels[1]['Outbound Call/Data'],
                     $scope.yearChannels[2]['Outbound Call/Data'],
                     $scope.yearChannels[3]['Outbound Call/Data'],
                     $scope.yearChannels[4]['Outbound Call/Data'],
                     $scope.yearChannels[5]['Outbound Call/Data'],
                     $scope.yearChannels[6]['Outbound Call/Data'],
                     $scope.yearChannels[7]['Outbound Call/Data'],
                     $scope.yearChannels[8]['Outbound Call/Data'],
                     $scope.yearChannels[9]['Outbound Call/Data'],
                     $scope.yearChannels[10]['Outbound Call/Data'],
                     $scope.yearChannels[11]['Outbound Call/Data'],
                     $scope.yearChannels[12]['Outbound Call/Data']
                ]
            }, {
                label: 'Website',
                backgroundColor: "#60bb46",
                data: [
                     $scope.yearChannels[1]['Website'],
                     $scope.yearChannels[2]['Website'],
                     $scope.yearChannels[3]['Website'],
                     $scope.yearChannels[4]['Website'],
                     $scope.yearChannels[5]['Website'],
                     $scope.yearChannels[6]['Website'],
                     $scope.yearChannels[7]['Website'],
                     $scope.yearChannels[8]['Website'],
                     $scope.yearChannels[9]['Website'],
                     $scope.yearChannels[10]['Website'],
                     $scope.yearChannels[11]['Website'],
                     $scope.yearChannels[12]['Website']
                ]
            }]
        };
      
            
            $scope.modal7 = function(yearChartCanvas) {
                var yearCtx = new Chart(yearChartCanvas, {
                    type: 'bar',
                    data: yearChartData,
                    options: {
                        title:{
                            display:false
                           // text:"Year Status"
                        },
                         legend: {
                            display: false
                        },
                        tooltips: {
                            mode: 'label'
                        },
                        responsive: true,
                        scales: {
                            xAxes: [{
                                stacked: true,
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        }
                    }
                });
            }
            
    $scope.modal7(yearChartCanvas);
    }).error(function(response) {})
};

function loadMonthlyPieChartForAppointmentByStatus(response)
{
    $scope.cancelledAppointmentsMonthTotal  = response.data.total_rejected_appointments;
    $scope.completedAppointmentsMonthTotal  = response.data.total_completed_appointments;
    $scope.plannedAppointmentsMonthTotal    = response.data.total_planned_appointments;
    $scope.totalsThisMonth = response.data.total_bookings;        

    var ctxStatusMonth = document.getElementsByClassName("total-appointments-status-month");
    $scope.modal5 = function(ctxStatusMonth) 
    {
        var statusMonthChart = new Chart(ctxStatusMonth, 
        {
            type: 'doughnut',
            data: 
            {
                labels: ["Completed ", "Cancelled", "Planned"],
                datasets: 
                [{
                    label: 'Status',
                    data:
                    [
                        $scope.completedAppointmentsMonthTotal,
                        $scope.cancelledAppointmentsMonthTotal,
                        $scope.plannedAppointmentsMonthTotal
                    ],
                    backgroundColor: 
                    [
                        '#60bb46',
                        '#ff0000',
                        '#0083c6' 
                    ] 
                }]
            },
            options: 
            {
                cutoutPercentage: 50,
                title:
                {
                    display:false,
                    text:"Monday"
                },
                legend: 
                {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: 
                {
                    yAxes: 
                    [{
                        display: true,
                        ticks: 
                        {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
    }
    $scope.modal5(ctxStatusMonth); 
};

function loadMonthlyPieChartForAppointmentByChannel(response)
{
    $scope.doorKnockingMonthTotal = response.data.appointments_by_channel['Door Knocking'];
    $scope.inboundCallMonthTotal = response.data.appointments_by_channel['Inbound Call'];
    $scope.outboundCallCouponMonthTotal = response.data.appointments_by_channel['Outbound Call/Coupon'];
    $scope.outboundCallDataMonthTotal = response.data.appointments_by_channel['Outbound Call/Data'];
    $scope.websiteMonthTotal = response.data.appointments_by_channel['Website'];
        
    var ctxSourceMonth = document.getElementsByClassName("total-appointments-source-month");
    $scope.modal6 = function(ctxSourceMonth) 
    {
        var sourceMonth = new Chart(ctxSourceMonth, 
        {
            type: 'doughnut',
            data: 
            {
                labels: ["Door Knocking", /*"Employee", "Footfall",*/ "Inbound Call", /*"Leaflet", "Letter", */"Outbound Call/Coupon", "Outbound Call/Data" ,"Website"],
                datasets: 
                [{
                    label: 'Source',
                    data:
                    [
                        $scope.doorKnockingMonthTotal,      
                        // $scope.employeeMonthTotal,          
                      //  $scope.footfallMonthTotal,          
                        $scope.inboundCallMonthTotal,       
                        // $scope.leafletMonthTotal,           
                        // $scope.letterMonthTotal,            
                        $scope.outboundCallCouponMonthTotal,
                        $scope.outboundCallDataMonthTotal,
                         $scope.websiteMonthTotal          
                    ],
                    backgroundColor: 
                    [
                        '#ff0000',
                        // '#0083c6',
                         //   '#00aeef',
                        '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        // 'rgba(259, 80, 30, 1)',
                        'rgba(15, 79, 93, 1)',
                        'rgba(177, 84, 75, 1)',
                         '#60bb46' 
                    ] 
                }]
            },
            options: 
            {
                cutoutPercentage: 50,
                title:
                {
                    display:false,
                    text:"Monday"
                },
                legend: 
                {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: 
                {
                    yAxes: 
                    [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
        
    }
    $scope.modal6(ctxSourceMonth);
};


function loadPieChartForAppointmentByStatusFromBeginning(response)
{       
    var ctxStatusSinceStart = document.getElementsByClassName("total-appointments-status-since-start");
    $scope.modal5SinceStart = function(ctxStatusSinceStart) {

    var statusSinceStartChart = new Chart(ctxStatusSinceStart, 
    {
        type: 'doughnut',
        data: 
        {
            labels: ["Completed ", "Cancelled", "Planned"],
            datasets: 
            [{
                label: 'Status',
                data:
                [
                    $scope.appointment_completed_number,
                    $scope.appointment_canceled_number,
                    $scope.appointment_booked_number
                ],
                backgroundColor: 
                [
                    '#60bb46',
                    '#ff0000',
                    '#0083c6' 
                ] 
            }]
        },
        options: 
        {
            cutoutPercentage: 50,
            title:
            {
                display:false,
                text:"Monday"
            },
            legend: 
            {
                display: false
            },
                responsive: true,
                maintainAspectRatio: false,
                scales: 
                {
                    yAxes: 
                    [{
                        display: true,
                        ticks: 
                        {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
    }
    $scope.modal5SinceStart(ctxStatusSinceStart); 
};


function loadPieChartForAppointmentByChannelFromBeginning(response)
{
    $scope.doorKnockingSinceStartTotal = response.data.appointments_by_channel['Door Knocking'];
    $scope.inboundCallSinceStartTotal = response.data.appointments_by_channel['Inbound Call'];
    $scope.employeeMonthTotal = response.data.appointments_by_channel['Employee'];
    $scope.websiteSinceStartTotal = response.data.appointments_by_channel['Website'];
    $scope.letterSinceStartTotal = response.data.appointments_by_channel['Letter'];
    $scope.leafletWeekTotal = response.data.appointments_by_channel['Leaflet'];
    $scope.footfallSinceStartTotal = response.data.appointments_by_channel['Footfall'];
    $scope.outboundCallCouponSinceStartTotal = response.data.appointments_by_channel['Outbound Call/Coupon'];
    $scope.outboundCallDataSinceStartTotal = response.data.appointments_by_channel['Outbound Call/Data'];
    
    var ctxSourceSinceStart = document.getElementsByClassName("total-appointments-source-since-start");
    $scope.modal6SinceStart = function(ctxSourceSinceStart) 
    {

        var sourceSinceStart = new Chart(ctxSourceSinceStart, 
        {
            type: 'doughnut',
            data: 
            {
                labels: ["Door Knocking", /*"Employee", "Footfall",*/ "Inbound Call", /*"Leaflet",*/ "Letter", "Outbound Call/Coupon", "Outbound Call/Data", "Website"],
                datasets: 
                [{
                    label: 'Source',
                    data:
                    [
                        $scope.doorKnockingSinceStartTotal,      
                        // $scope.employeeMonthTotal,          
                        // $scope.footfallSinceStartTotal,          
                        $scope.inboundCallSinceStartTotal,       
                        // $scope.leafletMonthTotal,           
                        $scope.letterSinceStartTotal,            
                        $scope.outboundCallCouponSinceStartTotal,
                        $scope.outboundCallDataSinceStartTotal,
                        $scope.websiteSinceStartTotal
                    ],
                    backgroundColor: 
                    [
                        '#ff0000',
                        // '#0083c6',
                        //'#00aeef',
                        '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        'rgba(259, 80, 30, 1)',
                        'rgba(15, 79, 93, 1)',
                        'rgba(177, 84, 75, 1)',
                        '#60bb46' 
                    ] 
                }]
            },
            options: 
            {
                cutoutPercentage: 50,
                title:
                {
                    display:false,
                    text:"Monday"
                },
                legend: 
                {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: 
                {
                    yAxes: 
                    [{
                        display: true,
                        ticks: 
                        {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
        
    }
    $scope.modal6SinceStart(ctxSourceSinceStart);
};

}]);    
        