hwecApp.controller('pendingReminderController', ['$http','$scope', '$location', 'FAILURE_CODE','PRODUCT_PICTURE_PATH', function($http, $scope, $location, FAILURE_CODE,PRODUCT_PICTURE_PATH) 
{
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    var itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);
     
    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
    var year = currentDate.getFullYear();
    
    getPendingReminders(year + '-' + month + '-' + day);
    getAppointmentDetailsToChangeReminderStatus();
     
    angular.element('#updating_button').hide();
    
     
    
    function getPendingReminders(date_to_search)
    { 
        console.log(date_to_search);
        $http
        ({
            method: 'POST',
            url: '/remindercall/api?token=' + localStorage.getItem('userToken'),
            data: {"date_filter": date_to_search, "method_name": "pendingReminders"},
            headers: {'Content-Type': 'application/json'}
        }).success(function (response) 
        {
            $scope.appointments = response['data'];
        }).error(function (data) 
        {
            if (data.error) {
                window.location = "#/logout"
            };
        });
    };
    
    $scope.getPendingRemindersBydate = function ()
    {
        console.log(angular.element('#date_to_search').val());
        getPendingReminders(angular.element('#date_to_search').val());
    }; 
     
    function getAppointmentDetailsToChangeReminderStatus()
    {
        $scope.backLink = '#/reminders/pending-reminders';
        
        $http({
            method: 'POST',
            url: '/appointments/api?token=' + localStorage.getItem('userToken'),
            data: {
                "method_name" : "viewData",
                "appointment_id" : itemId
                }
        }).success(function(response) {
            if(response.response_code == FAILURE_CODE ) {window.location = "#/logout"};
            $scope.items = response['data'];            
        }).error(function(response) {
            if(response.error) { window.location.href = "#/logout" }
        });
    };
    
    $scope.changeAppointmentReminderStatus = function (appointment_id)
    {
        angular.element('#update_button').hide();
        angular.element('#updating_button').show();
        if($scope.reminder_call_status === undefined)
        {
            $scope.status_class = 'invalid';
            angular.element('#updating_button').hide();
            angular.element('#update_button').show();            
        }
        else
        {
            $scope.status_class = '';
        }        
       
        
        if($scope.reminder_call_status !== undefined)
        {
            if($scope.reminder_call_comments === undefined)
            {
                $scope.reminder_call_comments = '--';
            }
            $http({
                method: 'POST',
                url: '/appointments/api?token=' + localStorage.getItem('userToken'),
                data: {
                    "method_name" : "changeReminderStatus",
                    "appointment_id" : appointment_id,
                    "reminder_call_comments": $scope.reminder_call_comments,
                    "reminder_call_status": $scope.reminder_call_status
                    }
            }).success(function(response) {
                if(response.response_code == FAILURE_CODE ) {window.location = "#/logout"};
                angular.element('#update_button').hide();
                angular.element('#updating_button').hide();
                window.location.href = "#/reminders/pending-reminders";
            }).error(function(response) {
                if(response.error) { window.location.href = "#/logout" }
            });
        }
        console.log($scope.reminder_call_comments);
    };
}]);