hwecApp.controller('addDoorknockerWorkAreaController', ['PROVIDER','$filter', '$scope', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', '$q', function (PROVIDER,$filter, $scope, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL, $q) { 
   
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    $scope.show_add_more_postcodes_button = true;
    var postcode_row_counter = 0;    
    $scope.doorknocker_postcodes = [];
    var doorknocker_postcodes = [];
    
    $scope.success_message_div = false;
    $scope.error_message_div = false;
    
    $scope.save_doorknocker_work_area_button = true;
    $scope.saving_doorknocker_work_area_button = false;
    
    $scope.pageTitle = "Add doorknocker work area";
    $scope.backLink = "#/doorknocker/doorknocker_work_area"
    
    // Start : pre-load all door_knocker in select box -- //
    
    $http({
        method: 'POST',
        url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
        data: {"method_name": "listData"},
        headers: {'Content-Type': 'application/json'}
    }).then(function successCallback(response)
    {
        loader.classList.add("hide-loader");
        $scope.doorknocker_list = response['data'];                        
        $scope.doorknocker_list = $scope.doorknocker_list['data'];
    }, function errorCallback(response) {
         window.location.href = "#/logout";
    });
    
    // End : pre-load all door_knocker in select box -- //
    
    // load one doorknocker_work_area_post_code row by default
    addDoorknockerPostCodeRow();
    
    // load doorknocker_work_area_post_code on button click 
    $scope.addDoorknockerPostCodeRow = function ()
    {
        addDoorknockerPostCodeRow();
    };
    
    
    // Start : add doorknocker_work_area_post_code row function -- PRIYA BHOJANI -- //
    function addDoorknockerPostCodeRow()
    {
        if(postcode_row_counter <= 5)
        {
            // Start : add postcode box for selected installer -- PRIYA BHOJANI -- //
            
            postcode_row_counter = postcode_row_counter + 1;
            $scope.doorknocker_postcodes.push({'postcode_row_counter': postcode_row_counter});
            doorknocker_postcodes.push({'postcode_row_counter': postcode_row_counter});
            
            // End : add postcode box for selected installer -- PRIYA BHOJANI -- //            
        }
        
        if(postcode_row_counter === 5)
        {
            $scope.show_add_more_postcodes_button = false;
        }
    }
    // End : add doorknocker_work_area_post_code row function -- PRIYA BHOJANI -- //
        
    
    // Start : save doorknocker work area function -- PRIYA BHOJANI -- //
    
    $scope.saveDoorknockerWorkArea = function ()
    {        
        var is_form_valid = 'yes';
        
        if(!$scope.selected_doorknocker)
        {
            angular.element('#doorknocker_list_select_box').css('border','1px red solid');            
            is_form_valid = 'no';
        }
        else
        {
            angular.element('#doorknocker_list_select_box').css('border','');            
        }
        
        var request_array = [];
        for(var i = 1;i <= postcode_row_counter; i++)
        {
            if($.trim(angular.element('#doorknocker_'+ i +'_postcode').val()).length === 0)
            {
                angular.element('#doorknocker_'+ i +'_postcode').css('border','1px red solid');
                is_form_valid = 'no';
                break;
            }
            else
            {
                angular.element('#doorknocker_'+ i +'_postcode').css('border','');
                request_array.push({
                                        'doorknocker_id': parseInt($scope.selected_doorknocker), 
                                        'doorknocker_work_area_postcode': $.trim(angular.element('#doorknocker_'+ i +'_postcode').val()).replace(" ",""), 
                                        'user_id' : parseInt(localStorage.getItem('user_id'))
                                    });
            }
        }
        
        $scope.request_array = {
                "method_name": "addDoorknockerWorkArea",
                "work_area": request_array
            };
             
        
        
        if(is_form_valid === 'yes')
        {
            $scope.save_doorknocker_work_area_button = false;
            $scope.saving_doorknocker_work_area_button = true;

            // server request
            $http({
                method: 'POST',
                url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
                data: $scope.request_array,
                headers: {'Content-Type': undefined}
            }).success(function (response)
            {
                $scope.success_message_div = true;
                $scope.error_message_div = false;

                window.location.href = "#/doorknocker/doorknocker_work_area";
            }).error(function (response) 
            {
                $scope.success_message_div = false;
                $scope.error_message_div = true;            

                $scope.save_doorknocker_work_area_button = true;
                $scope.saving_doorknocker_work_area_button = false;
            });
        }
    };
    
    // End : save doorknocker work area function -- PRIYA BHOJANI -- //       
}]);    
        