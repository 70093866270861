hwecApp.controller('loginController',['$scope', '$http', '$location','AUTHENTICATION_FAILURE_CODE', function($scope, $http, $location,AUTHENTICATION_FAILURE_CODE) {
	
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    $scope.credential = {};
    $scope.show_error_message = false;
    $scope.login_error_message = 'Invalid username OR password';
    
    $scope.show_login_button = true;
    $scope.show_processing_button = false;
    
    $scope.curentUrl = $location.path().replace('/',"").split('/')[0];    

    if(localStorage.getItem('isLoged')) { 
        var provider_id = localStorage.getItem('provider_id');
        if(provider_id=='1' || provider_id=='2') {
            window.location = "#/appointments";
        } else {
            window.location = "#/freepack-report";
        }        
        

    } 
 
	$scope.logIn = function()   
    {
        $scope.show_login_button = false;
        $scope.show_processing_button = true;
        
		$scope.credential = {     
			email: $scope.credential.username,
			password: $scope.credential.password
		}  
		var credentialData = JSON.stringify($scope.credential);
		$http({
			method: 'POST',  
		    url: '/api/authenticate',
		  	data: credentialData,
			headers: {'Content-Type': 'application/json'}
		}).success(function(data) 
        {
            if(data.response_code === AUTHENTICATION_FAILURE_CODE)
            {
                $scope.show_login_button = true;
                $scope.show_processing_button = false;
                $scope.show_error_message = true;                
            }
            else
            {
                $scope.show_error_message = false;
                localStorage.setItem('userToken', data.token);
                $http({ 
                    method: 'GET',
                    url: '/api/getAuthenticatedUser?token=' + localStorage.getItem('userToken'),
                    headers: {'Content-Type': 'application/json'}
                }).success(function(data) {
                    localStorage.setItem('user_id', data.user.id);
                    localStorage.setItem('isLoged', true);
                    localStorage.setItem('provider_id', data.user.provider_id);
                    //alert(data.user.provider_id);   
                    if(data.user.provider_id=='1' || data.user.provider_id=='2' || data.user.provider_id=='3') {
                        window.location.href = "/dashboard"; 
                    } else {
                        window.location.href = "#/freepack-report";
                    }
                });
            }
		}).error(function(data) 
        {
			console.log(data);
		});
	};

}]);