hwecApp.controller('installerApplicationQuestionsController', ['$APPOINTMENT_TIME_DETAIL','$window','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', function ($APPOINTMENT_TIME_DETAIL,$window,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH)
{

    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    $scope.pageTitle = "Modify appointment products";
    $scope.backLink = '#/appointments';
         
    $scope.show_assign_product_button = true;
    $scope.show_assigning_product_button = false;
   
    
    // Start : hwec question list -- PRIYA BHOJANI -- //
      
    $http
    ({
            method: 'POST',
            url: 'products/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "getInstallerQuestionsAndAnswers"},
            headers: {'Content-Type': 'application/json'}
    }) 
    .success(function (data) 
    {
        if (data.response_code === SUCCESS_CODE) 
        {
            $scope.installer_questions = data.data;            
        }
        else if (data.response_code === FAILURE_CODE) 
        {
            //window.location = "#/logout";
        }
    })
    .error(function (data) 
    {
        if (data.error) 
        {
            //window.location = "#/logout";
        }
    });
    
    
    // End : hwec question list -- PRIYA BHOJANI -- //
}]);

