hwecApp.controller('installerActivityController', ['$window','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', function ($window,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH) {

    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    $scope.getRealTimeSlot = ['08:00:00', '09:00:00' ,'10:00:00','11:00:00','11:30:00', '12:00:00', '13:00:00', '14:00:00','14:30:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00']; 
    
    $http({
        method: 'POST',
        url: '/installers/api?token=' + localStorage.getItem('userToken'),
        data: {"method_name": "listData"},
        headers: {'Content-Type': 'application/json'}
    }).then(function successCallback(response)
    {
        $scope.installer_list = response['data'];
        console.log($scope.installer_list);

    }, function errorCallback(response) {
        window.location.href = "#/logout";
    });


    //newPageNumber = 1; // initialize page no to 1
    $scope.total_count = 0;
    $scope.itemsPerPage = 20; //this could be a dynamic value from a drop down
    $scope.fecha1 = $scope.selected_date1;
    $scope.fecha2 = $scope.selected_date2;
    $scope.user_id = $scope.user_id;



    $scope.paginar= function(newPageNumber){

        $scope.installerActivity = {
            "method_name": "getInstallerActivity",
            "user_id":$scope.user_id,
            "date_started":$scope.fecha1,
            "date_end":$scope.fecha2,
            "records_per_page":20,
            "page_number": newPageNumber
        }
        $scope.installerActivity = JSON.stringify($scope.installerActivity);

        $http({
            method: 'POST',
            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
            data: $scope.installerActivity,
            headers: {'Content-Type': 'application/json'}
        }).success(function(response) {
            console.log(response.data);
            $scope.total_count  = response.total_appointments;
            $scope.appo  = response.data;
            $scope.selected_date1 = null;
            $scope.selected_date2 = null;
        }).error(function(response) {
            if(response.error) { window.location.href = "#/logout" };
        })

    }



    $scope.mostrar = function(newPageNumber) {

        //alert(newPageNumber);




        if($scope.selected_date1 != null && $scope.selected_date2 != null && $scope.user_id != null){




            $scope.fecha1 = $scope.selected_date1;
            $scope.fecha2 = $scope.selected_date2;

            $scope.installerActivity = {
                "method_name": "getInstallerActivity",
                "user_id":$scope.user_id,
                "date_started":$scope.selected_date1,
                "date_end":$scope.selected_date2,
                "records_per_page":20,
                "page_number": newPageNumber
            }

            $scope.installerActivity = JSON.stringify($scope.installerActivity);

            console.log($scope.installerActivity);

            $http({
                method: 'POST',
                url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                data: $scope.installerActivity,
                headers: {'Content-Type': 'application/json'}
            }).success(function(response) {
                console.log(response.data);
                $scope.total_count  = response.total_appointments;
                $scope.appo  = response.data;
                $scope.selected_date1 = null;
                $scope.selected_date2 = null;
            }).error(function(response) {
                if(response.error) { window.location.href = "#/logout" };
            })
        }


    }





   




}]);