/**
 * Created by oem on 06/12/16.
 */
hwecApp.controller('singleInstallerCalendarController', ['$window','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', function ($window,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH) {


    $http({
        method: 'POST',
        url: '/installers/api?token=' + localStorage.getItem('userToken'),
        data: {"method_name": "listData"},
        headers: {'Content-Type': 'application/json'}
    }).then(function successCallback(response)
    {
        $scope.installer_list = response['data'];
        console.log($scope.installer_list);

    }, function errorCallback(response) {
        window.location.href = "#/logout";
    });



    var loader = document.getElementById("loading-content");
    //loader.classList.remove("hide-loader");

    $scope.paginatePage = true;
    $scope.hideNewBtn = false;
    $scope.manage_installer_schedule_button = true;
    $scope.curentUrl = $location.path().replace('/', "");
    $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);
    $scope.show_delete_button = false;
    $scope.pageTitle = $scope.curentUrl.slice(0, -1) + " list";
    $scope.time_slot_array_to_remove = [];
    $scope.pageTitle = "Installer schedule";
    $scope.backLink = '#/';

    $scope.check_show = false;

    $scope.mostrar = function(){
        $scope.check_show = true;
        if($scope.user_id == null){
            $scope.data = {
                "method_name": "getSingleInstallerAvailability"
            };
        }else {
            $scope.data = {
                "method_name": "getSingleInstallerAvailability",
                "user_id":$scope.user_id
            };
        }

        //display calendar when data is ready
        $scope.events = [];
        $scope.readyToLoad = false;
        $http({
            method: 'POST',
            url: '/installers/api?token=' + localStorage.getItem('userToken'),
            data:$scope.data,
            headers: {'Content-Type': 'application/json'}
        }).success(function(data, status, headers, config) {
            loader.classList.add("hide-loader");
            //if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            angular.forEach(data["data"],function(value, index) {
                //object to be displayed in the calendar
                $scope.events.push({
                    id: value['installer_id'],
                    title: value['installer_id'] + " : " + value['available_time_slot_id'] + value['installer_name'] + " : " + value['work_area_postcode'],
                    start: value['available_date'] + $TIME_SLOT[value['available_time_slot_id']],
                    appointment_date: value['available_date'],
                    time_slot_id: value['available_time_slot_id'] ,
                    allDay: false,
                    stick: true,
                    installer_availability_id:value['installer_availability_id']
                });


            }); //end foreach


            $scope.slots_available  = $scope.events.length;



            /* event source that calls a function on every view switch */
            $scope.eventsF = function (start, end, timezone, callback) {
                var s = new Date(start).getTime() / 1000;
                var e = new Date(end).getTime() / 1000;
                var m = new Date(start).getMonth();
                var events = [{title: 'Feed Me ' + m,start: s + (50000),end: s + (100000),allDay: false, className: ['customFeed']}];
                callback(events);
            };

            /* alert on Drop */
            $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
                $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
            };
            /* alert on Resize */
            $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
                $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
            };
            /* add and removes an event source of choice */
            $scope.addRemoveEventSource = function(sources,source) {
                var canAdd = 0;
                angular.forEach(sources,function(value, key){
                    if(sources[key] === source){
                        sources.splice(key,1);
                        canAdd = 1;
                    }
                });
                if(canAdd === 0){
                    sources.push(source);
                }
            };
            /* add custom event*/
            $scope.addEvent = function() {
                $scope.events.push({
                    title: 'Open Sesame',
                    start: new Date(y, m, 28),
                    end: new Date(y, m, 29),
                    className: ['openSesame']
                });
            };
            /* remove event */
            $scope.remove = function(index) {
                $scope.events.splice(index,1);
            };
            /* Change View */
            $scope.changeView = function(view,calendar) {
                uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
            };
            /* Change View */
            $scope.renderCalender = function(calendar) {
                if(uiCalendarConfig.calendars[calendar]){
                    uiCalendarConfig.calendars[calendar].fullCalendar('render');
                }
            };

            $scope.alertOnEventClick = function(date, jsEvent, view)
            {
                if(angular.element(this).hasClass("priya"))
                {
                    angular.element(this).css('background', '#5cb85c');
                    angular.element(this).removeClass("priya");

                    var index = $scope.time_slot_array_to_remove.indexOf(date['installer_availability_id']);
                    $scope.time_slot_array_to_remove.splice(index, 1);

                    if($window.document.getElementsByClassName('priya').length === 0)
                    {
                        $scope.show_delete_button = false;
                    }
                }
                else
                {
                    $scope.time_slot_array_to_remove.push(date['installer_availability_id']);

                    //angular.element('.fc-day-grid-event').css('background', '#5cb85c');
                    angular.element(this).css('background', 'red');
                    angular.element(this).addClass("priya");

                    $scope.show_delete_button = true;
                }
            };
            // config calendar
            $scope.uiConfig = {
                calendar:{
                    timeFormat:'HH:mm',
                    height: 600,
                    firstDay:1,
                    editable: false,
                    header:{
                        left: 'month basicWeek basicDay',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    eventClick: $scope.alertOnEventClick,
                    eventDrop: $scope.alertOnDrop,
                    eventResize: $scope.alertOnResize,
                    eventRender: $scope.eventRender
                }
            };

            $scope.eventSources = [$scope.events, $scope.eventsF];
            //data is ready. Displaying calendar in view

            $scope.readyToLoad = true;


            $scope.readyToLoad = true;

        }).error(function(data, status, headers, config) {
            loader.classList.remove("hide-loader");
            if(data.error) { window.location.href = "#/logout" }
        });
    }

    if($scope.user_id == null){
        $scope.data = {
            "method_name": "getSingleInstallerAvailability"
        };
    }else {
        $scope.data = {
            "method_name": "getSingleInstallerAvailability",
            "user_id":$scope.user_id
        };
    }

    //display calendar when data is ready
    $scope.events = [];
    $scope.readyToLoad = false;
    $http({
        method: 'POST',
        url: '/installers/api?token=' + localStorage.getItem('userToken'),
        data:$scope.data,
        headers: {'Content-Type': 'application/json'}
    }).success(function(data, status, headers, config) {
        loader.classList.add("hide-loader");
        //if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
        angular.forEach(data["data"],function(value, index) {
            //object to be displayed in the calendar
            $scope.events.push({
                id: value['installer_id'],
                title: value['installer_id'] + " : " + value['available_time_slot_id'] + value['installer_name'] + " : " + value['work_area_postcode'],
                start: value['available_date'] + $TIME_SLOT[value['available_time_slot_id']],
                appointment_date: value['available_date'],
                time_slot_id: value['available_time_slot_id'] ,
                allDay: false,
                stick: true,
                installer_availability_id:value['installer_availability_id']
            });
        }); //end foreach


        console.log($scope.events);



        /* event source that calls a function on every view switch */
        $scope.eventsF = function (start, end, timezone, callback) {
            var s = new Date(start).getTime() / 1000;
            var e = new Date(end).getTime() / 1000;
            var m = new Date(start).getMonth();
            var events = [{title: 'Feed Me ' + m,start: s + (50000),end: s + (100000),allDay: false, className: ['customFeed']}];
            callback(events);
        };

        /* alert on Drop */
        $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
            $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
        };
        /* alert on Resize */
        $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
            $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
        };
        /* add and removes an event source of choice */
        $scope.addRemoveEventSource = function(sources,source) {
            var canAdd = 0;
            angular.forEach(sources,function(value, key){
                if(sources[key] === source){
                    sources.splice(key,1);
                    canAdd = 1;
                }
            });
            if(canAdd === 0){
                sources.push(source);
            }
        };
        /* add custom event*/
        $scope.addEvent = function() {
            $scope.events.push({
                title: 'Open Sesame',
                start: new Date(y, m, 28),
                end: new Date(y, m, 29),
                className: ['openSesame']
            });
        };
        /* remove event */
        $scope.remove = function(index) {
            $scope.events.splice(index,1);
        };
        /* Change View */
        $scope.changeView = function(view,calendar) {
            uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
        };
        /* Change View */
        $scope.renderCalender = function(calendar) {
            if(uiCalendarConfig.calendars[calendar]){
                uiCalendarConfig.calendars[calendar].fullCalendar('render');
            }
        };

        $scope.alertOnEventClick = function(date, jsEvent, view)
        {
            if(angular.element(this).hasClass("priya"))
            {
                angular.element(this).css('background', '#5cb85c');
                angular.element(this).removeClass("priya");

                var index = $scope.time_slot_array_to_remove.indexOf(date['installer_availability_id']);
                $scope.time_slot_array_to_remove.splice(index, 1);

                if($window.document.getElementsByClassName('priya').length === 0)
                {
                    $scope.show_delete_button = false;
                }
            }
            else
            {
                $scope.time_slot_array_to_remove.push(date['installer_availability_id']);

                //angular.element('.fc-day-grid-event').css('background', '#5cb85c');
                angular.element(this).css('background', 'red');
                angular.element(this).addClass("priya");

                $scope.show_delete_button = true;
            }
        };

        // config calendar
        $scope.uiConfig = {
            calendar:{
                timeFormat:'HH:mm',
                height: 600,
                firstDay:1,
                editable: false,
                header:{
                    left: 'month basicWeek basicDay',
                    center: 'title',
                    right: 'today prev,next'
                },
                eventClick: $scope.alertOnEventClick,
                eventDrop: $scope.alertOnDrop,
                eventResize: $scope.alertOnResize,
                eventRender: $scope.eventRender
            }
        };

        $scope.eventSources = [$scope.events, $scope.eventsF];
        //data is ready. Displaying calendar in view
        $scope.readyToLoad = true;


        $scope.readyToLoad = true;

    }).error(function(data, status, headers, config) {
        loader.classList.remove("hide-loader");
        if(data.error) { window.location.href = "#/logout" }
    });


    $scope.removeInstallerAvailabilityTimeSlot = function ()
    {
        var confirm = $window.confirm("Do you really want to delete selected time-slot(s) ? ");

        if(confirm)
        {
            $http
            ({
                method: 'POST',
                url: 'installers/api?token=' + localStorage.getItem('userToken'),
                data:
                {
                    "method_name": "deleteInstallerAvailabilityTimeSlot",
                    "time_slot_array_to_remove": $scope.time_slot_array_to_remove
                }
            })
                .success(function (data)
                {
                    $window.location.reload();
                })
                .error(function (data) {
                    if (data.error) {
                        window.location = "#/logout";
                    }
                });
        }
    };
}]);