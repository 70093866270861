hwecApp.controller('editController', ['$filter','$scope', '$http','$records_per_page','SUCCESS_CODE','FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT','AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory','PRODUCT_PICTURE_PATH', function($filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular,$timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH){

    $scope.product_picture_path = '/' + PRODUCT_PICTURE_PATH;     
    $scope.time_slots                   = $TIME_SLOT;
    $scope.updated_installer_id         = null;
    $scope.appointment_date            = null;
    $scope.time_slot_id     = null;
    $scope.updated_data = [];

    $scope.dataToShow = null;
    $scope.curentUrl = $location.path().replace('/',"").split('/')[0];
    $scope.backLink = "#/" + $scope.curentUrl;
    $scope.pageTitle = $scope.curentUrl.slice(0, -1) + " edit";

    $scope.previous_time_slot_id        = null;
    $scope.previous_appointment_date    = null;


    var itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);
    $scope.itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);
    $scope.mostrar_link = $scope.curentUrl + "/" + $scope.itemId;

    if($scope.pageTitle == "categorie edit") {$scope.pageTitle = "category edit"};


    //get addresses in edit appointments



    //get adress based on postcode
    $scope.getAdressList = function() {
        $http({
            method: 'POST',
            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
            data: {
                method_name: "getAddressDetailsByPostCode",
                post_code: $scope.postcode
            }
        }).success(function(data) {
            if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            if(data.response_code == SUCCESS_CODE) {
                $scope.lists = data['data'];
                $scope.fullAddresses = AppointmentFactory.listOfFullAddresses($scope.lists);
                $scope.focus = true;
                $scope.postcodeAdressNot = false;
            }else if(data.response_code == NOT_FOUND_CODE) {
                $scope.postcodeAdressNot = true;
            }else if(data.response_code == FAILURE_CODE) {
                window.location.href = "#/logout"
            }

        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        });
    };


    //autocomplete adress from select
    $scope.addAdress = function(addressId) {
        var foundObject = ($filter('filter')($scope.lists,{address_id:addressId}))[0];
        $scope.address_1 								= foundObject.address_1;
        $scope.address_2 								= foundObject.address_2;
        $scope.address_3 								= foundObject.address_3;
        $scope.address_4 								= foundObject.address_4;
        $scope.address_5 								= foundObject.address_5;
        $scope.postcode 								= foundObject.postcode;
        $scope.property_number 					        = foundObject.property_number;
        $scope.address_id 								= foundObject.address_id;
        $scope.customer_name_client 			        = foundObject.customer_name_client;
        $scope.customer_reference_client                = foundObject.customer_reference_client;
        $scope.customer_id								= foundObject.customer_id;
        $scope.focus = false;
    };
 
    // Start : calendar -- PRIYA BHOJANI -- //
    //display calendar when data is ready
        $scope.events = [];
        $scope.readyToLoad = false;
        $http({
            method: 'POST',
            url: '/installers/api?token=' + localStorage.getItem('userToken'),
            data:{"method_name":"getInstallerAvailability"},
            headers: {'Content-Type': 'application/json'}
        }).success(function(data, status, headers, config) {
            
            //if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            angular.forEach(data["data"],function(value, index) {
                //object to be displayed in the calendar
                $scope.events.push({
                    id: value['installer_id'],
                    title: value['installer_availability_id']  + " : " + value['installer_name'] + " : " + value['work_area_postcode'],
                    start: value['available_date'] + $TIME_SLOT[value['available_time_slot_id']],
                    appointment_date: value['available_date'],
                    time_slot_id: value['available_time_slot_id'],
                    installer_availability_id:value['installer_availability_id'],
                    allDay: false,
                    stick: true
                });

            }); //end foreach

            /* event source that calls a function on every view switch */
            $scope.eventsF = function (start, end, timezone, callback) {
                var s = new Date(start).getTime() / 1000;
                var e = new Date(end).getTime() / 1000;
                var m = new Date(start).getMonth();
                var events = [{title: 'Feed Me ' + m,start: s + (50000),end: s + (100000),allDay: false, className: ['customFeed']}];
                callback(events);
            };

            /* alert on Drop */
            $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
                $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
            };
            /* alert on Resize */
            $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
                $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
            };
            /* add and removes an event source of choice */
            $scope.addRemoveEventSource = function(sources,source) {
                var canAdd = 0;
                angular.forEach(sources,function(value, key){
                    if(sources[key] === source){
                        sources.splice(key,1);
                        canAdd = 1;
                    }
                });
                if(canAdd === 0){
                    sources.push(source);
                }
            };
            /* add custom event*/
            $scope.addEvent = function() {
                $scope.events.push({
                    title: 'Open Sesame',
                    start: new Date(y, m, 28),
                    end: new Date(y, m, 29),
                    className: ['openSesame']
                });
            };

            /* remove event */
            $scope.remove = function(index) {
                $scope.events.splice(index,1);
            };
            /* Change View */
            $scope.changeView = function(view,calendar) {
                uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
            };
            /* Change View */
            $scope.renderCalender = function(calendar) {
                if(uiCalendarConfig.calendars[calendar]){
                    uiCalendarConfig.calendars[calendar].fullCalendar('render');
                }
            };

            // config calendar
            $scope.uiConfig = {
                calendar:{
                    timeFormat:'HH:mm',
                    height: 600,
                    editable: false,
                    firstDay:1,
                    header:{
                        left: 'month basicWeek basicDay',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    eventClick:$scope.alertOnEventClick,
                    eventDrop: $scope.alertOnDrop,
                    eventResize:$scope.alertOnResize,
                    eventRender:$scope.eventRender
                }
            };   


            $scope.eventSources = [$scope.events, $scope.eventsF];
            //data is ready. Displaying calendar in view
            $scope.readyToLoad = true;
             setTimeout(function() {
                        $('.fc-day-grid-event .fc-time:contains("08:00")').parents(".fc-day-grid-event").css("background", "#68d068");
                        $('.fc-day-grid-event .fc-time:contains("09:00")').parents(".fc-day-grid-event").css("background", "#5cb85c");
                        $('.fc-day-grid-event .fc-time:contains("10:00")').parents(".fc-day-grid-event").css("background", "#51a251");
                        $('.fc-day-grid-event .fc-time:contains("11:00")').parents(".fc-day-grid-event").css("background", "#428242");
                        $('.fc-day-grid-event .fc-time:contains("12:00")').parents(".fc-day-grid-event").css("background", "#397539");
                        $('.fc-day-grid-event .fc-time:contains("13:00")').parents(".fc-day-grid-event").css("background", "#2e5f2e");
                        $('.fc-day-grid-event .fc-time:contains("14:00")').parents(".fc-day-grid-event").css("background", "#234823");
                        $('.fc-day-grid-event .fc-time:contains("15:00")').parents(".fc-day-grid-event").css("background", "#1c3a1c");
                        $('.fc-day-grid-event .fc-time:contains("16:00")').parents(".fc-day-grid-event").css("background", "#102110");
                        $('.fc-day-grid-event .fc-time:contains("17:00")').parents(".fc-day-grid-event").css("background", "#040604");
                    }, 300);

        }).error(function(data, status, headers, config) {
            if(data.error) { window.location.href = "#/logout" };
        });

        //collect data from calendar on click
        $scope.clickedEvent = false;
        $scope.alertOnEventClick = function( date, jsEvent, view){
            //$scope.alertMessage = (date.title + ' was clicked the date is' + date.start + "and id is" + date.id);
            /*$scope.updated_data.installer_id = date.id;
            $scope.updated_data.time_slot_id = date.time_slot_id;
            $scope.updated_data.appointment_date = date.appointment_date;
            $scope.selectedEvent = "Selected appointment: " + date.title +", date: " + date.appointment_date;
            $scope.clickedEvent = true;*/
            console.log(date); 
                $scope.alertMessage                 = (date.title + ' was clicked the date is' + date.start + "and id is" + date.id);
                $scope.installer_id                 = date.id;
                $scope.time_slot_id                 = date.time_slot_id;
                $scope.appointment_date             = date.appointment_date;
                $scope.selectedEvent                = "Selected appointment: " + date.title +", date: " + date.appointment_date + "\n" + " Time Slot Selected :" + $TIME_SLOT[parseInt(date.time_slot_id)].replace("T","") ;
                $scope.clickedEvent                 = true;                
                $scope.installer_id                 = $scope.installer_id;
                $scope.updated_time_slot            = date.time_slot_id; 
                $scope.updated_appointment_date     = date.appointment_date;
                $scope.installer_availability_id    = date.installer_availability_id;


            angular.element('.fc-day-grid-event').removeClass('selected-event');
            angular.element(this).addClass('selected-event');
            $http({
                method: 'POST',
                url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                data: {
                      "method_name": "checkTimeSlotAvailability",
                      "time_slot_id": date.time_slot_id,
                      "appointment_date": date.appointment_date,
                      "installer_id": date.id
                      
                }
            }).success(function(data) {
                if(data.time_slot_available == "no") {
                    $scope.newAppointmentMessage = data.response_message;
                    $('#appointment-modal').modal('show');

                    setTimeout(function() {
                        location.reload();
                    },300)
                }
            }).error(function(data) {

            })
        };
        
        $scope.hideEventAlert = function() { $scope.clickedEvent = false; };

        // End : calendar -- PRIYA BHOJANI --//


//check if the current url is edit appointment

    if($scope.curentUrl == "appointments"){


        $scope.tabActivate = function() {
            setTimeout(function(){
                document.querySelector('.fc-month-button').click();
            }, 300);


        }

        $scope.disabledField = true;
        $scope.modifyCustomerData = function() {
            if($scope.modify_existing_details) {
                $scope.disabledField = true;
            } else {
                $scope.disabledField = false;
            }
        };      
        /**********************************************************************************************************/
        //Tenemos que recoger los nuevos valores que han sido actualizado en el formulario de las distintas pestanas
        /**********************************************************************************************************/

        //set cancelation reason
        var cancelationComment = false;
        $scope.setCanceledReason = function() {
             if($scope.cancelation_reason == 'Other') {
                 $scope.scheduler_comments = "";
                 $scope.scheduler_comments      = $scope.scheduler_comments;
                 cancelationComment = true;
            } else {
                $scope.scheduler_comments       = "Cancelation reason: " + $scope.cancelation_reason; 
                cancelationComment = false;
            }
        };

        $scope.editAppointment = function (){
            $scope.method_name                  = "editAppointment";
            $scope.address_id                   = $scope.items["address_id"];
            $scope.first_name                   = $scope.first_name;
            $scope.last_name                    = $scope.last_name;
            $scope.customer_reference_client    = $scope.items["customer_reference_client"];
            $scope.property_number              = $scope.property_number;
            $scope.address_1                    = $scope.address_1;
            $scope.address_2                    = $scope.address_2;
            $scope.address_3                    = $scope.address_3;
            $scope.address_4                    = $scope.address_4;
            $scope.address_5                    = $scope.address_5;
            $scope.postcode                     = $scope.postcode;
            $scope.title                        = $scope.customer_title;
            $scope.email_id                     = $scope.email_id;
            $scope.customer_mobile_number       = $scope.customer_mobile_number;
            console.log($scope.customer_mobile_number + '--------------');
            $scope.telephone_number             = $scope.customer_telephone_number;   

            // Mohtadi Bakali Tahiri   mohtadibak@gmail.com
            // 07/09/2016
            // Note : Change status appointment according number of booking

            if($scope.items.appointment_date != $scope.appointment_date  && $scope.status == 'Booked'  ){
                 $scope.status                       = 'Re-Booked 1';                 
            } else if ($scope.items.appointment_date != $scope.appointment_date  && $scope.status == 'Re-Booked 1'){
                   $scope.status                     = 'Re-Booked 2'; $scope.appointment_date            =  $scope.items.appointment_date_2 ;
            } else if ($scope.items.appointment_date != $scope.appointment_date  && $scope.status == 'Re-Booked 2'){
                   $scope.status                     = 'Re-Booked 3';                    
            } else if ($scope.items.appointment_date != $scope.appointment_date  && $scope.status == 'Re-Booked 3'){
                   $scope.status                     = 'Re-Booked 4'; $scope.appointment_date            =  $scope.items.appointment_date_4 ;
            }else if ($scope.items.appointment_date != $scope.appointment_date  && $scope.status == 'Re-Booked 4'){
                   $scope.status                     = 'Re-Booked 5';                         
            } else {
                $scope.status                       = $scope.status;
            }




            $scope.contact_preference           = 'Text';
            $scope.security_password            = $scope.security_password;
            $scope.reminder_call_required       = $scope.reminder_call_required ? 'Yes':'No';
            $scope.how_heard_primary            = $scope.how_heard_primary ? $scope.how_heard_primary:'Letter';
            $scope.how_heard_secondary          = $scope.how_heard_secondary ? $scope.how_heard_secondary:'';
            $scope.over_16_and_bill_payer       = $scope.over_16_and_bill_payer ? 'Yes':'No';
           
            if(cancelationComment) {
                $scope.scheduler_comments           = $scope.scheduler_comments;
            };

            $scope.modify_existing_details      = $scope.modify_existing_details ? 1:0;
            $scope.customer_id                  = $scope.items["customer_id"];
            $scope.appointment_id               = $scope.items["appointment_id"];
            $scope.provider_id                  = 1; 
            $scope.user_id                      = 1;
            
            
            $scope.appointment_date             = $scope.appointment_date;
            $scope.installer_id                 = $scope.installer_id ;
            
            if($scope.time_selected && $scope.time_selected.length > 0)
            {
                $scope.time_slot_id             = $scope.updated_time_slot;
            }
            else
            {
                $scope.time_slot_id                 = $scope.time_slot_id;
            }            
 
        /**********************************************************************************************************/
        //            Los valores arriba recogidos los convierto en un objeto json para poder ser enviados
        /**********************************************************************************************************/

            $scope.updated_data =  JSON.stringify({
                method_name:'editAppointment',
                address_id: $scope.items["address_id"],
                first_name: $scope.first_name,
                last_name:$scope.last_name,
                customer_reference_client:$scope.items["customer_reference_client"],
                property_number:$scope.property_number,
                address_1:$scope.address_1,
                address_2:$scope.address_2,
                address_3:$scope.address_3,
                address_4:$scope.address_4,
                address_5:$scope.address_5,
                postcode:$scope.postcode,
                title:$scope.customer_title,
                email_id:$scope.email_id,
                mobile_number:$scope.customer_mobile_number,
                telephone_number:$scope.telephone_number,
                status:$scope.status,
                contact_preference:$scope.contact_preference,
                security_password:$scope.security_password,
                reminder_call_required:$scope.reminder_call_required ? 'Yes':'No',
                how_heard_primary:$scope.how_heard_primary,
                how_heard_secondary:$scope.how_heard_primary,
                over_16_and_bill_payer:$scope.over_16_and_bill_payer,
                scheduler_comments:$scope.scheduler_comments,
                modify_existing_details: $scope.modify_existing_details,
                customer_id:$scope.items["customer_id"],
                appointment_id:$scope.items["appointment_id"],
                provider_id:1,
                user_id:1,
                created_date:'2016-06-16 23:00:00',
                appointment_date:$scope.appointment_date,
                time_slot_id:$scope.time_slot_id,
                installer_id:$scope.installer_id,
                installer_availability_id:$scope.installer_availability_id,
                previous_time_slot_id:$scope.previous_time_slot_id,
                previous_appointment_date:$scope.previous_appointment_date
            });
            
        /**********************************************************************************************************/
        //      Antes hacer post al servidor comprobamos la salida que tienen los datos que se van a enviar 
                console.log( '############# JSON OBJECT ############');
                console.log($scope.updated_data);
                console.log( '######################################');
        /**********************************************************************************************************/

   
        console.log("At submit : " + $scope.time_slot_id);

            $http({
                method: 'POST',
                url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                data: $scope.updated_data,
                headers: {'Content-Type': 'application/json'}
            }).success(function(data) {
                console.log(data + 'first');
                if(data.response_code == SUCCESS_CODE) {
                    $scope.newAppointmentMessage = data.response_message;
                    window.location = '#/appointments';
                }else if(data.response_code == FAILURE_CODE) {
                    $scope.newAppointmentMessage = data.response_message;
                    $('#appointment-modal').modal('show');
                }
            }).error(function(data) {
                 console.log(data + 'sec');
                if(data.error) { window.location.href = "#/logout" };
            });

        };
        


        /**********************************************************************************************************/
        /**********************************************************************************************************/
       
       

        $scope.itemId                = $location.path().substr($location.path().lastIndexOf('/') + 1);
        $scope.getAppointmentDetails = {
            "method_name": "viewData",
            "appointment_id": $scope.itemId
        };
        $scope.getAppointmentDetails        = JSON.stringify($scope.getAppointmentDetails);
        $scope.items                        = null
        $scope.first_name                   = null;
        $scope.last_name                    = null;
        $scope.email_id                     = null
        $scope.mobile_number                = null;
        $scope.customer_telephone_number    = null;
        $scope.postcode                     = null;
        $scope.property_number              = null;
        $scope.address_1                    = null;
        $scope.address_2                    = null;
        $scope.address_3                    = null;
        $scope.address_4                    = null;
        $scope.address_5                    = null;
        $scope.comments                     = null;
        $scope.status                       = null;
        $scope.security_password            = null;
        $scope.scheduler_comments           = null;
        $scope.provider_id                  = null;          
  
        /**********************************************************************************************************/
        //  Hacemos una llamada al servidor  para obtener los detalles del apppointment que stamos  editando
        /**********************************************************************************************************/
 
        $http({
            method: 'POST',
            url: '/appointments/api?token=' + localStorage.getItem('userToken'),
            data: $scope.getAppointmentDetails,
            headers: {'Content-Type': undefined}
        }).success(function(data) {
            console.log("Details from server");
            console.log(data);
            if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            if(data.response_code == SUCCESS_CODE) 
            {
                console.log(data['data']);
                $scope.items                            = data["data"];
                
                if(data["data"]["appointment_date"].length > 0)
                {
                    $scope.appointment_date                 = data["data"]["appointment_date"];
                    $scope.time_slot_id                     = data["data"]["time_slot_id"];
                    $scope.previous_time_slot_id            = data["data"]["time_slot_id"];
                    $scope.previous_appointment_date        = data["data"]["appointment_date"];
                }
                if(data["data"]["appointment_date_1"] && data["data"]["appointment_date_1"].length > 0) 
                {
                    $scope.appointment_date                 = data["data"]["appointment_date_1"];
                    $scope.time_slot_id                     = data["data"]["time_slot_1"];
                    $scope.previous_time_slot_id            = data["data"]["time_slot_1"];
                    $scope.previous_appointment_date        = data["data"]["appointment_date_1"];
                }
                if(data["data"]["appointment_date_2"] && data["data"]["appointment_date_2"].length > 0)
                {
                    $scope.appointment_date                 = data["data"]["appointment_date_2"];
                    $scope.time_slot_id                     = data["data"]["time_slot_2"];
                    $scope.previous_time_slot_id            = data["data"]["time_slot_2"];
                    $scope.previous_appointment_date        = data["data"]["appointment_date_2"];
                }
                if(data["data"]["appointment_date_3"] && data["data"]["appointment_date_3"].length > 0)
                {
                    $scope.appointment_date                 = data["data"]["appointment_date_3"];
                    $scope.time_slot_id                     = data["data"]["time_slot_3"];
                    $scope.previous_time_slot_id            = data["data"]["time_slot_3"];
                    $scope.previous_appointment_date        = data["data"]["appointment_date_3"];
                }
                if(data["data"]["appointment_date_4"] && data["data"]["appointment_date_4"].length > 0)
                {
                    $scope.appointment_date                 = data["data"]["appointment_date_4"]
                    $scope.time_slot_id                     = data["data"]["time_slot_4"];
                    $scope.previous_time_slot_id            = data["data"]["time_slot_4"];
                    $scope.previous_appointment_date        = data["data"]["appointment_date_4"];
                }
                if(data["data"]["appointment_date_5"] && data["data"]["appointment_date_5"].length > 0)
                {
                    $scope.appointment_date                 = data["data"]["appointment_date_5"];
                    $scope.time_slot_id                     = data["data"]["time_slot_4"];
                    $scope.previous_time_slot_id            = data["data"]["time_slot_4"];
                    $scope.previous_appointment_date        = data["data"]["appointment_date_5"];
                }
                
                    $scope.first_name                       = data["data"]["first_name"];
                    $scope.last_name                        = data["data"]["last_name"];
                    $scope.email_id                         = data["data"]["customer_email_id"];
                    $scope.customer_mobile_number                    = data["data"]["customer_mobile_number"];
                    $scope.customer_telephone_number        = data["data"]["customer_telephone_number"];
                    $scope.postcode                         = data["data"]["postcode"];
                    $scope.property_number                  = data["data"]["property_number"];
                    $scope.address_1                        = data["data"]["address_1"];
                    $scope.address_2                        = data["data"]["address_2"];
                    $scope.address_3                        = data["data"]["address_3"];
                    $scope.address_4                        = data["data"]["address_4"];
                    $scope.address_5                        = data["data"]["address_5"];
                    $scope.comments                         = data["data"]["scheduler_comments"];
                    $scope.status                           = data["data"]["status"];
                    $scope.security_password                = data["data"]["security_password"];
                    $scope.scheduler_comments               = data["data"]["scheduler_comments"];
                    $scope.contact_preference               = data["data"]["contact_preference"];
                    $scope.how_heard_primary                = data["data"]["how_heard_primary"];
                    $scope.how_heard_secondary              = data["data"]["how_heard_secondary"];
                
                $scope.installer_id                     = data["data"]["installer_id"];
                
                switch(data["data"]["customer_title"]) {
                    case "Miss"  : document.getElementById('title-miss').selected = "true"; 
                    break;
                    case "Ms."   : document.getElementById('title-ms').selected = "true";
                    break;
                    case "Mrs."  : document.getElementById('title-ms').selected = "true";
                    break;
                    case "Mr."   : document.getElementById('title-mr').selected = "true";
                    break; 
                }
                $scope.customer_title                   = data["data"]["customer_title"];
                $scope.provider_id                      = data["data"]["provider_id"];

                console.log("Now : " + $scope.time_slot_id);
            }else if(data.response_code == FAILURE_CODE) {
                //
            }
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        });
        


    }//cierre del if que comprueba que estamos dentro de la pagina appointments



//get product detaill

    if($scope.curentUrl === "products")
    {
        $scope.product_detailsData = {
            "method_name": "viewData",
            "product_id" :$scope.itemId
        };

        $http({
            method: 'POST',
            url: '/products/api?token=' + localStorage.getItem('userToken'),
            data: $scope.product_detailsData,
            headers: {'Content-Type': undefined}
        }).success(function(data) {

            if(data.response_code === SUCCESS_CODE) {
                $scope.dataToShow = data['data'];
                angular.forEach($scope.dataToShow, function(value, key)            
                {
                    //console.log(value);

                    $scope.category_id = value.category_id;
                    $scope.category_name = value.category_name;
                    $scope.created_by = value.created_by;
                    $scope.created_date = value.created_date;
                    $scope.max_quantity = value.max_quantity;
                    $scope.money_saved = value.money_saved;
                    $scope.product_can_be = value.product_can_be;
                    $scope.product_category_name = value.category_name;
                    $scope.product_description = value.product_description;
                    $scope.product_id = value.product_id;
                    $scope.product_image = value.product_image;
                    $scope.product_name = value.product_name;
                    $scope.product_description = value.product_description;
                    $scope.product_status = value.product_status;
                    $scope.provider_id = value.provider_id;
                    $scope.stock_quantity = value.stock_quantity;
                    $scope.swsm_id = value.swsm_id;
                    $scope.updated_by = value.updated_by;
                    $scope.updated_date = value.updated_date;
                    $scope.water_saved = value.water_saved;
                    $scope.second_product_water_saved = value.second_product_water_saved;
                    $scope.third_product_water_saved = value.third_product_water_saved;
                     
                    $scope.selected_category = {product_category_id: value.category_id, product_category_name: value.category_name};                
                });
            }else if(data.response_code === FAILURE_CODE) {
                //
            }
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        });
    }



    if($scope.curentUrl === "categories")
    {
        $scope.category_detailsData = {
            "method_name": "viewData",
            "product_category_id" :$scope.itemId
        };

        $http({
            method: 'POST',
            url: '/categories/api?token=' + localStorage.getItem('userToken'),
            data: $scope.category_detailsData,
            headers: {'Content-Type': undefined}
        }).success(function(response) {
            //console.log(response);
            if(response.response_code === SUCCESS_CODE) {
               $scope.category_details = response['data'];
               $scope.product_category_name = $scope.category_details.product_category_name;
               $scope.category_status = $scope.category_details.category_status;
               $scope.max_savings = $scope.category_details.max_savings;
            }else if(response.response_code === FAILURE_CODE) {
                //
            }
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        });
    }
    ProviderListFactory.async().success(function(data) {
        if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
        $scope.providers = data['data'];
    }).error(function(data) {
        if(data.error) { window.location.href = "#/logout" };
    });

    //get category list
    CategoryListFactory.async().success(function(data) {
        if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
        $scope.categories = data['data'];
    }).error(function(data) {
        if(data.error) { window.location.href = "#/logout" };
    });

    //edit product
    var uploadedCount = 0;
    $scope.files = [];
    $scope.imageChanged = false;
    $scope.editProduct = function() { 
        if($scope.productCanBe === 'InstalledORLeft') { $scope.productCanBe = 'Installed OR Left' }

        $scope.editProductData = {
                "method_name"           : "editData",
                "product_id"            : parseInt(itemId, 10),
                "provider_id"           : 1,//parseInt($scope.provider,10),
                "category_id"           : parseInt($scope.selected_category.product_category_id,10),
                "swsm_id"               : parseInt($scope.swsm_id,10),
                "product_status"        : $scope.product_status,
                "product_name"          : $scope.product_name,
                "product_description"   : $scope.product_description,
                "max_quantity"          : parseInt($scope.max_quantity,10),
                "stock_quantity"        : parseInt($scope.stock_quantity, 10),
                "water_saved"           : parseInt($scope.water_saved, 10),
                "money_saved"           : parseInt($scope.money_saved,10),
                "second_product_water_saved"           : parseInt($scope.second_product_water_saved, 10),
                "third_product_water_saved"           : parseInt($scope.third_product_water_saved, 10),
                "product_image"         : "product_image.jpg",
                "product_can_be"        : $scope.product_can_be,
                "user_id"               : parseInt(localStorage.getItem('user_id'), 10)
            };

        if($scope.imageChanged == true) {
            $scope.editProductData.file = {
                "filetype"          : $scope.files[0].filetype,
                "filename"          : $scope.files[0].filename,
                "filesize"          : $scope.files[0].filesize,
                "image_base64_encrypted_string" : $scope.files[0].base64
            }                  
        }   

        $scope.editProductData = JSON.stringify($scope.editProductData);
        $http({
            method: 'POST',
            url: '/products/api?token=' + localStorage.getItem('userToken'),
            data: $scope.editProductData,
            headers: {'Content-Type': undefined}
        }).success(function(data) {
            if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            if(data.response_code == SUCCESS_CODE) {
                window.location = '#/products';
            }else if(data.response_code == FAILURE_CODE) {
                
            }
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        })
    };



    $scope.submitEditExistingCategory = function (){

        $scope.itemId                   = $location.path().substr($location.path().lastIndexOf('/') + 1);
        $scope.category_active          = parseInt($scope.category_status ? 1:0);
        $scope.dataUpdatedNewCategory   = $scope.product_category_name;
        
        if($scope.max_savings === undefined)
        {
            $scope.max_savings = null;
        }
                        
        $scope.updatedCategoryData = {
            "method_name": "editData",
            "product_category_id" : $scope.itemId,
            "product_category_name": $scope.product_category_name,
            "status" : $scope.category_status,
            "max_savings": $scope.max_savings
        };

        $scope.updatedCategoryData = JSON.stringify($scope.updatedCategoryData);


        $http({
            method: 'POST',
            url: '/categories/api?token=' + localStorage.getItem('userToken'),
            data: $scope.updatedCategoryData,
            headers: {'Content-Type': undefined}
        }).success(function(data) {
            if(data.response_code == FAILURE_CODE ) {window.location = "#/logout"};
            if(data.response_code == SUCCESS_CODE) {
                window.location = '#/categories';
            }else if(data.response_code == FAILURE_CODE) {
                //
            }
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        })



    };



}]);