hwecApp.controller('calculatorCustomerBookAppointmentController', ['PROVIDER','$filter', '$scope', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', function (PROVIDER,$filter, $scope, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL) { 
        
        var loader = document.getElementById("loading-content");
        loader.classList.add("hide-loader");
  
        // Start : load "how heard primary" options by provider -- //
        $scope.provider_details = null;
        $scope.how_heard_primary_options = null;
                
        $http({
            method: 'POST',
            url: 'customers/api?token=',
            data: '{"method_name":"getProviderConfigDetails"}',
            headers: {'Content-Type': 'application/json'}
        }).success(function (data) 
        {                    
            if (data.response_code === SUCCESS_CODE) 
            {
                $scope.provider_details = data;
                $scope.how_heard_primary_options = data.HOW_HEARD_PRIMARY_DROPDOWN_OPTIONS;
            }
            else if (data.response_code === FAILURE_CODE) 
            {
                window.location = "#/logout";
            }
        }).error(function (data) {
            if (data.error) 
            {
                window.location = "#/logout";
            }
        });
        // End : load "how heard primary" options by provider -- //
                
        //hide main nav for book-appointment page
        document.getElementById('main-navigation').style.display = 'none';
        $scope.PROVIDER = PROVIDER; 
        $scope.paginatePage = true;
        $scope.hideNewBtn = false;
        $scope.hideBackBtn = true;
        $scope.timeslotSelect = false;
        $scope.curentUrl = $location.path().replace('/', "");
        $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);

        $scope.pageTitle = $scope.curentUrl.slice(0, -1) + " list";

        $scope.pageTitle = "Book an appointment";
        $scope.backLink = '#/';
        $scope.pageNumber = 1;
        $scope.lastPage = false;
        $scope.firstPage = false;
        $scope.newAppointmentData = [];
        $scope.disabledField = false;//!$scope.disabledField;
           $('.dropdown.open').toggle();
           $scope.newAppointmentData.modify_existing_details = parseInt($scope.modifyData  ? 0:1);
           $scope.newAppointmentData.address_id = 0;
           $scope.newAppointmentData.customer_id = 0;
            $scope.newAppointmentData.address_1 =
            $scope.newAppointmentData.address_2 =                             
            $scope.newAppointmentData.address_3 =                            
            $scope.newAppointmentData.address_4 =                            
            $scope.newAppointmentData.address_5 =                             
            $scope.newAppointmentData.property_number = null;             

       //display calendar when data is ready
        $scope.events = [];
        $scope.readyToLoad = false;
        $http({
            method: 'POST',
            url: '/calculatorcustomers/api?token=',
            data:{"method_name":"getInstallerAvailability"},
            headers: {'Content-Type': 'application/json'}
        }).success(function(data, status, headers, config) {

            //if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
            angular.forEach(data["data"],function(value, index) 
            {   
                var time_slot_title = value['installer_name'];
                //object to be displayed in the calendar
                $scope.events.push({
                    id: value['installer_id'],
                    title: time_slot_title,
                    start: value['available_date'],
                    appointment_date: value['available_date'],
                    time_slot_id: value['available_time_slot_id'],
                    allDay: false,
                    stick: true
                });
            }); //end foreach 

            /* event source that calls a function on every view switch */
            $scope.eventsF = function (start, end, timezone, callback) {
                var s = new Date(start).getTime() / 1000;
                var e = new Date(end).getTime() / 1000;
                var m = new Date(start).getMonth();
                var events = [{title: 'Feed Me ' + m,start: s + (50000),end: s + (100000),allDay: false, className: ['customFeed']}];
                callback(events);
            };

            /* alert on Drop */
            $scope.alertOnDrop = function(event, delta, revertFunc, jsEvent, ui, view){
                $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
            };
            /* alert on Resize */
            $scope.alertOnResize = function(event, delta, revertFunc, jsEvent, ui, view ){
                $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
            };
            /* add and removes an event source of choice */
            $scope.addRemoveEventSource = function(sources,source) {
                var canAdd = 0;
                angular.forEach(sources,function(value, key){
                    if(sources[key] === source){
                        sources.splice(key,1);
                        canAdd = 1;
                    }
                });
                if(canAdd === 0){
                    sources.push(source);
                }
            };
            /* add custom event*/
            $scope.addEvent = function() {
                $scope.events.push({
                    title: 'Open Sesame',
                    start: new Date(y, m, 28),
                    end: new Date(y, m, 29),
                    className: ['openSesame']
                });
            };
            /* remove event */
            $scope.remove = function(index) {
                $scope.events.splice(index,1);
            };
            /* Change View */
            $scope.changeView = function(view,calendar) {
                uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
            };
            /* Change View */
            $scope.renderCalender = function(calendar) {
                if(uiCalendarConfig.calendars[calendar]){
                    uiCalendarConfig.calendars[calendar].fullCalendar('render');
                }
            };
 
            // config calendar
            $scope.uiConfig = {
                calendar:{
                    timeFormat:'HH:mm',
                    height: 600,
                    firstDay:1,
                    editable: false,
                    displayEventTime:false,
                    header:{
                        left: 'month basicWeek basicDay',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    eventClick: $scope.alertOnEventClick,
                    eventDrop: $scope.alertOnDrop,
                    eventResize: $scope.alertOnResize,
                    eventRender: $scope.eventRender
                }
            };  
            $scope.eventSources = [$scope.events, $scope.eventsF];
            //data is ready. Displaying calendar in view
            $scope.readyToLoad = true;
        }).error(function(data, status, headers, config) {
            
        });

        //collect data from calendar on click
        $scope.clickedEvent = false;
        $scope.alertOnEventClick = function( date, jsEvent, view){
            //$scope.alertMessage = (date.title + ' was clicked the date is' + date.start + "and id is" + date.id);
            $scope.newAppointmentData.installer_id = date.id;
            $scope.newAppointmentData.time_slot_id = date.time_slot_id;
            $scope.newAppointmentData.appointment_date = date.appointment_date;
            $scope.selectedEvent = "Selected appointment: " + date.title +", date: " + date.appointment_date;
            angular.element('.fc-day-grid-event').removeClass('selected-event');
            angular.element(this).addClass('selected-event');
            $scope.clickedEvent = true;
            $scope.timeslotSelect = false; 
        };
        $scope.hideEventAlert = function() { $scope.clickedEvent = false; };


        //$scope.disabledField = true; 
       
        
        $scope.modifyData = true; // added on 25th October 2016 for : Job 1347 - Edit address on web booking 
        
        $scope.modifyCustomerData = function() {
            $scope.disabledField = !$scope.disabledField;
            if($scope.newAppointmentData.modify_existing_details) {
                $scope.newAppointmentData.modify_existing_details = 1;
            } else {
                $scope.newAppointmentData.modify_existing_details = 1; // kept it spcifically because a customer can not edit the data -- PRIY A BHOJANI --//
            }
        }; 

  
        //get adress based on postcode
        $scope.getAdressList = function() {
            $http({ 
                method: 'POST',
                url: 'customers/api?token=',
                data: {
                    method_name: "getAddressDetailsByPostCode",
                    post_code: $scope.newAppointmentData.postcode

                }
            }).success(function(data) { 
               
                if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
                if(data.response_code == SUCCESS_CODE) {
                    $scope.lists = data['data'];
                    $scope.fullAddresses = AppointmentFactory.listOfFullAddresses($scope.lists);
                    $scope.focus = true;
                    $scope.modifyData = true;
                    $scope.disabledField = true;
                    //hide postcode error if postcode found
                    $scope.postcodeAdressNot = false;
                }else if(data.response_code == NOT_FOUND_CODE) {
                    //show postcode error if not found
                    $scope.postcodeAdressNot = true;
                    $scope.newAppointmentData.modify_existing_details = 0;
                    $scope.newAppointmentData.address_id = null;
                    $scope.disabledField = false;
                    $scope.modifyData = false;

                }else if(data.response_code == FAILURE_CODE) {
                   
                }

            }).error(function(data) {
                if(data.error == "token_not_provided") { window.location.href = "#/logout" };
            });
        };

        $scope.modifyCustomerData = function () {
            $scope.disabledField = !$scope.disabledField;
           $('.dropdown.open').toggle();
           $scope.newAppointmentData.modify_existing_details = parseInt($scope.modifyData  ? 0:1);
           $scope.newAppointmentData.address_id = null;
            $scope.newAppointmentData.address_1 =
            $scope.newAppointmentData.address_2 =                             
            $scope.newAppointmentData.address_3 =                            
            $scope.newAppointmentData.address_4 =                            
            $scope.newAppointmentData.address_5 =                             
            $scope.newAppointmentData.property_number = null;                      

        };




        
        //autocomplete adress from select
        $scope.addAdress = function(addressId) {
            var foundObject = ($filter('filter')($scope.lists,{address_id:addressId}))[0];
            $scope.newAppointmentData.address_1                                 = foundObject.address_1;
            $scope.newAppointmentData.address_2                                 = foundObject.address_2;
            $scope.newAppointmentData.address_3                                 = foundObject.address_3;
            $scope.newAppointmentData.address_4                                 = foundObject.address_4;
            $scope.newAppointmentData.address_5                                 = foundObject.address_5;
            $scope.newAppointmentData.postcode                                  = foundObject.postcode;
            $scope.newAppointmentData.property_number                           = foundObject.property_number;
            $scope.newAppointmentData.address_id                                = foundObject.address_id;
            $scope.newAppointmentData.customer_name_client                      = foundObject.customer_name_client;
            $scope.newAppointmentData.customer_reference_client                 = foundObject.customer_reference_client;
            $scope.newAppointmentData.customer_id                               = foundObject.customer_id;
            $scope.newAppointmentData.modify_existing_details                   = 1;
            //$scope.newAppointmentData.title                                     = foundObject.title;
            
            //customer details
            // switch($scope.newAppointmentData.title) {
            //     case "Miss"  : document.getElementById('title-miss').selected = "true"; 
            //     break;
            //     case "Ms."   : document.getElementById('title-ms').selected = "true";
            //     break;
            //     case "Mrs."  : document.getElementById('title-ms').selected = "true";
            //     break;
            //     case "Mr."   : document.getElementById('title-mr').selected = "true";
            //     break; 
            // }
            //$scope.newAppointmentData.first_name                                = foundObject.first_name;
            //$scope.newAppointmentData.last_name                                 = foundObject.last_name;
            //$scope.newAppointmentData.email_id                                  = foundObject.email_id;
            //$scope.newAppointmentData.mobile_number                             = foundObject.mobile_number;
            //$scope.newAppointmentData.telephone_number                          = foundObject.telephone_number;
            
            $scope.focus = false;
            $scope.modifyData = false;
        };   

        //object for creating new appointment
        $scope.newAppointmentData = {
            method_name: "addAppointment",
            status: "Pending_1",
            user_id: localStorage.getItem('user_id'),
            customer_id:0,
            address_id:0
        };
        
        //get current date time (yyyy-mm-dd h:mm:ss)
        var currentDate = new Date();
        $scope.newAppointmentData.created_date =
            currentDate.getFullYear() + '-'
            + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-'
            + ('0' + currentDate.getDate()).slice(-2) + " "
            + ('0' + currentDate.getHours()).slice(-2) + ":"
            + ('0' + currentDate.getMinutes()).slice(-2)+ ":"
            + ('0' + currentDate.getSeconds()).slice(-2);

        $scope.notOver16 = false;    
        $scope.over16Check = function() {
            if($scope.newAppointmentData.over_16_and_bill_payer == 1) {
                  
                $scope.notOver16 = true
            } else {
                $scope.notOver16 = false
                
            }  

        };    

        $scope.succesBooked = false;
        $scope.newAppointment = function() {
             if($scope.newAppointmentData.over_16_and_bill_payer) {
                $scope.notOver16 = false;
             } else {
                $scope.notOver16 = true;
             }
             

             $scope.submitted = true;
            //Recolenting data from form
            $scope.newAppointmentData.title                                 = $scope.newAppointmentData.title;
            $scope.newAppointmentData.first_name                            = $scope.newAppointmentData.first_name;
            $scope.newAppointmentData.last_name                             = $scope.newAppointmentData.last_name;
           // $scope.newAppointmentData.modify_existing_details                 = parseInt($scope.modifyData  ? 0:1);
            //Address Details
            $scope.newAppointmentData.postcode                              = $scope.newAppointmentData.postcode;
            $scope.newAppointmentData.property_number                       = $scope.newAppointmentData.property_number;
            $scope.newAppointmentData.address_1                             = $scope.newAppointmentData.address_1;
            $scope.newAppointmentData.address_2                             = $scope.newAppointmentData.address_2;
            $scope.newAppointmentData.address_3                             = $scope.newAppointmentData.address_3;
            $scope.newAppointmentData.address_4                             = $scope.newAppointmentData.address_4;
            $scope.newAppointmentData.address_5                             = $scope.newAppointmentData.address_5;
            $scope.newAppointmentData.provider_id                           = parseInt(PROVIDER_ID);
            $scope.newAppointmentData.email_id                              = $scope.newAppointmentData.email_id;
            $scope.newAppointmentData.mobile_number                         = $scope.newAppointmentData.mobile_number;
            $scope.newAppointmentData.telephone_number                      = $scope.newAppointmentData.telephone_number;
            $scope.newAppointmentData.reminder_call_required                = $scope.newAppointmentData.reminder_call_required  ? 'Yes': 'No';
            if($scope.newAppointmentData.contact_preference == undefined) {
                $scope.newAppointmentData.contact_preference = "Landline";
            }

            $scope.newAppointmentData.contact_preference                    = $scope.newAppointmentData.contact_preference;
            $scope.newAppointmentData.over_16_and_bill_payer                = parseInt($scope.newAppointmentData.over_16_and_bill_payer  ? 1:0);
            $scope.newAppointmentData.how_heard_primary                     = $scope.newAppointmentData.how_heard_primary;
            $scope.newAppointmentData.channel                               = 'Website';

            if($scope.newAppointmentData.address_2 == undefined) {
                $scope.newAppointmentData.address_2 = null;
            }
            
            if($scope.newAppointmentData.address_3 == undefined) {
                $scope.newAppointmentData.address_3 = null;
            }
            
            if($scope.newAppointmentData.address_4 == undefined) {
                $scope.newAppointmentData.address_4 = null;
            }
            
            if($scope.newAppointmentData.email_id == undefined) {
                $scope.newAppointmentData.email_id = null;
            }
            if($scope.newAppointmentData.mobile_number == undefined) {
                $scope.newAppointmentData.mobile_number = null;
            }
            if($scope.newAppointmentData.modify_existing_details == undefined) {
                $scope.newAppointmentData.modify_existing_details = 1;
            }
            if($scope.newAppointmentData.security_password == undefined) {
                $scope.newAppointmentData.security_password = null;
            } else {
                $scope.newAppointmentData.security_password = $scope.newAppointmentData.security_password;
            }
            if($scope.newAppointmentData.scheduler_comments == undefined) { 
                $scope.newAppointmentData.scheduler_comments = null;
            } else {
                $scope.newAppointmentData.scheduler_comments = $scope.newAppointmentData.scheduler_comments;
            }
            if($scope.newAppointmentData.how_heard_secondary == undefined) {
                $scope.newAppointmentData.how_heard_secondary = null;
            } else {
                $scope.newAppointmentData.how_heard_secondary = $scope.newAppointmentData.how_heard_secondary;
            }
            $scope.appointmentData = JSON.stringify($scope.newAppointmentData);

            if($scope.clickedEvent == false) {
                $scope.timeslotSelect = true;
            } else {
               $scope.timeslotSelect = false; 
            };
            setTimeout(function() {
                if($(".text-danger:visible").length > 0 || $scope.clickedEvent == false) {
                    if($(".text-danger:visible").length > 0) { 
                        var errorElement = $(".text-danger:visible");
                        var scrollTo = errorElement.offset().top - 60;
                        $('html, body').animate({
                        scrollTop: scrollTo
                        }, 300);
                     };
                } else 
                {
                    var time_slot = '';
                    if($scope.newAppointmentData.time_slot_id === 1000)
                    {
                        time_slot = 'AM';
                    }
                    else
                    {
                        time_slot = 'PM';
                    }
                    
                    
                
                    $http({
                    method: 'POST',
                    url: 'calculatorcustomers/api?token=',
                    data: $scope.appointmentData,
                    headers: {'Content-Type': 'application/json'}
                    }).success(function(data) { 
                        if(data.response_code == SUCCESS_CODE) {
                            $scope.newAppointmentData.appointment_id = data.appointment_id;
                            
                            $scope.showAppointmentDetails = {
                                date           : $scope.newAppointmentData.appointment_date,
                                title     : $scope.newAppointmentData.title,
                                first_name     : $scope.newAppointmentData.first_name,
                                last_name     : $scope.newAppointmentData.last_name,
                                time_slot      : time_slot,
                                time_slot_2    : '',
                                property_number : $scope.newAppointmentData.property_number,
                                address_1 : $scope.newAppointmentData.address_1,
                                address_2 : $scope.newAppointmentData.address_2,
                                address_3 : $scope.newAppointmentData.address_3,
                                address_4 : $scope.newAppointmentData.address_4,
                                address_5 : $scope.newAppointmentData.address_5,
                                appointment_id : $scope.newAppointmentData.appointment_id  
                            };
                            $scope.newAppointmentMessage = data.response_message;
                            $scope.succesBooked = true; 
                            //window.location = '#/appointment-message'; 
                        }else if(data.response_code == FAILURE_CODE) {
                            $scope.newAppointmentMessage = data.response_message;
                            $('#appointment-modal').modal('show'); 
                        }
                    }).error(function(data) { 
                        
                    });
                }; 
            },300)
        };        
}]);