hwecApp.controller('listController', ['$scope', '$http','$records_per_page', '$route', '$location', 'Restangular', 'FAILURE_CODE',  function($scope, $http, $records_per_page, $route, $location, Restangular, FAILURE_CODE,SUCCESS_CODE){

var loader = document.getElementById("loading-content");
loader.classList.remove("hide-loader");
console.log('onother');


    $scope.completed  = function(status){
        if(status == 'Completed'){
            console.log("Este es el estado "+status);
        } else {
            return false;
        }
    }

    $scope.checkUserRol = function(){

        if($scope.user_role_id === 7 || $scope.user_role_id === 5){
            return false;
        } else {
            return true;
        }
    };
///////////////////////////////Check current user logged //////////////////////////
///////////////////////////////////////////////////////////////////////////////////
    $scope.$user_id = localStorage.getItem('user_id');
        $http({
            method: 'GET',
            url: 'api/getAuthenticatedUser?token=' + localStorage.getItem('userToken'),
            data:null,
            headers: {'Content-Type': 'application/json'}
        }).success(function(data) {
            $scope.user_role_id = data["role"];


            console.log("rol de usuario " + $scope.user_role_id);
            console.log(data);
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        })

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

 $scope.getRealTimeSlot = ['08:00:00', '09:00:00' ,'10:00:00','11:00:00','11:30:00', '12:00:00', '13:00:00', '14:00:00','14:30:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00'];
 
    $scope.getAMPM = []; 
    $scope.getAMPM['1000'] = 'AM';
    $scope.getAMPM['2000'] = 'PM';
    $scope.paginatePage = true;
	$scope.hideNewBtn = true;
	$scope.curentUrl = $location.path().replace('/',"");
	$scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1); 

    $scope.check_filter_applied = false;
    $scope.check_range_date_applied = false;

	$scope.pageTitle = $scope.curentUrl.slice(0, -1) + " list";

	//modify categorie to category
	if($scope.Urllocation == "categorie") {$scope.Urllocation = "category"}
	if($scope.pageTitle == "categorie list") {$scope.pageTitle = "category list"}
	
	$scope.backLink = '#/';
	$scope.pageNumber = 1; 
	$scope.lastPage = false; 
	$scope.firstPage = false;
	if($scope.curentUrl != 'dashboard') {
		$http({
			  method: 'POST', 
			  url: '/'+ $scope.curentUrl + '/api?token=' + localStorage.getItem('userToken'), 
			  data:{"records_per_page" : $records_per_page, "page_number": $scope.pageNumber, "method_name":"listData"},
			  headers: {'Content-Type': 'application/json'}  
			}).then(function successCallback(response) {
                loader.classList.add("hide-loader");
				if(response.data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
				$scope.items = response['data'];
				$scope.appointments = response['data'];
				var appointmentsCount = response['data']['total_number_of_appointments'];
				//show pagination if more then 20 appointments
				if(appointmentsCount < 21) { $scope.paginatePage = false; }
				$scope.totalPages = Math.ceil(appointmentsCount / $records_per_page);
			  }, function errorCallback(response) {
                    loader.classList.add("hide-loader");
			  		if(response.data.error) { window.location.href = "#/logout" };
			  }); 
	};

     //*********************************************************************************************************//
     //******************************** This is code belong to Search Controller *******************************//
     //*********************************************************************************************************//


    $scope.date = new Date();
    $scope.sortType     = 'updated_date'; // set the default sort type
    $scope.sortReverse  = true;  // set the default sort order

    $scope.appo = []; //declare an empty array
    $scope.pageno = 1; // initialize page no to 1
    $scope.total_count = 0;
    $scope.itemsPerPage = 20; //this could be a dynamic value from a drop down



    $scope.getData = function(pageno) {
            loader.classList.remove("hide-loader");
            $scope.keyword          =    $scope.keyword  ? $scope.keyword:null;
            $scope.fieldToSearch    =    $scope.fieldToSearch  ? $scope.fieldToSearch:null;
            $scope.fecha1           =    $scope.fecha1  ? $scope.fecha1:null;
            $scope.fecha2           =    $scope.fecha2  ? $scope.fecha2:null;
            pageno =  pageno  ? pageno:1;

            $scope.appointmentDataFiltered = {
                "method_name": "searchByKeyword",
                "field":$scope.fieldToSearch,
                "keyword":$scope.keyword,
                "fecha1":$scope.fecha1,
                "fecha2":$scope.fecha2,
                "page_number":pageno,
                "records_per_page":$scope.itemsPerPage
            }

            $scope.appointmentDataFiltered = JSON.stringify($scope.appointmentDataFiltered);
            console.log( $scope.appointmentDataFiltered);


            $http({
                method: 'POST',
                url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                data: $scope.appointmentDataFiltered,
                headers: {'Content-Type': 'application/json'}
            }).success(function(data) {
                loader.classList.add("hide-loader");
                $scope.total_count  = data["total_elements"];
                $scope.appo = data["data"];
                console.log(data);
                if($scope.keyword != null && $scope.keyword != null) {
                    $scope.check_filter_applied = true;
                }

            }).error(function(data) {
                if(data.error) { window.location.href = "#/logout" };
            })
    };


        $scope.getData($scope.pageno);

    $scope.removeFilters = function(){
        $scope.fieldToSearch = null,
        $scope.keyword = null,
        $scope.fecha1 = null,
        $scope.fecha2 = null,
        $scope.check_filter_applied = false;
        $scope.getData(1);
    };



    //******************************************************************************************************//
    //******************************************************************************************************//
 
	$scope.reload = function() { 
		console.log('reload...');
		$http({
		  method: 'POST', 
		  url: '/'+ $scope.curentUrl + '/api?token=' + localStorage.getItem('userToken'),
		  data:{"records_per_page" : $records_per_page, "page_number": $scope.pageNumber, "method_name":"listData"},
		  headers: {'Content-Type': 'application/json'} 
		}).then(function successCallback(response) {
			if(response.data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
			$scope.items = response['data'];
			$scope.appointments = response['data'];
			var appointmentsCount = response['data']['total_number_of_appointments'];
			//show pagination if more then 20 appointments
			if(appointmentsCount < 21) { $scope.paginatePage = false; }
			$scope.totalPages = Math.ceil(appointmentsCount / $records_per_page);

		  }, function errorCallback(response) {
		  		if(response.data.error) { window.location.href = "#/logout" }
		  });
	};

    $scope.removeCategory = function(item)
    {    
        $scope.deleteCategoryData = {
            "method_name": "deleteData",
            "product_category_id" : item
        };

        $scope.deleteCategoryData = JSON.stringify($scope.deleteCategoryData);

        $http({
            method: 'POST',
            url: 'categories/api?token=' + localStorage.getItem('userToken'),
            data: $scope.deleteCategoryData,
            headers: {'Content-Type': 'application/json'}
        }).success(function(data) {
            angular.element(document.querySelector('.item_status_' + item)).html("Deleted");
            angular.element(document.querySelector('#delete_button_' + item)).hide();
        }).error(function(data) { 
            if(data.error) { window.location.href = "#/logout" };
        })
    }; 
    
    $scope.removeProduct = function(item)
    {    
        $scope.deleteProductData = {
            "method_name": "deleteData",
            "product_id" : item
        };

        $scope.deleteProductData = JSON.stringify($scope.deleteProductData);

        $http({
            method: 'POST',
            url: 'products/api?token=' + localStorage.getItem('userToken'),
            data: $scope.deleteProductData,
            headers: {'Content-Type': 'application/json'}
        }).success(function(data) {
            angular.element(document.querySelector('.item_status_' + item)).html("Deleted");
            angular.element(document.querySelector('#delete_button_' + item)).hide();
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        })
    };


 

}]);                               
