hwecApp.controller('newController',
        ['$window','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', function ($window,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory) {

                var loader = document.getElementById("loading-content");
                loader.classList.remove("hide-loader");


                
                $scope.show_confirm_appointment_button = true;
                $scope.show_confirming_appointment_button = false;
                
                // Start : load "how heard primary" options by provider -- //
                $scope.provider_details = null;
                $scope.how_heard_primary_options = null;
                
                $scope.second_product_water_saved = 0;
                $scope.third_product_water_saved = 0;
                 
                $http({
                    method: 'POST',
                    url: 'providers/api?token=' + localStorage.getItem('userToken'),
                    data: '{"method_name":"getProviderConfigDetails"}',
                    headers: {'Content-Type': 'application/json'}
                }).success(function (data) 
                {                    
                    if (data.response_code === SUCCESS_CODE) 
                    {
                        $scope.provider_details = data;
                        $scope.how_heard_primary_options = data.HOW_HEARD_PRIMARY_DROPDOWN_OPTIONS;
                    }
                    else if (data.response_code === FAILURE_CODE) 
                    {
                        window.location = "#/logout";
                    }
                }).error(function (data) {
                    if (data.error) 
                    {
                        window.location = "#/logout";
                    }
                });
                // End : load "how heard primary" options by provider -- //
                        
                $scope.show_error_message = false;
                $scope.error_message_text = 'Please fill required fiealds';

                //                        flag to check if the call back is active           
                //********************************************************************************
                //********************************************************************************
                $scope.callback_status = false;
                $scope.reminder_call_switch_class = 'false';



                $scope.callBack = function () {
                    $scope.callback_status = true;
                    $scope.newAppointmentData.created_date = null;
                    $scope.newAppointmentData.time_slot_id = null;
                    $scope.newAppointmentData.installer_id = 0;
                    $scope.newAppointmentData.appointment_date = null;
                    $scope.newAppointmentData.status = "Call Back";                    
                }
                //********************************************************************************
                //********************************************************************************


                $scope.hideNewBtn = false;
                $scope.curentUrl = $location.path().replace('/', "");

                $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);
                $scope.baseUrl = $scope.curentUrl.slice(0, -4);
                $scope.pageTitle = "New " + $scope.baseUrl;

                if ($scope.Urllocation == "categorie") {
                    $scope.Urllocation = "category"
                }
                if ($scope.pageTitle == "categorie list") {
                    $scope.pageTitle = "category list"
                }

                $scope.backLink = '#/' + $scope.baseUrl;

                //display calendar when data is ready
                $scope.events = [];
                $scope.readyToLoad = false;
                $http({
                    method: 'POST',
                    url: '/installers/api?token=' + localStorage.getItem('userToken'),
                    data: {"method_name": "getInstallerAvailability"},
                    headers: {'Content-Type': 'application/json'}
                }).success(function (data, status, headers, config) {
                    loader.classList.add("hide-loader");
                    if (data.response_code == FAILURE_CODE) {
                        window.location.href = "#/logout"
                    }
                    ;
                    angular.forEach(data["data"], function (value, index) {
                        //object to be displayed in the calendar
                        $scope.events.push({
                            id: value['installer_id'],
                            title: value['installer_name'] + " : " +value['work_area_postcode'],
                            start: value['available_date'] + $TIME_SLOT[value['available_time_slot_id']],
                            appointment_date: value['available_date'],
                            time_slot_id: value['available_time_slot_id'],
                            allDay: false,
                            stick: true
                        });
                    }); //end foreach

                    /* event source that calls a function on every view switch */
                    $scope.eventsF = function (start, end, timezone, callback) {
                        var s = new Date(start).getTime() / 1000;
                        var e = new Date(end).getTime() / 1000;
                        var m = new Date(start).getMonth();
                        var events = [{title: 'Feed Me ' + m, start: s + (50000), end: s + (100000), allDay: false, className: ['customFeed']}];
                        callback(events);
                    };

                    /* alert on Drop */
                    $scope.alertOnDrop = function (event, delta, revertFunc, jsEvent, ui, view) {
                        $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
                    };
                    /* alert on Resize */
                    $scope.alertOnResize = function (event, delta, revertFunc, jsEvent, ui, view) {
                        $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
                    };
                    /* add and removes an event source of choice */
                    $scope.addRemoveEventSource = function (sources, source) {
                        var canAdd = 0;
                        angular.forEach(sources, function (value, key) {
                            if (sources[key] === source) {
                                sources.splice(key, 1);
                                canAdd = 1;
                            }
                        });
                        if (canAdd === 0) {
                            sources.push(source);
                        }
                    };
                    /* add custom event*/
                    $scope.addEvent = function () {
                        $scope.events.push({
                            title: 'Open Sesame',
                            start: new Date(y, m, 28),
                            end: new Date(y, m, 29),
                            className: ['openSesame']
                        });
                    };


                    /* remove event */
                    $scope.remove = function (index) {
                        $scope.events.splice(index, 1);
                    };
                    /* Change View */
                    $scope.changeView = function (view, calendar) {
                        uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
                    };
                    /* Change View */
                    $scope.renderCalender = function (calendar) {
                        if (uiCalendarConfig.calendars[calendar]) {
                            uiCalendarConfig.calendars[calendar].fullCalendar('render');
                        }
                    };

                    // config calendar
                    $scope.uiConfig = {
                        calendar: {
                            timeFormat: 'HH:mm',
                            height: 600,
                            editable: false,
                            firstDay: 1,
                            header: {
                                left: 'month basicWeek basicDay',
                                center: 'title',
                                right: 'today prev,next'
                            },
                            eventClick: $scope.alertOnEventClick,
                            eventDrop: $scope.alertOnDrop,
                            eventResize: $scope.alertOnResize,
                            eventRender: $scope.eventRender
                        }
                    };



                    $scope.eventSources = [$scope.events, $scope.eventsF];
                    //data is ready. Displaying calendar in view
                    $scope.readyToLoad = true;
                    setTimeout(function() {
                        $('.fc-day-grid-event .fc-time:contains("08:00")').parents(".fc-day-grid-event").css("background", "#68d068");
                        $('.fc-day-grid-event .fc-time:contains("09:00")').parents(".fc-day-grid-event").css("background", "#5cb85c");
                        $('.fc-day-grid-event .fc-time:contains("10:00")').parents(".fc-day-grid-event").css("background", "#51a251");
                        $('.fc-day-grid-event .fc-time:contains("11:00")').parents(".fc-day-grid-event").css("background", "#428242");
                        $('.fc-day-grid-event .fc-time:contains("12:00")').parents(".fc-day-grid-event").css("background", "#397539");
                        $('.fc-day-grid-event .fc-time:contains("13:00")').parents(".fc-day-grid-event").css("background", "#2e5f2e");
                        $('.fc-day-grid-event .fc-time:contains("14:00")').parents(".fc-day-grid-event").css("background", "#234823");
                        $('.fc-day-grid-event .fc-time:contains("15:00")').parents(".fc-day-grid-event").css("background", "#1c3a1c");
                        $('.fc-day-grid-event .fc-time:contains("16:00")').parents(".fc-day-grid-event").css("background", "#102110");
                        $('.fc-day-grid-event .fc-time:contains("17:00")').parents(".fc-day-grid-event").css("background", "#040604");
                    }, 300);


                }).error(function (data, status, headers, config) {
                    loader.classList.add("hide-loader");
                    if (data.error) {
                        window.location.href = "#/logout";
                    }
                });

                //collect data from calendar on click
                $scope.clickedEvent = false;
                $scope.alertOnEventClick = function (date, jsEvent, view) {
                    //$scope.alertMessage = (date.title + ' was clicked the date is' + date.start + "and id is" + date.id);
                    $scope.newAppointmentData.installer_id = date.id;
                    $scope.newAppointmentData.time_slot_id = date.time_slot_id;
                    $scope.newAppointmentData.appointment_date = date.appointment_date;
                    $scope.selectedEvent = "Selected appointment: " + date.title + ", date: " + date.appointment_date;
                    $scope.clickedEvent = true;
                    angular.element('.fc-day-grid-event').removeClass('selected-event');
                    angular.element(this).addClass('selected-event');
                    $http({
                        method: 'POST',
                        url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                        data: {
                            "method_name": "checkTimeSlotAvailability",
                            "time_slot_id": date.time_slot_id,
                            "appointment_date": date.appointment_date,
                            "installer_id": date.id

                        }
                    }).success(function (data) {
                        if (data.time_slot_available == "no") {
                            $scope.newAppointmentMessage = data.response_message;
                            $('#appointment-modal').modal('show');

                            setTimeout(function () {
                                location.reload();
                            }, 300)
                        }
                    }).error(function (data) {
                        if (data.error) {
                            window.location = "#/logout"
                        }
                    })
                };

                $scope.hideEventAlert = function () {
                    $scope.clickedEvent = false;
                };


                //get address based on postcode
                $scope.getAddressList = function () {
                    loader.classList.remove("hide-loader");
                    $http({
                        method: 'POST',
                        url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                        data: {
                            method_name: "getAddressDetailsByPostCode",
                            post_code: $scope.newAppointmentData.postcode

                        }
                    }).success(function (data) {
                        loader.classList.add("hide-loader");
                        if (data.response_code == FAILURE_CODE) {
                            window.location = "#/logout"
                        }
                        ;
                        if (data.response_code == SUCCESS_CODE) {                            
                            $scope.lists = data['data'];
                            $scope.fullAddresses = AppointmentFactory.listOfFullAddresses($scope.lists);
                            $scope.focus = true;
                            $scope.modifyData = true;
                            $scope.disabledField = true;
                            //hide postcode error if postcode found
                            $scope.postcodeAddressNot = false;
                            $scope.newAppointmentData.modify_existing_details = 0;
                        } else if (data.response_code == NOT_FOUND_CODE) {
                            $scope.postcodeAddressNot = true;
                            $scope.newAppointmentData.modify_existing_details = 1;
                            $scope.disabledField = false;
                            $scope.modifyData = false;
                        } else if (data.response_code == FAILURE_CODE) {
                            window.location.href = "#/logout"
                        }

                    }).error(function (data) {
                        loader.classList.add("hide-loader");
                        if (data.error) {
                            window.location = "#/logout"
                        }
                        ;
                    });
                };



                //autocomplete address from select
                $scope.addAddress = function (addressId) {
                    var foundObject = ($filter('filter')($scope.lists, {address_id: addressId}))[0];
                    $scope.newAppointmentData.address_1 = foundObject.address_1;
                    $scope.newAppointmentData.address_2 = foundObject.address_2;
                    $scope.newAppointmentData.address_3 = foundObject.address_3;
                    $scope.newAppointmentData.address_4 = foundObject.address_4;
                    $scope.newAppointmentData.address_5 = foundObject.address_5;
                    $scope.newAppointmentData.postcode = foundObject.postcode;
                    $scope.newAppointmentData.property_number = foundObject.property_number;
                    $scope.newAppointmentData.address_id = foundObject.address_id;
                    $scope.newAppointmentData.customer_name_client = foundObject.customer_name_client;
                    $scope.newAppointmentData.customer_reference_client = foundObject.customer_reference_client;
                    $scope.newAppointmentData.customer_id = foundObject.customer_id;
                    $scope.newAppointmentData.title = foundObject.title;
                    
                    //Author: Mohtadi Bakali Tahiri
                    // 19/09/2016
                    // If the customer email or Mobile Number or Phone number fields are null we will make them active
                    
                    if ( foundObject.postcode != null &&  foundObject.email_id != null && foundObject.email_id.length < 4  || foundObject.mobile_number.length < 4 || foundObject.telephone_number.length < 4 || foundObject.title.length < 1 ||
                        foundObject.first_name.length < 1 || foundObject.last_name.length < 1  || foundObject.property_number.length < 1 || foundObject.address_id.length < 1 ){
                        $scope.newAppointmentData.modify_existing_details = 1;
                        $scope.disabledField = false;      
                        $scope.modify_existing_details = 1;   
                                             }    


                    $scope.newAppointmentData.first_name        = foundObject.first_name;
                    $scope.newAppointmentData.last_name         = foundObject.last_name;
                    $scope.newAppointmentData.email_id          = foundObject.email_id;


                    $scope.focus = false;
                };

                //get provider list
                ProviderListFactory.async().success(function (data) {
                    if (data.response_code == FAILURE_CODE) {
                        window.location.href = "#/logout"
                    }
                    ;
                    $scope.providers = data['data'];
                }).error(function (data) {
                    if (data.error) {
                        window.location.href = "#/logout"
                    } ;

                });

                //get category list 
                CategoryListFactory.async().success(function (data) {
                    if (data.response_code == FAILURE_CODE) {
                        window.location.href = "#/logout"
                    }
                    ;
                    $scope.categories = data['data'];
                }).error(function (data) {
                    if (data.error) {
                        window.location.href = "#/logout"
                    }
                    ;
                });

                //object for creating new appointment


                $scope.newAppointmentData = {
                    method_name: "addAppointment",
                    user_id: localStorage.getItem('user_id'),
                    reminder_call_required:'No'
                };

                $scope.disabledField = true;
                $scope.newAppointmentData.modify_existing_details = 0;
                $scope.modifyCustomerData = function () {
                    $scope.disabledField = !$scope.disabledField;
                    $scope.newAppointmentData.modify_existing_details = $scope.newAppointmentData.modify_existing_details === 0 ? 1 : 0;
                };

                //get current date time (yyyy-mm-dd h:mm:ss)
                var currentDate = new Date();

                //********************************************************************************
                //        Depen de value of call back flag we modifying the object request
                //********************************************************************************

                if ($scope.callback_status == false) {

                    $scope.newAppointmentData.status = "Booked";
                    $scope.newAppointmentData.created_date =
                            currentDate.getFullYear() + '-'
                            + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-'
                            + ('0' + currentDate.getDate()).slice(-2) + " "
                            + ('0' + currentDate.getHours()).slice(-2) + ":"
                            + ('0' + currentDate.getMinutes()).slice(-2) + ":"
                            + ('0' + currentDate.getSeconds()).slice(-2);                    
                }
                //********************************************************************************
                //********************************************************************************

                $scope.notOver16 = false;
                $scope.over16Check = function () {
                    if ($scope.newAppointmentData.over_16_and_bill_payer == 1) {
                        $scope.notOver16 = true
                    } else {
                        $scope.notOver16 = false;
                    }

                };

                $scope.newAppointment = function () {
                    if ($scope.newAppointmentData.over_16_and_bill_payer) {
                        $scope.notOver16 = false;
                    } else {
                        $scope.notOver16 = true;
                    }

                    $scope.submitted = true;
                    //Recolenting data from form
                    $scope.newAppointmentData.title                     = $scope.newAppointmentData.title;
                    $scope.newAppointmentData.first_name                = $scope.newAppointmentData.first_name;
                    $scope.newAppointmentData.last_name                 = $scope.newAppointmentData.last_name;
                    // $scope.newAppointmentData.modify_existing_details  			= parseInt($scope.newAppointmentData.modify_existing_details  ? 1:0);
                    //Address Details
                    $scope.newAppointmentData.postcode                  = $scope.newAppointmentData.postcode;
                    $scope.newAppointmentData.property_number           = $scope.newAppointmentData.property_number;
                    $scope.newAppointmentData.address_1                 = $scope.newAppointmentData.address_1;
                    $scope.newAppointmentData.address_2                 = $scope.newAppointmentData.address_2;
                    $scope.newAppointmentData.address_3                 = $scope.newAppointmentData.address_3;
                    $scope.newAppointmentData.address_4                 = $scope.newAppointmentData.address_4;
                    $scope.newAppointmentData.address_5                 = $scope.newAppointmentData.address_5;
                    $scope.newAppointmentData.provider_id               = parseInt(PROVIDER_ID);
                    $scope.newAppointmentData.contact_preference        = $scope.newAppointmentData.contact_preference;
                    $scope.newAppointmentData.email_id                  = $scope.newAppointmentData.email_id;
                    $scope.newAppointmentData.mobile_number             = $scope.newAppointmentData.mobile_number;
                    $scope.newAppointmentData.telephone_number          = $scope.newAppointmentData.telephone_number;
                    $scope.newAppointmentData.reminder_call_required    = $scope.newAppointmentData.reminder_call_required ? 'Yes' : 'No';

                    $scope.newAppointmentData.over_16_and_bill_payer    = parseInt($scope.newAppointmentData.over_16_and_bill_payer ? 1 : 0);
                    $scope.newAppointmentData.how_heard_primary         = $scope.newAppointmentData.how_heard_primary;
                    $scope.newAppointmentData.channel                   = $scope.newAppointmentData.channel;

                    if ($scope.newAppointmentData.customer_reference_client == undefined) {
                        $scope.newAppointmentData.customer_reference_client = null;
                    }

                    if ($scope.newAppointmentData.customer_id == undefined) {
                        $scope.newAppointmentData.customer_id = 0;
                    }
                    if ($scope.newAppointmentData.address_id == undefined) {
                        $scope.newAppointmentData.address_id = 0;
                    }
                    if ($scope.newAppointmentData.address_2 == undefined) {
                        $scope.newAppointmentData.address_2 = null;
                    }
                    if ($scope.newAppointmentData.address_3 == undefined) {
                        $scope.newAppointmentData.address_3 = null;
                    }
                    if ($scope.newAppointmentData.address_4 == undefined) {
                        $scope.newAppointmentData.address_4 = null;
                    }
                    if ($scope.newAppointmentData.email_id == undefined) {
                        $scope.newAppointmentData.email_id = null;
                    }
                                        
                    if ($scope.newAppointmentData.mobile_number == undefined) {
                        $scope.newAppointmentData.mobile_number = null;
                    }

                    if ($scope.newAppointmentData.contact_preference == undefined) {
                        $scope.newAppointmentData.contact_preference = "Landline";
                    }

                    if ($scope.newAppointmentData.modify_existing_details == undefined) {
                        $scope.newAppointmentData.modify_existing_details = 0;
                    }

                    if ($scope.newAppointmentData.security_password == undefined) {
                        $scope.newAppointmentData.security_password = null;
                    } else {
                        $scope.newAppointmentData.security_password = $scope.newAppointmentData.security_password;
                    }

                    if ($scope.newAppointmentData.scheduler_comments == undefined) {
                        $scope.newAppointmentData.scheduler_comments = null;
                    } else {
                        $scope.newAppointmentData.scheduler_comments = $scope.newAppointmentData.scheduler_comments;
                    }

                    if ($scope.newAppointmentData.how_heard_secondary == undefined) {
                        $scope.newAppointmentData.how_heard_secondary = null;
                    } else {
                        $scope.newAppointmentData.how_heard_secondary = $scope.newAppointmentData.how_heard_secondary;
                    }
                    $scope.appointmentData = JSON.stringify($scope.newAppointmentData);

                    console.log($scope.appointmentData = JSON.stringify($scope.newAppointmentData));

                    if ($scope.newAppointmentForm.$valid) 
                    {
                        $scope.show_confirm_appointment_button = false;
                        $scope.show_confirming_appointment_button = true;
                        $scope.new_appointment_error_message = false;
                        
                        $http({
                            method: 'POST',
                            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                            data: $scope.appointmentData,
                            headers: {'Content-Type': 'application/json'}
                        }).success(function (data) {
                            if (data.response_code == SUCCESS_CODE) {
                                $scope.newAppointmentMessage = data.response_message;
                                window.location = '#/appointments';
                            } else if (data.response_code == FAILURE_CODE) {
                                $scope.newAppointmentMessage = data.response_message;
                                $('#appointment-modal').modal('show');
                                
                                $scope.show_confirm_appointment_button = true;
                                $scope.show_confirming_appointment_button = false;
                                $scope.new_appointment_error_message = false;
                            }
                        }).error(function (data) 
                        {
                            if (data.error) {
                                window.location = "#/logout";
                            };
                        });
                    }
                    else
                    {
                        $window.scrollTo(0,0);
                    };
                };


                //new product
                var uploadedCount = 0;
                $scope.files = [];


                $scope.newProduct = function () {

                    if ($scope.productCanBe === 'InstalledORLeft')
                    {
                        $scope.productCanBe = 'Installed OR Left';
                    }
                    $scope.newProductData = {
                        "method_name": "addData",
                        "category_id": parseInt($scope.category, 10),
                        "swsm_id": parseInt($scope.swsmId, 10),
                        "product_status": $scope.status,
                        "product_name": $scope.productName,
                        "product_description": $scope.productDescription,
                        "max_quantity": parseInt($scope.maxQuantity, 10),
                        "stock_quantity": parseInt($scope.productStock, 10),
                        "reporting_name": $scope.reportingName,
                        "product_can_be": $scope.productCanBe,
                        "user_id": parseInt(localStorage.getItem('user_id'), 10),
                        "provider_id": PROVIDER_ID, //parseInt(localStorage.getItem('provider_id'), 10),
                        "money_saved": parseInt($scope.savedMoney, 10),
                        "water_saved": parseInt($scope.savedWater, 10),
                        "second_product_water_saved": parseInt($scope.second_product_water_saved, 10),
                        "third_product_water_saved": parseInt($scope.third_product_water_saved, 10),
                        "file": {
                            "filetype": $scope.files[0].filetype,
                            "filename": $scope.files[0].filename,
                            "filesize": $scope.files[0].filesize,
                            "image_base64_encrypted_string": $scope.files[0].base64
                        }
                    };



                    $http({
                        method: 'POST',
                        url: '/products/api?token=' + localStorage.getItem('userToken'),
                        data: $scope.newProductData,
                        headers: {'Content-Type': undefined}
                    }).success(function (data)
                    {

                        if (data.response_code == FAILURE_CODE) {
                            window.location.href = "#/logout"
                        }
                        ;
                        if (data.response_code == SUCCESS_CODE) {
                            window.location = '#/products';
                        } else if (data.response_code == FAILURE_CODE) {

                        }
                    }).error(function (data) {
                        if (data.error) {
                            window.location.href = "#/logout"
                        }
                        ;
                    })
                };

                // Create new Category
                // Mohtadi Bakali Tahiri  : 03/08/2016
                $scope.submitNewCategory = function () {
                    
                    if($scope.product_category_name === undefined || $scope.category_status === undefined)
                    {
                        $scope.show_error_message = true;
                    }
                    else
                    {
                        if($scope.max_savings === undefined)
                        {
                            $scope.max_savings = null;
                        }
                        $scope.show_error_message = false;
                        $scope.category_status = $scope.category_status;
                        $scope.category_name = $scope.product_category_name;
                        $scope.newCategoryData = {
                            "method_name": "addData",
                            "product_category_name": $scope.product_category_name,
                            "max_savings": $scope.max_savings,
                            "status": $scope.category_status
                        };

                        $scope.newCategoryData = JSON.stringify($scope.newCategoryData);
                        $http({
                            method: 'POST',
                            url: 'categories/api?token=' + localStorage.getItem('userToken'),
                            data: $scope.newCategoryData,
                            headers: {'Content-Type': 'application/json'}
                        }).success(function (data) {
                            if (data.response_code == FAILURE_CODE) {
                                window.location = "#/logout"
                            }
                            ;
                            if (data.response_code == SUCCESS_CODE) {
                                window.location = '#/categories';
                            } else if (data.response_code == FAILURE_CODE) {
                                window.location = "#/logout";
                            }
                        }).error(function (data) {
                            if (data.error) {
                                window.location = "#/logout";
                            }
                            ;
                        });
                    }
                };
                
                 
                $scope.contactPreferenceChanged = function () 
                {
                    if($scope.newAppointmentData.contact_preference === 'Text')
                    {
                        $scope.reminder_call_switch_class = 'true';
                        $scope.newAppointmentData.reminder_call_required  = 'No';
                        $scope.newAppointmentForm.email.required = 'false';
                        $scope.newAppointmentForm.mobile_number.required = 'true';




                    }
                    else if($scope.newAppointmentData.contact_preference === 'Email'){

                            $scope.reminder_call_switch_class = 'true';
                            $scope.newAppointmentData.reminder_call_required  = 'No';
                            $scope.newAppointmentForm.email.required = 'true';
                            $scope.newAppointmentForm.mobile_number.required = 'false';

                    }
                    else
                    {
                        $scope.reminder_call_switch_class = 'true';
                        $scope.newAppointmentData.reminder_call_required  = 'Yes';
                    }
                };

            }]);
