hwecApp.controller('editAppointmentController', ['$APPOINTMENT_TIME_DETAIL','$window','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', function ($APPOINTMENT_TIME_DETAIL,$window,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH)
    {

        $scope.setCanceledReason = function() {
            if($scope.scheduler_comments != null  &&  $scope.status === 'Cancelled'){
               return true;
            }else {
                return false;
            }
        };
  

        var loader = document.getElementById("loading-content");
        loader.classList.add("hide-loader");
        
        // Start : load "how heard primary" options by provider -- //
        $scope.provider_details = null;
        $scope.how_heard_primary_options = null;
 
        $http({
            method: 'POST',
            url: 'providers/api?token=' + localStorage.getItem('userToken'),
            data: '{"method_name":"getProviderConfigDetails"}',
            headers: {'Content-Type': 'application/json'}
        }) 
        .success(function (data) 
        {
            if (data.response_code === SUCCESS_CODE) 
            {
                $scope.provider_details = data;
                $scope.how_heard_primary_options = data.HOW_HEARD_PRIMARY_DROPDOWN_OPTIONS;
            }
            else if (data.response_code === FAILURE_CODE) 
            {
                window.location = "#/logout";
            }
        })
        .error(function (data) 
        {
            if (data.error) 
            {
                window.location = "#/logout";
            }
        });
        // End : load "how heard primary" options by provider -- //
                
        $scope.hideNewBtn = false;
        $scope.curentUrl = $location.path().replace('/', "");

        $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);
        $scope.baseUrl = $scope.curentUrl.slice(0, -4);
        $scope.pageTitle = "Edit " + $scope.baseUrl;
        $scope.time_slot_selected_error = false;
        $scope.disabledField = true;
        
        $scope.newly_selected_time_slot_id = 0;
        $scope.newly_selected_time_appointment_date = 0;
                        
        $scope.updated_data = [];
        $scope.old_time_slot_id = 0;
        $scope.old_time_appointment_date = 0;
        $scope.old_status = "";
        
        $scope.loading_button = false;
        $scope.confirm_button = true;
        
        $scope.display_previous_time_slot_details = false;
        
        $scope.pageTitle = "Edit appointment";
        
        $scope.status_to_compare = "";
        
        angular.element('.fc-month-button').click();
        // start : load existing details -- PRIYA BHOJANI -- //
        
        $scope.itemId                = $location.path().substr($location.path().lastIndexOf('/') + 1);
        $scope.getAppointmentDetails = 
        {
            "method_name": "viewData",
            "appointment_id": $scope.itemId
        };
        
        $http
        ({
            method: 'POST',
            url: '/appointments/api?token=' + localStorage.getItem('userToken'),
            data: $scope.getAppointmentDetails,
            headers: {'Content-Type': undefined}
        }).success(function(data) 
        {
            if(data.response_code === SUCCESS_CODE) 
            {

                $scope.scheduler_comments = data.data["scheduler_comments"];
                $scope.status = data.data["status"];
                
                $scope.updated_data = data.data;
                $scope.updated_data.user_id = localStorage.getItem('user_id');
                $scope.updated_data.reminder_call_required = 'Yes';
                $scope.status_to_compare = data.data['status'];                
                
                $scope.newAppointmentData = data.data;                
                $scope.newAppointmentData.title = $scope.newAppointmentData.customer_title;                 
                $scope.notOver16 = false;
                $scope.newAppointmentData.over_16_and_bill_payer = 1;                
                var selected_channel = $scope.newAppointmentData.channel;
                //document.getElementById(selected_channel).selected = "true";
                
                var selected_status = ($scope.newAppointmentData.status.replace(" ","-"));
                
                // Start : check if our drop-down has the status , and if it has , make it selected -- PRIYA BHOJANI -- //
                if(document.getElementById(selected_status) !== null)
                {
                    document.getElementById(selected_status).selected = "true";
                }
                // End : check if our drop-down has the status , and if it has , make it selected -- PRIYA BHOJANI -- //
                    


                
                while($scope.newAppointmentData.customer_mobile_number.charAt(0) === '0')
                {
                    $scope.newAppointmentData.customer_mobile_number = $scope.newAppointmentData.customer_mobile_number.substr(1);
                }
                    

                $scope.newAppointmentData.customer_telephone_number = $scope.newAppointmentData.customer_telephone_number.replace(" ","");
                
                $scope.old_status = $scope.newAppointmentData.status;
                
                if($scope.newAppointmentData.status === 'Booked')
                {
                    $scope.old_time_slot_id = data.data.time_slot_id;
                    $scope.old_time_appointment_date = data.data.appointment_date;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                }
                else if($scope.newAppointmentData.status === 'Re-Booked 1')
                {
                    $scope.old_time_slot_id = data.data.time_slot_1;
                    $scope.old_time_appointment_date = data.data.appointment_date_1;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                }
                else if($scope.newAppointmentData.status === 'Re-Booked 2')
                {
                    $scope.old_time_slot_id = data.data.time_slot_2;
                    $scope.old_time_appointment_date = data.data.appointment_date_2;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                }
                else if($scope.newAppointmentData.status === 'Re-Booked 3')
                {
                    $scope.old_time_slot_id = data.data.time_slot_3;
                    $scope.old_time_appointment_date = data.data.appointment_date_3;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                }
                else if($scope.newAppointmentData.status === 'Re-Booked 4')
                {
                    $scope.old_time_slot_id = data.data.time_slot_4;
                    $scope.old_time_appointment_date = data.data.appointment_date_4;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                }
                else if($scope.newAppointmentData.status === 'Re-Booked 5')
                {
                    $scope.old_time_slot_id = data.data.time_slot_5;
                    $scope.old_time_appointment_date = data.data.appointment_date_5;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                }
                else if($scope.newAppointmentData.status === 'Pending_1')
                {
                    $scope.old_time_slot_id = data.data.time_slot_id;
                    $scope.old_time_appointment_date = data.data.appointment_date;
                    $scope.display_previous_time_slot_details = true;
                    //$scope.newAppointmentData.status = "";
                } 
                
                if($scope.old_time_slot_id)
                {
                    $scope.time_slot_to_display = $APPOINTMENT_TIME_DETAIL[parseInt($scope.old_time_slot_id)].replace(":00:00",":00");
                }
        
                
                $scope.newAppointmentData.modify_existing_details = 0;
                $scope.modifyData = true;
                $scope.user_id = localStorage.getItem('user_id');
                $scope.reminder_call_required = 'Yes';
            }
        })
        .error(function(data) 
        {
            if(data.error) { window.location = "#/logout" };
        });
        
        // end : load existing details -- PRIYA BHOJANI -- //
        
        
        
        
        
        $scope.over16Check = function () {
                    if ($scope.newAppointmentData.over_16_and_bill_payer == 1) {
                        $scope.notOver16 = true
                    } else {
                        $scope.notOver16 = false;
                    }

                };
                

        
        // start : load calendar -- PRIYA BHOJANI -- //
        $scope.events = [];
        $scope.readyToLoad = false;
        $http({
            method: 'POST',
            url: '/installers/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "getInstallerAvailability"},
            headers: {'Content-Type': 'application/json'}
        }).success(function (data, status, headers, config) {
            if (data.response_code == FAILURE_CODE) {
                window.location = "#/logout"
            }
            ;
            angular.forEach(data["data"], function (value, index) {
                //object to be displayed in the calendar
                $scope.events.push({
                    id: value['installer_id'],
                    title: value['installer_name'] + " : " + value['work_area_postcode'],
                    start: value['available_date'] + $TIME_SLOT[value['available_time_slot_id']],
                    appointment_date: value['available_date'],
                    time_slot_id: value['available_time_slot_id'],
                    allDay: false,
                    stick: true
                });
            }); //end foreach

            /* event source that calls a function on every view switch */
            $scope.eventsF = function (start, end, timezone, callback) {
                var s = new Date(start).getTime() / 1000;
                var e = new Date(end).getTime() / 1000;
                var m = new Date(start).getMonth();
                var events = [{title: 'Feed Me ' + m, start: s + (50000), end: s + (100000), allDay: false, className: ['customFeed']}];
                callback(events);
            };

            /* alert on Drop */
            $scope.alertOnDrop = function (event, delta, revertFunc, jsEvent, ui, view) {
                $scope.alertMessage = ('Event Droped to make dayDelta ' + delta);
            };
            /* alert on Resize */
            $scope.alertOnResize = function (event, delta, revertFunc, jsEvent, ui, view) {
                $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
            };
            /* add and removes an event source of choice */
            $scope.addRemoveEventSource = function (sources, source) {
                var canAdd = 0;
                angular.forEach(sources, function (value, key) {
                    if (sources[key] === source) {
                        sources.splice(key, 1);
                        canAdd = 1;
                    }
                });
                if (canAdd === 0) {
                    sources.push(source);
                }
            };
            /* add custom event*/
            $scope.addEvent = function () {
                $scope.events.push({
                    title: 'Open Sesame',
                    start: new Date(y, m, 28),
                    end: new Date(y, m, 29),
                    className: ['openSesame']
                });
            };


            /* remove event */
            $scope.remove = function (index) {
                $scope.events.splice(index, 1);
            };
            /* Change View */
            $scope.changeView = function (view, calendar) {
                uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
            };
            /* Change View */
            $scope.renderCalender = function (calendar) {
                if (uiCalendarConfig.calendars[calendar]) {
                    uiCalendarConfig.calendars[calendar].fullCalendar('render');
                }
            };

            // config calendar
            $scope.uiConfig = {
                calendar: {
                    timeFormat: 'HH:mm',
                    height: 600,
                    editable: false,
                    firstDay: 1,
                    header: {
                        left: 'month basicWeek basicDay',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    eventClick: $scope.alertOnEventClick,
                    eventDrop: $scope.alertOnDrop,
                    eventResize: $scope.alertOnResize,
                    eventRender: $scope.eventRender
                }
            };



            $scope.eventSources = [$scope.events, $scope.eventsF];
            //data is ready. Displaying calendar in view
            $scope.readyToLoad = true;
            setTimeout(function() {
                $('.fc-day-grid-event .fc-time:contains("08:00")').parents(".fc-day-grid-event").css("background", "#68d068");
                $('.fc-day-grid-event .fc-time:contains("09:00")').parents(".fc-day-grid-event").css("background", "#5cb85c");
                $('.fc-day-grid-event .fc-time:contains("10:00")').parents(".fc-day-grid-event").css("background", "#51a251");
                $('.fc-day-grid-event .fc-time:contains("11:00")').parents(".fc-day-grid-event").css("background", "#428242");
                $('.fc-day-grid-event .fc-time:contains("12:00")').parents(".fc-day-grid-event").css("background", "#397539");
                $('.fc-day-grid-event .fc-time:contains("13:00")').parents(".fc-day-grid-event").css("background", "#2e5f2e");
                $('.fc-day-grid-event .fc-time:contains("14:00")').parents(".fc-day-grid-event").css("background", "#234823");
                $('.fc-day-grid-event .fc-time:contains("15:00")').parents(".fc-day-grid-event").css("background", "#1c3a1c");
                $('.fc-day-grid-event .fc-time:contains("16:00")').parents(".fc-day-grid-event").css("background", "#102110");
                $('.fc-day-grid-event .fc-time:contains("17:00")').parents(".fc-day-grid-event").css("background", "#040604");
            }, 300);

        }).error(function (data, status, headers, config) {
            if (data.error) {
                window.location = "#/logout";
            }
        });
        
                //collect data from calendar on click
                $scope.clickedEvent = false;
                $scope.alertOnEventClick = function (date, jsEvent, view) {
                    angular.element('#time_slot_selected_error').html("");
                    angular.element('#time_slot_selected_error').hide();
                    //$scope.alertMessage = (date.title + ' was clicked the date is' + date.start + "and id is" + date.id);
                    $scope.newAppointmentData.installer_id = date.id;
                    $scope.newAppointmentData.time_slot_id = date.time_slot_id;
                    
                    //$scope.newAppointmentData.appointment_date = date.appointment_date;
                    
                    if($scope.newAppointmentData.status === 'Pending_1')
                    {
                        $scope.newAppointmentData.status = $scope.newAppointmentData.status;
                    }
                    else
                    {
                        $scope.newAppointmentData.status = 'Booked';
                    }
                    
                    $scope.newly_selected_time_slot_id = date.time_slot_id;
                    $scope.newly_selected_time_appointment_date = date.appointment_date;
                    $scope.selectedEvent = "Selected appointment: Postcode : " + date.title + ", Date: " + date.appointment_date + " at " + $APPOINTMENT_TIME_DETAIL[parseInt($scope.newly_selected_time_slot_id)].replace(":00:00",":00");
                    $scope.clickedEvent = true;
                    angular.element('.fc-day-grid-event').removeClass('selected-event');
                    angular.element(this).addClass('selected-event');
                    angular.element(this).addClass('selected_time_slot');
                    $http({
                        method: 'POST',
                        url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                        data: {
                            "method_name": "checkTimeSlotAvailability",
                            "time_slot_id": date.time_slot_id,
                            "appointment_date": date.appointment_date,
                            "installer_id": date.id

                        }
                    }).success(function (data) {
                        if (data.time_slot_available == "no") {
                            $scope.newAppointmentMessage = data.response_message;
                            $('#appointment-modal').modal('show');

                            setTimeout(function () {
                                location.reload();
                            }, 300)
                        }
                    }).error(function (data) {
                        if (data.error) {
                            window.location = "#/logout"
                        }
                    })
                };

                $scope.hideEventAlert = function () {
                    $scope.clickedEvent = false;
                };

        // end : load calendar -- PRIYA BHOJANI -- //
        
        
        
        
        
        
        // start : get address details by postcode -- //
       
        $scope.getAdressList = function () 
        {
            $http
            ({
                method: 'POST',
                url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                data: {
                    method_name: "getAddressDetailsByPostCode",
                    post_code: $scope.newAppointmentData.postcode

                }
            }).success(function (data) 
            {
                if (data.response_code == FAILURE_CODE) {
                    window.location = "#/logout"
                }
                ;
                if (data.response_code == SUCCESS_CODE) {
                    $scope.lists = data['data'];
                    $scope.fullAddresses = AppointmentFactory.listOfFullAddresses($scope.lists);
                    $scope.focus = true;
                    $scope.modifyData = true;
                    $scope.disabledField = true;
                    //hide postcode error if postcode found
                    $scope.postcodeAdressNot = false;
                    $scope.newAppointmentData.modify_existing_details = 0;
                } else if (data.response_code == NOT_FOUND_CODE) {
                    $scope.postcodeAdressNot = true;
                    $scope.newAppointmentData.modify_existing_details = 1;
                    $scope.disabledField = false;
                    $scope.modifyData = false;
                } else if (data.response_code == FAILURE_CODE) {
                    window.location = "#/logout"
                }

            }).error(function (data) {
                if (data.error) {
                    window.location = "#/logout"
                }
                ;
            });
        };
        
        // end : get address details by postcode -- //
        
        
        
        
        // start : add address from selection -- //        
        
        $scope.addAdress = function (addressId) {
                    var foundObject = ($filter('filter')($scope.lists, {address_id: addressId}))[0];                    
                    $scope.newAppointmentData.address_1 = foundObject.address_1;
                    $scope.newAppointmentData.address_2 = foundObject.address_2;
                    $scope.newAppointmentData.address_3 = foundObject.address_3;
                    $scope.newAppointmentData.address_4 = foundObject.address_4;
                    $scope.newAppointmentData.address_5 = foundObject.address_5;
                    $scope.newAppointmentData.postcode = foundObject.postcode;
                    $scope.newAppointmentData.property_number = foundObject.property_number;
                    $scope.newAppointmentData.address_id = foundObject.address_id;
                    $scope.newAppointmentData.customer_name_client = foundObject.customer_name_client;
                    $scope.newAppointmentData.customer_reference_client = foundObject.customer_reference_client;
                    $scope.newAppointmentData.customer_id = foundObject.customer_id;
                    $scope.newAppointmentData.title = foundObject.title;
                    
                    $scope.newAppointmentData.first_name = foundObject.first_name;
                    $scope.newAppointmentData.last_name = foundObject.last_name;
                    $scope.newAppointmentData.email_id = foundObject.email_id;
                    $scope.newAppointmentData.mobile_number = foundObject.mobile_number;
                    $scope.newAppointmentData.telephone_number = foundObject.telephone_number;                    
                    $scope.focus = false;
                };
                
        // start : add address from selection -- //
        
        
        
        // start : click on "Modify existing details"
        
        $scope.modifyCustomerData = function () 
        {
            $scope.disabledField = !$scope.disabledField;
            $scope.newAppointmentData.modify_existing_details = $scope.newAppointmentData.modify_existing_details === 0 ? 1 : 0;
        };
        
        // end : click on "Modify existing details"
        
        
        
        
        // start : edit appointment -- PRIYA BHOJANI -- //
        
        $scope.editAppointment = function ()
        {
            $scope.submitted = true;
            setTimeout(function()
            {
                if(angular.element('.text-danger').is(':visible'))
                {
                    if($scope.newAppointmentData.status === "Booked" && $scope.newly_selected_time_appointment_date === 0)
                    {
                        angular.element('#time_slot_selected_error').html("<font color='red'>Select time-slot</font>");
                        $scope.time_slot_selected_error = true;

                        $scope.confirm_button = true;
                        $scope.loading_button = false;
                    }
                    else
                    {
                        $scope.confirm_button = true;
                        $scope.loading_button = false;
                    }
                }        
                else if(!angular.element('.text-danger').is(':visible'))
                {   
                    $scope.confirm_button = false;
                    $scope.loading_button = true;
                    
                    // Start : define the status dependent on selected date and time  -- PRIYA BHOJANI -- //
                    
                    if($scope.newAppointmentData.status !== "Pending_1" && $scope.newAppointmentData.status !== "Cancelled" && $scope.newAppointmentData.status !== "Call Back" && $scope.newly_selected_time_appointment_date !== 0)
                    {
                        if(($scope.newly_selected_time_appointment_date !== $scope.old_time_appointment_date) || ($scope.newly_selected_time_slot_id !== $scope.updated_data.old_time_slot_id))
                        {
                            // Start : used when previous status was call back and now it is booked -- PRIYA BHOJANI -- //
                            if(!$scope.newAppointmentData.appointment_date)
                            {
                                $scope.newAppointmentData.status = "Booked";
                                $scope.newAppointmentData.time_slot_id = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_time = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_date = $scope.newly_selected_time_appointment_date;
                            }
                            // End : used when previous status was call back and now it is booked -- PRIYA BHOJANI -- //
                            else if(!$scope.newAppointmentData.appointment_date_1)
                            {
                                $scope.newAppointmentData.status = "Re-Booked 1";
                                $scope.newAppointmentData.time_slot_1 = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_date_1 = $scope.newly_selected_time_appointment_date;
                            }

                            else if(!$scope.newAppointmentData.appointment_date_2)
                            {
                                $scope.newAppointmentData.status = "Re-Booked 2";
                                $scope.newAppointmentData.time_slot_2 = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_date_2 = $scope.newly_selected_time_appointment_date;
                            }                

                            else if(!$scope.newAppointmentData.appointment_date_3)
                            {
                                $scope.newAppointmentData.status = "Re-Booked 3";
                                $scope.newAppointmentData.time_slot_3 = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_date_3 = $scope.newly_selected_time_appointment_date;
                            }

                            else if(!$scope.newAppointmentData.appointment_date_4)
                            {
                                $scope.newAppointmentData.status = "Re-Booked 4";                    
                                $scope.newAppointmentData.time_slot_4 = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_date_4 = $scope.newly_selected_time_appointment_date;
                            }

                            else if(!$scope.newAppointmentData.appointment_date_5)
                            {
                                $scope.newAppointmentData.status = "Re-Booked 5";
                                $scope.newAppointmentData.time_slot_5 = $scope.newly_selected_time_slot_id;
                                $scope.newAppointmentData.appointment_date_5 = $scope.newly_selected_time_appointment_date;
                            }
                        }
                    }
                    else if($scope.newAppointmentData.status === "Pending_1" && $scope.newly_selected_time_appointment_date !== 0)
                    {
                        if(($scope.newly_selected_time_appointment_date !== $scope.old_time_appointment_date) || ($scope.newly_selected_time_slot_id !== $scope.updated_data.old_time_slot_id))
                        {
                            $scope.newAppointmentData.status = "Booked";
                            $scope.newAppointmentData.time_slot_id = $scope.newly_selected_time_slot_id;
                            $scope.newAppointmentData.appointment_time = $scope.newly_selected_time_slot_id;
                            $scope.newAppointmentData.appointment_date = $scope.newly_selected_time_appointment_date;
                        }
                    }
                    // End : define the status dependent on selected date and time  -- PRIYA BHOJANI -- //
                    
                    $scope.newAppointmentData.method_name = "editAppointment";
                    $scope.newAppointmentData.customer_title = $scope.newAppointmentData.title;            

                    // Start : set status to old status if no any status is selected -- PRIYA BHOJANI -- //
                    if($scope.newAppointmentData.status === undefined || $scope.newAppointmentData.status === "")
                    {
                        $scope.newAppointmentData.status = $scope.old_status;
                    }
                    // End : set status to old status if no any status is selected -- PRIYA BHOJANI -- //
                    
                    $scope.newAppointmentData.modify_existing_details = $scope.modify_existing_details;
                                        
                    $scope.newAppointmentData.status_to_compare = $scope.status_to_compare;
                    //console.log($scope.newAppointmentData);
                    
                    $http
                    ({
                        method: 'POST',
                        url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                        data: $scope.newAppointmentData,
                        headers: {'Content-Type': 'application/json'}
                    }).success(function(data) {                
                        if(data.response_code == SUCCESS_CODE) {
                            $scope.newAppointmentMessage = data.response_message;
                            window.location = '#/appointments';
                        }else if(data.response_code == FAILURE_CODE) {
                            $scope.confirm_button = true;
                            $scope.loading_button = false;
                            $scope.newAppointmentMessage = data.response_message;
                            $('#appointment-modal').modal('show');
                        }
                    }).error(function(data) {                 
                        if(data.error) { window.location = "#/logout" };
                    });
                }
            },2000);
        };
        
        // end : edit appointment -- PRIYA BHOJANI -- //


        // Start : if appointment status is "Booked" show calendar -- // 
        $scope.appointmentStatusChanged = function ()
        {
            if($scope.newAppointmentData.status === "Booked")
            {
                setTimeout(function()
                {
                    angular.element('.fc-month-button').click();
                }, 1000);
            }
        };
        // End : if appointment status is "Booked" show calendar -- //

    }]);

