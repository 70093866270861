hwecApp.factory('AppointmentFactory', function() {

	var AppointmentFactory = {
 
	listOfFullAddresses:function(jsonObjects) {
	 	var tempList = {};
	 	angular.forEach(jsonObjects, function(value, index) {
	 		var fullAdress = value["property_number"] +", " + value["address_1"];
            
            // Start : check if values are null -- PRIYA BHOJANI -- //
            if(value["address_2"])
            {
                fullAdress += ", "+ value["address_2"];
            }
            else if(value["address_3"])
            {
                fullAdress += ", "+ value["address_3"];
            }
            else if(value["address_4"])
            {
                fullAdress += ", "+ value["address_4"];
            }
            // End : check if values are null -- PRIYA BHOJANI -- //
            
            fullAdress += ", "+ value["address_5"] +", "+ value["postcode"];
	 		var key = value["address_id"];
	 		tempList[key] = fullAdress;
	 	});
	 	return tempList; 
	 } 
	}
	return AppointmentFactory
});