hwecApp.controller('installerController', ['$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', function ($filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH) {




        var loader = document.getElementById("loading-content");
        loader.classList.remove("hide-loader");
 
        $scope.paginatePage = true;
        $scope.hideNewBtn = false;
        $scope.curentUrl = $location.path().replace('/', "");
        $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);

        $scope.pageTitle = $scope.curentUrl.slice(0, -1) + " list";
 
        $scope.pageTitle = "Manage installer schedule";
        $scope.backLink = '#/';
        $scope.pageNumber = 1;
        $scope.lastPage = false;
        $scope.firstPage = false;

        $scope.selected_installers = [];
        $scope.revert_data = [];
        angular.element('.installer_post_code_table').hide();
        
        // Start : pre-load all installer in multi-select box -- //
        $http({
            method: 'POST',
            url: '/installers/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "listData"},
            headers: {'Content-Type': 'application/json'}
        }).then(function successCallback(response)
        {
            loader.classList.add("hide-loader");
            $scope.installer_list = response['data'];

            if ($scope.installer_list.response_code === FAILURE_CODE)
            {
                window.location.href = "#/logout";
            }
            ;
            $scope.installer_list = $scope.installer_list['data'];
        }, function errorCallback(response) {
            window.location.href = "#/logout";
        });
        // End : pre-load all installer in multi-select box -- //

        var selected_installers = [];
        $scope.addPostCodeRow = function (user_id, installer_name, index)
        {
            angular.element('.installer_post_code_table').show();
            if (angular.element('#installer_' + user_id + '_postcode').length === 0)
            {
                angular.element('#td_installer_' + user_id).addClass("selected_installer");
                angular.element('#td_installer_' + user_id).css("background-color", "#E6E6E6");

                // Start : add postcode box for selected installer -- PRIYA BHOJANI -- //
                $scope.selected_installers.push({'user_id': user_id, 'installer_name': installer_name});
                selected_installers.push({'user_id': user_id, 'installer_name': installer_name});                
                // End : add postcode box for selected installer -- PRIYA BHOJANI -- //
            } else
            {                
                if (angular.element('.installer_post_code_row').length === 1)
                {
                    angular.element('.installer_post_code_table').hide();
                }
                angular.element('#td_installer_' + user_id).removeClass("selected_installer");
                angular.element('#td_installer_' + user_id).css("background-color", "");

                // Start : remove postcode box for de-selected installer -- PRIYA BHOJANI -- //
                for (var i = 0, len = selected_installers.length; i < len; i++) 
                {
                    if (selected_installers[i].user_id === user_id) {
                        selected_installers.splice(i,1);
                        $scope.selected_installers.splice(i,1);
                        break;
                    }
                }
                // End : remove postcode box for de-selected installer -- PRIYA BHOJANI -- //                
            }
        };

        // Start : add installer availability function -- PRIYA BHOJANI -- //
        $scope.saveInstallerSchedule = function ()
        {
            if (angular.element('.installer_post_code_row').length === 0)
            {
                angular.element('#installer_list_table').css("border","1px solid red");
                return false; 
            }
            angular.element('#installer_list_table').css("border","");
            // Start : Returns an array of dates between the two dates -- PRIYA BHOJANI -- //
            var getDates = function (startDate, endDate) {
                var dates = [],
                        currentDate = startDate,
                        addDays = function (days) {
                            var date = new Date(this.valueOf());
                            date.setDate(date.getDate() + days);
                            return date;
                        };
                while (currentDate <= endDate) {
                    dates.push(currentDate);
                    currentDate = addDays.call(currentDate, 1);
                }
                return dates;
            };
            // End : Returns an array of dates between the two dates -- PRIYA BHOJANI -- //

            // Usage
            var dates = getDates(new Date(angular.element('#from_date').val()), new Date(angular.element('#to_date').val()));

            var start_time = parseInt(angular.element('#start_time option:selected').val());
            var end_time = parseInt(angular.element('#end_time option:selected').val());

            var response_array = []; // array to insert for installer availibility schedule

            selected_installers.forEach(function (installer)
            {
                dates.forEach(function (date)
                {
                    var date_data = new Date(date);
                    var day = date_data.getDate();
                    var monthIndex = date_data.getMonth();
                    var year = date_data.getFullYear();

                    var available_date = year + '-' + (monthIndex + 1) + '-' + day;
                    for (var time_slot_id = start_time; time_slot_id <= end_time; time_slot_id++)
                    {
                        time_slot_id = parseInt(time_slot_id);
                        var work_area_postcode = angular.element('#installer_' + installer.user_id + '_postcode').val();

                        // push to the array for installer availibility schedule
                        response_array.push({'available_date': available_date, 'user_id': installer.user_id, 'time_slot': time_slot_id, 'work_area_postcode': work_area_postcode});
                    }
                });
            });

            $scope.response_array = {
                "method_name": "addInstallerAvailability",
                "availability": response_array
            };
            $scope.response_array = JSON.stringify($scope.response_array);

            // Start : add installer availaibility -- PRIYA BHOJANI -- //
            $http({
                method: 'POST',
                url: '/installers/api?token=' + localStorage.getItem('userToken'),
                data: $scope.response_array,
                headers: {'Content-Type': undefined}
            }).success(function (response)
            {
                if (response.data.length !== 0)
                {
                    var conflicted_data = response.data;
                    var confirm_message = "Below mentioned time-slots are conflicted , Do you want to override them ? \n";

                    conflicted_data.forEach(function (conflict)
                    {
                        conflict.forEach(function (conflict_value)
                        {
                            confirm_message += conflict_value.name + ' on ' + conflict_value.available_date + ' on ' + $TIME_SLOT[conflict_value.available_time_slot_id] + " with postcode : " + conflict_value.work_area_postcode + "\n";
                        });
                    });
                    
                    
                    if (window.confirm(confirm_message))
                    {
                        // redirect if user confirms to override
                        window.location.href = "#/installers/";
                    } else
                    {
                        // Start : if user cancells overriding data , then revert it -- PRIYA BHOJANI -- //
                        var data_to_revert = [];
                        conflicted_data.forEach(function (conflict)
                        {
                            conflict.forEach(function (conflict_value)
                            {
                                data_to_revert.push({'available_date': conflict_value.available_date, 'user_id': conflict_value.user_id, 'time_slot': conflict_value.available_time_slot_id, 'work_area_postcode': conflict_value.work_area_postcode});

                            });
                        });

                        $scope.revert_data =
                                {
                                    "method_name": "addInstallerAvailability",
                                    "availability": data_to_revert
                                };
                        $scope.response_array = JSON.stringify($scope.response_array);

                        $http
                        ({
                            method: 'POST',
                            url: '/installers/api?token=' + localStorage.getItem('userToken'),
                            data: $scope.revert_data,
                            headers: {'Content-Type': undefined}
                        }).success(function (response)
                        {
                             window.location.href = "#/installers/";
                        }).error(function (response)
                        {
                            if (response.error === "token_not_provided")
                            {
                                window.location.href = "#/logout";
                            }
                        });
                        // End : if user cancells overriding data , then revert it -- PRIYA BHOJANI -- //
                    } // ELSE end : (window.confirm(confirm_message)) 
                } // if (response.data.length !== 0) end
                else
                {
                     window.location.href = "#/installers/";
                }                 
            }).error(function (response) {
                if (response.error) {
                    window.location.href = "#/logout";
                }
            });
            // End : add installer availaibility -- PRIYA BHOJANI -- //

        };
        // End : add installer availability function -- PRIYA BHOJANI -- //
    }]);