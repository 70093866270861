// configure our routes 

 hwecApp.config(['$routeProvider',
    function($routeProvider, $locationProvider) {
        $routeProvider
        //list pages
        .when('/', {
            templateUrl: '/app/views/users/login.html',
            controller: 'loginController'
        }) 
        .when('/logout', {
            template: "",
            controller: 'logoutController'
        })
        .when('/register', {
            templateUrl: '/app/views/users/register.html',
            controller: 'registerController'
        })
        .when('/dashboard', {
                templateUrl: '/app/views/appointments/list.html',
                controller: 'listController'
        })
        .when('/appointments', {
            templateUrl: '/app/views/appointments/list.html',
            controller: 'listController'
        })
        .when('/categories', {
            templateUrl: '/app/views/categories/list.html',
            controller: 'listController'
        })
        .when('/categories/new', {
            templateUrl: '/app/views/categories/list.html',
           controller: 'newController'
        })
        .when('/products', {
            templateUrl: '/app/views/products/list.html',
            controller: 'listController'
        })
        .when('/installers', {
            templateUrl: '/app/views/installers/installer_calendar.html',
            controller: 'installerCalendarController'
        })

            .when('/installers-daily-activity', {
                templateUrl: '/app/views/installers/installer_daily_activity.html',
                controller: 'installerActivityController'
            })
            .when('/single-installer-activity', {
                templateUrl: '/app/views/installers/single-installer-activity.html',
                controller: 'singleInstallerCalendarController'
            })
 
        //new page
        .when('/appointments/new', {
            templateUrl: '/app/views/appointments/new.html',
            controller: 'newController'
        })
        .when('/categories/new', {
            templateUrl: '/app/views/categories/new.html',
            controller: 'newController'
        })
        .when('/products/new', {
            templateUrl: '/app/views/products/new.html',
            controller: 'newController'
        })
        .when('/installers/new', {
            templateUrl: '/app/views/installers/new.html',
            controller: 'newController'
        })

        //detail page
        .when('/appointments/:id', {
            templateUrl: '/app/views/appointments/view.html',
            controller: 'viewController'
        })
        .when('/categories/:id', {
            templateUrl: '/app/views/categories/view.html',
            controller: 'viewController'
        })
        .when('/products/:id', {
            templateUrl: '/app/views/products/view.html',
            controller: 'viewController'
        })

        //edit page
        .when('/categories/edit/:id', {
            templateUrl: '/app/views/categories/edit.html',
            controller: 'editController'
        }) 
        .when('/appointments/edit/:id', {
            templateUrl: '/app/views/appointments/edit_appointment.html',
            controller: 'editAppointmentController'
        })        
        .when('/products/edit/:id', {
            templateUrl: '/app/views/products/edit.html',
            controller: 'editController'
        })
        .when('/installers/edit/:id', {
            templateUrl: '/app/views/installers/edit.html',
            controller: 'editController'
        })
        .when('/installers/manage_installer_schedule/', {
            templateUrl: '/app/views/installers/manage_installer_schedule.html',
            controller: 'installerController'
        })

        //delete
        .when('/categories/delete/:id', {
            templateUrl: '/app/views/categories/delete.html',
            controller: 'deleteController'
        })
        //delete appointments
        .when('/appointments/delete/:id', {
            templateUrl: '/app/views/appointments/delete.html',
            controller: 'deleteController'
        })    
             
        .when('/book-appointment', {
            templateUrl: '/app/views/customers/customer_book_appointment.html',
            controller: 'customerBookAppointmentController'
        })
        .when('/appointment-message', { 
            templateUrl: '/app/views/customers/customer_appointment_message.html',
            controller: 'customerAppointmentMessageController'
        })
        .when('/doorknocker/manage-doorknocker-addresses', {
            templateUrl: '/app/views/doorknockers/manage_doorknocker_addresses.html',
            controller: 'manageDoorknockerAddressController' 
        })
        
        .when('/reminders/pending-reminders', {
            templateUrl: '/app/views/reminders/pending_reminders.html',
            controller: 'pendingReminderController' 
        })
        .when('/reminders/change-appointment-reminder-status/:id', {
            templateUrl: '/app/views/reminders/change_appointment_reminder_status.html',
            controller: 'pendingReminderController' 
        })
        .when('/export_data', {
            templateUrl: '/app/views/export_data/export_data.html',
            controller: 'exportDataController' 
        })
        .when('/doorknocker/override-doorknocker-addresses', {
            templateUrl: '/app/views/doorknockers/override_doorknocker_addresses.html',
            controller: 'overrideDoorknockerAddressController' 
        })
        .when('/product/assign-products-to-appointment', {
            templateUrl: '/app/views/products/assign_products_to_appointment.html',
            controller: 'assignProductsToAppointmentsController'
        })

        //REPORTS
        .when('/acquisition-report', {
            templateUrl: '/app/views/reports/acquisition.html',
            controller: 'acquisitionDashboardController'
        })
        .when('/installs-report', {
            templateUrl: '/app/views/reports/installs.html',
            controller: 'installerReportController'
        })
        .when('/freepack-report', {
            templateUrl: '/app/views/reports/freepack.html',
            controller: 'freepackReportController'
        })
        .when('/freepack-report/:year/:month', {
            templateUrl: '/app/views/reports/freepack.html',
            controller: 'freepackReportController'
        })
        .when('/calculator-customer-book-appointment', {
            templateUrl: '/app/views/calculator_customers/calculator_customer_book_appointment.html',
            controller: 'calculatorCustomerBookAppointmentController'
        })
        .when('/installer_application_questions', {
            templateUrl: '/app/views/installers/installer_app_questions.html',
            controller: 'installerApplicationQuestionsController'
        })
        .when('/doorknocker/add_doorknocker_work_area', {
            templateUrl: '/app/views/doorknockers/add_doorknocker_work_area.html',
            controller: 'addDoorknockerWorkAreaController'
        })
         .when('/doorknocker/doorknocker_work_area', {
            templateUrl: '/app/views/doorknockers/doorknocker_work_area.html',
            controller: 'doorknockerWorkAreaController'
        })
    }
]); 




