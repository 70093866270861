hwecApp.controller('deleteController', ['$filter','$scope', '$http','SUCCESS_CODE','FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'ProviderListFactory', 'CategoryListFactory', function($filter, $scope, $http, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, ProviderListFactory, CategoryListFactory, $window) {  
 

 $scope.curentUrl = $location.path().replace('/',"").split('/')[0];
 $scope.itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);

    // Delete Category
    // Mohtadi Bakali Tahiri  : 03/08/2016

 if($scope.curentUrl == "categories"){

            $scope.submitDeleteCategory = function (){
                $scope.itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);

                //$scope.status =   parseInt($scope.category_active ? 1:0);
                $scope.deleteCategoryData = {
                    "method_name": "deleteData",
                    "product_category_id" : $scope.itemId
                };


                $scope.deleteCategoryData = JSON.stringify($scope.deleteCategoryData);

                $http({
                    method: 'POST',
                    url: 'categories/api?token=' + localStorage.getItem('userToken'),
                    data: $scope.deleteCategoryData,
                    headers: {'Content-Type': 'application/json'}
                }).success(function(data) {
                    if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
                    if(data.response_code == SUCCESS_CODE) {
                        window.location = '#/Add_Category';
                    }else if(data.response_code == FAILURE_CODE) {
                        //
                    }
                }).error(function(data) {
                    if(data.error) { window.location.href = "#/logout" };
                })
            };
 };

 

        // Delete Appointment
        // Mohtadi Bakali Tahiri  : 15/08/2016

        if($scope.curentUrl == "appointments"){   

          
        $scope.deleteAppointmentData = {
            "security_token": "swsm",
            "method_name": "changeAppointmentStatusByScheduler",
            "user_id": 1,
            "appointment_id": $scope.itemId,
            "status": "Deleted"
        }

        $scope.deleteAppointmentData = JSON.stringify($scope.deleteAppointmentData);   
        console.log($scope.deleteAppointmentData);

            $http({
                method: 'POST',
                url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                data: $scope.deleteAppointmentData,
                headers: {'Content-Type': 'application/json'}
            }).success(function(data) {
                if(data.response_code == FAILURE_CODE ) {window.location.href = "#/logout"};
                if(data.response_code == SUCCESS_CODE) {
                    window.location = '#/appointments';
                    console.log(data);
                }else if(data.response_code == FAILURE_CODE) {
                    //
                }
            }).error(function(data) {
                if(data.error) { window.location.href = "#/logout" };
            })                            



        };

}]);