hwecApp.controller('searchController', ['$scope', '$http','$records_per_page', '$route', '$location', 'FAILURE_CODE', function($scope, $http, $records_per_page, $route, $location, FAILURE_CODE,SUCCESS_CODE){

    $scope.paginatePage = true;
    $scope.hideNewBtn = true;
    $scope.curentUrl = $location.path().replace('/',"");
    $scope.Urllocation = $scope.curentUrl.substring(0, $scope.curentUrl.length - 1);

    $scope.pageTitle = $scope.curentUrl + " list";

    $scope.date = new Date();

    $scope.sortType     = 'appointment_date'; // set the default sort type
    $scope.sortReverse  = true;  // set the default sort order

    $scope.users = []; //declare an empty array
    $scope.pageno = 1; // initialize page no to 1
    $scope.total_count = 0;
    $scope.itemsPerPage = 20; //this could be a dynamic value from a drop down



    $scope.getData = function(pageno){ // This would fetch the data on page change.

        $scope.appointmentData = {
            "records_per_page":$scope.itemsPerPage,
            "page_number": pageno,
            "remember_token":"dfs",
            "method_name":"listData"
        };

        $scope.appointmentData = JSON.stringify($scope.appointmentData);



        $scope.users = [];
        $http({
            method: 'POST',
            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
            data: $scope.appointmentData,
            headers: {'Content-Type': 'application/json'}
        }).success(function(data) {
            $scope.total_count = data["total_number_of_appointments"];
            $scope.users = data["appointments"]; 
        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        })
    };

    $scope.getData($scope.pageno); // Call the function to fetch initial data on page load.
    $scope.rangeDataApp = function(){

        $scope.keyword  =         $scope.keyword  ? $scope.keyword:null;
        $scope.fieldToSearch =    $scope.fieldToSearch  ? $scope.fieldToSearch:null;


        if($scope.keyword != null || $scope.fieldToSearch != null){
                //console.log("accedemos a la api buscar por keyword");
                $scope.appointmentDataFiltered = {
                    "method_name": "searchByKeyword",
                    "field":$scope.fieldToSearch,
                    "keyword":$scope.keyword 
                }
            
                $scope.appointmentDataFiltered = JSON.stringify($scope.appointmentDataFiltered);

                $http({
                    method: 'POST',
                    url: 'appointments/api?token=' + localStorage.getItem('userToken'),
                    data: $scope.appointmentDataFiltered,
                    headers: {'Content-Type': 'application/json'}
                }).success(function(data) {
                    $scope.total_count  = data.length;
                    $scope.users        = data;
                    $scope.keyword = null;
                    $scope.fieldToSearch = null;

                    console.log(data);
                }).error(function(data) {
                    if(data.error) { window.location.href = "#/logout" };
                })
        }else {



        $scope.appointmentDataFiltered = {
            "method_name": "filtereData",
            "date_start":$scope.fecha1,
            "date_end":$scope.fecha2,
            "sort_field":'appointment_date'
        };

        $scope.appointmentDataFiltered = JSON.stringify($scope.appointmentDataFiltered);

            console.log($scope.appointmentDataFiltered );

        $http({
            method: 'POST',
            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
            data: $scope.appointmentDataFiltered,
            headers: {'Content-Type': 'application/json'}
        }).success(function(data) {
            $scope.total_count  = data.length;
            $scope.users        = data;

        }).error(function(data) {
            if(data.error) { window.location.href = "#/logout" };
        })
        }

    }

}]);




 
                            