hwecApp.controller('acquisitionController', ['PROVIDER','$filter', '$scope', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', '$q', function (PROVIDER,$filter, $scope, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL, $q) { 
   
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");

    //full width page 
    $scope.fullWidth = true;


   //get this year start and end
    var thisYear = (new Date()).getFullYear();    

    /* ========================================================== *
     *                get totals since start
     * ========================================================== */
   
    function getPercentage(total, amount) {
        return  ((amount / total) * 100).toFixed(2) + '%';
    };
       
    $http({
        method: 'POST',
       url: 'statistics/api?token=' + localStorage.getItem('userToken'), 
       data: {
           "method_name" : "getAppointmentStatistics" ,
           "start_date": "",
	       "finish_date": ""	
       }
    }).success(function(response) {
        if (response.response_code === FAILURE_CODE) {
            window.location = "#/logout";     
        }



        $scope.appointment_booked_number =  parseInt(response.appointment_booked) + 
                                            parseInt(response.appointment_callback) + 
                                            parseInt(response.appointment_rebooked1) +
                                            parseInt(response.appointment_rebooked2) +
                                            parseInt(response.appointment_rebooked3) +
                                            parseInt(response.appointment_rebooked4) +
                                            parseInt(response.appointment_rebooked5); 
       
        $scope.appointment_completed_number = parseInt(response.appointment_completed);

        $scope.appointment_canceled_number = parseInt(response.appointment_cancelled) +
                                             parseInt(response.appointment_noanswer) + 
                                             parseInt(response.appointment_rejected);


        $scope.appointmentsTotal = $scope.appointment_booked_number + $scope.appointment_completed_number + $scope.appointment_canceled_number;// + parseInt(response.appointment_inprogress);

        $scope.appointment_booked = getPercentage($scope.appointmentsTotal, $scope.appointment_booked_number);

        $scope.appointment_canceled = getPercentage($scope.appointmentsTotal, $scope.appointment_canceled_number);

        $scope.appointment_completed = getPercentage($scope.appointmentsTotal, $scope.appointment_completed_number);

    }); 


    //get Mondays and Sundays of THIS week
    var today = new Date();
    var dayOfWeekStartingSundayZeroIndexBased = today.getDay(); // 0 : Sunday ,1 : Monday,2,3,4,5,6 : Saturday
    var mondayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+1);
    var sundayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay()+7);

    //Mondays
    var Mmonth   = mondayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Mday     = mondayOfWeek.getDate();
    var Myear    = mondayOfWeek.getUTCFullYear();

    //Sundays
    var Smonth   = sundayOfWeek.getUTCMonth() + 1; //months from 1-12
    var Sday     = sundayOfWeek.getDate();
    var Syear    = sundayOfWeek.getUTCFullYear();

    //date from current month
    var beginCurentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var endCurentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);


    var dayBM = beginCurentMonth.getDate();
    var monthBM = beginCurentMonth.getMonth() + 1;
    var yearBM = beginCurentMonth.getUTCFullYear();

    var dayEM = endCurentMonth.getDate();
    var monthEM = endCurentMonth.getMonth() + 1;
    var yearEM = endCurentMonth.getUTCFullYear();

    //get array sum
    // function sum(input){
             
    // if (toString.call(input) !== "[object Array]")
    //     return false;
        
    //     var total =  0;
    //     for(var i=0;i<input.length;i++)
    //     {                  
    //         if(isNaN(input[i])){
    //         continue;
    //         }
    //         total += Number(input[i]);
    //     }
    //     return total;
    // }

    Array.prototype.sum = function() {
    return this.reduce(function(a,b){return a+b;});
    };

    //format from long date to YYYY-MM-DD
    function formatDate(day, month, year) {
        day = day < 10 ? "0"+day : day;
        month = month < 10 ? "0"+month : month;
        year = year;
        return newdate = year + "-" + month + "-" + day;
    }


    // get previos weeks from current week
    var fromThisMonday = formatDate(Mday, Mmonth, Myear);
    var fromThisSunday = formatDate(Sday, Smonth, Syear);

    function getDaysBefore(startingWeek, numDays) {
        var newWeekDate = new Date(startingWeek);
        newWeekDate.setDate(newWeekDate.getDate() - numDays);
        var day   = newWeekDate.getDate();
        var month = newWeekDate.getUTCMonth() + 1;
        var year  = newWeekDate.getUTCFullYear();

        return formatDate(day, month, year);
    }
   
    function numberOfWeeks(numWeeks) { return numWeeks * 7 }

    var thisWeek, weekOne, weekTwo, weekThree, weekFour = "";

    /* ========================================================== *
     *             get appointment for this week
     * ========================================================== */
        $scope.request0 = $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getTotalNumberOfAppointmentsByDate",
            "from"        : formatDate(Mday, Mmonth, Myear), //this week Monday
            "to"          : formatDate(Sday, Smonth, Syear) // this week Sunday
        }
    }).success(function(response) {
        
        var dataResponse = response.data.data_array;
        var dataResponseKeys = Object.keys(dataResponse).map(function(k){return dataResponse[k]});
        $scope.totalAppointments = response.data.total_count;
        thisWeek = $scope.totalAppointments;

        var ctx = document.getElementsByClassName("total-appointments-chart");
        $scope.modal2 = function(ctx) { 
            
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sunday"],
                datasets: [{
                    label: 'Appointments',
                    data: dataResponseKeys,
                    backgroundColor: [
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                        '#00aeef',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                 legend: {
                    display: false
                },
                title:{
                    display:false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
        });
         };

         $scope.modal2(ctx);

    }).error(function(response) {
        
    });

    /* ========================================================== *
     *             total appointment one week before
     * ========================================================== */
    $scope.request1 = $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
             "method_name" : "getTotalNumberOfAppointmentsByDate",
             "from"        : getDaysBefore(fromThisMonday, numberOfWeeks(1)),
             "to"          : getDaysBefore(fromThisSunday, numberOfWeeks(1))
        }
    }).success(function(response) {
         weekOne = response.data.total_count;
    }).error(function(response) {});


    /* ========================================================== *
     *             total appointment two week before
     * ========================================================== */
    $scope.request2 = $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
             "method_name" : "getTotalNumberOfAppointmentsByDate",
             "from"        : getDaysBefore(fromThisMonday, numberOfWeeks(2)),
             "to"          : getDaysBefore(fromThisSunday, numberOfWeeks(2))
        }
    }).success(function(response) {
         weekTwo = response.data.total_count;
    }).error(function(response) {});


    /* ========================================================== *
     *             total appointment three week before
     * ========================================================== */
    $scope.request3 = $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
             "method_name" : "getTotalNumberOfAppointmentsByDate",
             "from"        : getDaysBefore(fromThisMonday, numberOfWeeks(3)),
             "to"          : getDaysBefore(fromThisSunday, numberOfWeeks(3))
        }
    }).success(function(response) {
         weekThree = response.data.total_count;
    }).error(function(response) {});


    /* ========================================================== *
     *             total appointment four week before
     * ========================================================== */
    $scope.request4 = $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
             "method_name" : "getTotalNumberOfAppointmentsByDate",
             "from"        : getDaysBefore(fromThisMonday, numberOfWeeks(4)),
             "to"          : getDaysBefore(fromThisSunday, numberOfWeeks(4))
        }
    }).success(function(response) {
         weekFour = response.data.total_count;
    }).error(function(response) {});
    
    
   /* ========================================================== *
    *             get statistics by status
    * ========================================================== */
    function add(a, b) {
        return a + b;
    }

   $http({
       method: 'POST',
       url: 'statistics/api?token=' + localStorage.getItem('userToken'),
       data: {
           "method_name"  : "getAllAppointmentsByStatus",
            "from"        : formatDate(Mday, Mmonth, Myear), // this week Monday
            "to"          : formatDate(Sday, Smonth, Syear) // this week Sunday
       }
   }).success(function(response) {

       var cancelledAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].cancelled)});
       var completedAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].completed)});
       var plannedAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].planned)});

        // $scope.cancelledAppointmentsWeekTotal = parseInt(cancelledAppointments.reduce(add, 0));
        // $scope.completedAppointmentsWeekTotal = parseInt(completedAppointments.reduce(add, 0));
        // $scope.plannedAppointmentsWeekTotal   = parseInt(plannedAppointments.reduce(add, 0));


        $scope.cancelledAppointmentsWeekTotal = cancelledAppointments.sum();
        $scope.completedAppointmentsWeekTotal = completedAppointments.sum();
        $scope.plannedAppointmentsWeekTotal   = plannedAppointments.sum();

        $scope.weekStatusTotal = parseInt($scope.cancelledAppointmentsWeekTotal) + parseInt($scope.completedAppointmentsWeekTotal) + parseInt($scope.plannedAppointmentsWeekTotal);

       var barChartData = {
            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            datasets: [{
                label: 'Cancelled',
                backgroundColor: "rgba(255, 99, 132, 1)",
                yAxisID: "y-axis-1",
                data: cancelledAppointments
            }, {
                label: 'Completed',
                backgroundColor: "rgba(75, 192, 192, 1)",
                yAxisID: "y-axis-2",
                data: completedAppointments
            }, {
                label: 'Planned',
                backgroundColor: "rgba(54, 162, 235, 1)",
                yAxisID: "y-axis-1", 
                data: plannedAppointments
            }]
        };

   
        var ctxStatus = document.getElementsByClassName("total-appointments-status");
        $scope.modal3 = function(ctxStatus) {

        var channelMondayChart = new Chart(ctxStatus, {
            type: 'doughnut',
            data: {
                labels: ["Completed ", "Cancelled", "Planned"],
                datasets: [{
                    label: 'Satus',
                    data:[
                        $scope.completedAppointmentsWeekTotal,
                        $scope.cancelledAppointmentsWeekTotal,
                        $scope.plannedAppointmentsWeekTotal
                        ],
                    backgroundColor: [
                        '#60bb46',
                        '#ff0000',
                        '#0083c6' 
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
            

        }
        $scope.modal3(ctxStatus);    
        
   }).error(function(response) {
       
   });

   $http({
       method: 'POST',
       url: 'statistics/api?token=' + localStorage.getItem('userToken'),
       data: {
           "method_name"  : "getAllAppointmentsByStatus",
            "from"        : formatDate(dayBM, monthBM, yearBM), // current month start
            "to"          : formatDate(dayEM, monthEM, yearEM) // current month end
       }
   }).success(function(response) {

    
       

       var monthCancelledAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].cancelled)});
       var monthCompletedAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].completed)});
       var monthPlannedAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].planned)});

        $scope.cancelledAppointmentsMonthTotal  = monthCancelledAppointments.sum();
        $scope.completedAppointmentsMonthTotal  = monthCompletedAppointments.sum();
        $scope.plannedAppointmentsMonthTotal    = monthPlannedAppointments.sum();

        $scope.totalsThisMonth = parseInt($scope.cancelledAppointmentsMonthTotal) + parseInt($scope.completedAppointmentsMonthTotal) + parseInt($scope.plannedAppointmentsMonthTotal);

        var ctxStatusMonth = document.getElementsByClassName("total-appointments-status-month");
        $scope.modal5 = function(ctxStatusMonth) {

        var statusMonthChart = new Chart(ctxStatusMonth, {
            type: 'doughnut',
            data: {
                labels: ["Completed ", "Cancelled", "Planned"],
                datasets: [{
                    label: 'Status',
                    data:[
                        $scope.completedAppointmentsMonthTotal,
                        $scope.cancelledAppointmentsMonthTotal,
                        $scope.plannedAppointmentsMonthTotal
                        ],
                    backgroundColor: [
                        '#60bb46',
                        '#ff0000',
                        '#0083c6' 
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });

        }
        $scope.modal5(ctxStatusMonth); 

   }).error(function(response) {

   });



   //since start
   $http({
       method: 'POST',
       url: 'statistics/api?token=' + localStorage.getItem('userToken'),
       data: {
           "method_name"  : "getAllAppointmentsByStatus",
            "from"        : "2016-09-01",
            "to"          : formatDate(31, 12, thisYear)
       }
   }).success(function(response) { 

       var sinceStartCancelledAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].cancelled)});
       var sinceStartCompletedAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].completed)});
       var sinceStartPlannedAppointments = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k].planned)});
       
        $scope.cancelledAppointmentsSinceStartTotal  = sinceStartCancelledAppointments.sum();
        $scope.completedAppointmentsSinceStartTotal  = sinceStartCompletedAppointments.sum();
        $scope.plannedAppointmentsSinceStartTotal    = sinceStartPlannedAppointments.sum();

        var ctxStatusSinceStart = document.getElementsByClassName("total-appointments-status-since-start");
        $scope.modal5SinceStart = function(ctxStatusSinceStart) {

        var statusSinceStartChart = new Chart(ctxStatusSinceStart, {
            type: 'doughnut',
            data: {
                labels: ["Completed ", "Cancelled", "Planned"],
                datasets: [{
                    label: 'Satus',
                    data:[
                        $scope.completedAppointmentsSinceStartTotal,
                        $scope.cancelledAppointmentsSinceStartTotal,
                        $scope.plannedAppointmentsSinceStartTotal
                        ],
                    backgroundColor: [
                        '#60bb46',
                        '#ff0000',
                        '#0083c6' 
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });

        }
        $scope.modal5SinceStart(ctxStatusSinceStart); 

   }).error(function(response) {

   }); 


   /* ========================================================== *
    *             get appointments by source
    * ========================================================== */
    $scope.totalDoorKnocking = [];
    $scope.totalInboundCall = [];
    $scope.totalEmployee = [];
    $scope.totalWebsite = [];
    $scope.totalLetter = [];
    $scope.totalLeaflet = [];
    $scope.totalFootfall = [];
    $scope.totalOutboundCallCoupon = [];
    $scope.totalOutboundCallData = [];

    function getTotalSum(total, num) { return total + num };

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getAppointmentsByChannelByDate",
            "from"        : formatDate(Mday, Mmonth, Myear), // this week Monday
            "to"          : formatDate(Sday, Smonth, Syear) // this week Sunday

        }
    }).success(function(response) {



        angular.forEach(response.data.data_array, function(value, key) {
            $scope.totalDoorKnocking.push(value['Door Knocking']);
            $scope.totalEmployee.push(value['Employee']);
            $scope.totalFootfall.push(value['Footfall']);
            $scope.totalInboundCall.push(value['Inbound Call']);
            $scope.totalLeaflet.push(value['Leaflet']);
            $scope.totalLetter.push(value['Letter']);
            $scope.totalOutboundCallCoupon.push(value['Outbound Call/Coupon']);
            $scope.totalOutboundCallData.push(value['Outbound Call/Data']);
            $scope.totalWebsite.push(value['Website']);
        });

       //pass in each day the value of the key 
       $scope.doorKnocking       = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Door Knocking'])});
       $scope.employee           = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Employee'])});
       $scope.footfall           = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Footfall'])});
       $scope.inboundCall        = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Inbound Call'])});
       $scope.leaflet            = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Leaflet'])});
       $scope.letter             = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Letter'])});
       $scope.outboundCallCoupon = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Outbound Call/Coupon'])});
       $scope.outboundCallData   = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Outbound Call/Data'])});
       $scope.website            = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Website'])});
       

       $scope.doorKnockingWeekTotal       = $scope.doorKnocking.sum();
       $scope.employeeWeekTotal           = $scope.employee.sum();
       $scope.footfallWeekTotal           = $scope.footfall.sum();
       $scope.inboundCallWeekTotal        = $scope.inboundCall.sum();
       $scope.leafletWeekTotal            = $scope.leaflet.sum();
       $scope.letterWeekTotal             = $scope.letter.sum();
       $scope.outboundCallCouponWeekTotal = $scope.outboundCallCoupon.sum();
       $scope.outboundCallDataWeekTotal   = $scope.outboundCallData.sum(); 
       $scope.websiteWeekTotal            = $scope.website.sum();   

      

  
        var ctxSourceWeek = document.getElementsByClassName("total-appointments-source");
        $scope.modal4 = function(ctxSourceWeek) {
         var sourceWeek = new Chart(ctxSourceWeek, {
            type: 'doughnut',
            data: {
                labels: ["Door Knocking", /*"Employee", "Footfall",*/ "Inbound Call", /*"Leaflet", "Letter",*/ "Outbound Call/Coupon", "Outbound Call/Data", "Website"],
                datasets: [{
                    label: 'Source',
                    data:[
                        $scope.doorKnockingWeekTotal,      
                        // $scope.employeeWeekTotal,          
                        //$scope.footfallWeekTotal,
                        $scope.inboundCallWeekTotal,       
                        // $scope.leafletWeekTotal,           
                        // $scope.letterWeekTotal,            
                        $scope.outboundCallCouponWeekTotal,
                        $scope.outboundCallDataWeekTotal,
                        $scope.websiteWeekTotal
                        ],
                    backgroundColor: [
                        '#ff0000',
                        // '#0083c6',
                      //  '#00aeef',
                        '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        // 'rgba(259, 80, 30, 1)',
                        'rgba(15, 79, 93, 1)',
                        'rgba(177, 84, 75, 1)',
                         '#60bb46' 
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });   
    }
    $scope.modal4(ctxSourceWeek); 
   }).error(function(response) {
       
   });


    $scope.monthTotalDoorKnocking = [];
    $scope.monthTotalInboundCall = [];
    $scope.monthTotalEmployee = [];
    $scope.monthTotalWebsite = [];
    $scope.monthTotalLetter = [];
    $scope.monthTotalLeaflet = [];
    $scope.monthTotalFootfall = [];
    $scope.monthTotalOutboundCallCoupon = [];
    $scope.monthTotalOutboundCallData = [];

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getAppointmentsByChannelByDate",
            "from"        : formatDate(dayBM, monthBM, yearBM), // current month start
            "to"          : formatDate(dayEM, monthEM, yearEM) // current month end
        }
    }).success(function(response) {

        angular.forEach(response.data.data_array, function(value, key) {
            $scope.monthTotalDoorKnocking.push(value['Door Knocking']);
            $scope.monthTotalEmployee.push(value['Employee']);
            $scope.monthTotalFootfall.push(value['Footfall']);
            $scope.monthTotalInboundCall.push(value['Inbound Call']);
            $scope.monthTotalLeaflet.push(value['Leaflet']);
            $scope.monthTotalLetter.push(value['Letter']);
            $scope.monthTotalOutboundCallCoupon.push(value['Outbound Call/Coupon']);
            $scope.monthTotalOutboundCallData.push(value['Outbound Call/Data']);
            $scope.monthTotalWebsite.push(value['Website']);
        });

       //pass in each day the value of the key 
       $scope.doorKnockingMonth            = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Door Knocking'])});
       $scope.employeeMonth                = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Employee'])});
       $scope.footfallMonth                = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Footfall'])});
       $scope.inboundCallMonth             = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Inbound Call'])});
       $scope.leafletMonth                 = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Leaflet'])});
       $scope.letterMonth                  = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Letter'])});
       $scope.outboundCallCouponMonth      = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Outbound Call/Coupon'])});
       $scope.outboundCallDataMonth        = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Outbound Call/Data'])});
       $scope.websiteMonth                 = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Website'])});


       $scope.doorKnockingMonthTotal        =  $scope.doorKnockingMonth.sum();
       $scope.employeeMonthTotal            =  $scope.employeeMonth.sum();
       $scope.footfallMonthTotal            =  $scope.footfallMonth.sum();
       $scope.inboundCallMonthTotal         =  $scope.inboundCallMonth.sum();
       $scope.leafletMonthTotal             =  $scope.leafletMonth.sum();
       $scope.letterMonthTotal              =  $scope.letterMonth.sum();
       $scope.outboundCallCouponMonthTotal  =  $scope.outboundCallCouponMonth.sum();
       $scope.outboundCallDataMonthTotal    =  $scope.outboundCallDataMonth.sum();
       $scope.websiteMonthTotal             =  $scope.websiteMonth.sum();



        var ctxSourceMonth = document.getElementsByClassName("total-appointments-source-month");
        $scope.modal6 = function(ctxSourceMonth) {

         var sourceMonth = new Chart(ctxSourceMonth, {
            type: 'doughnut',
            data: {
                labels: ["Door Knocking", /*"Employee", "Footfall",*/ "Inbound Call", /*"Leaflet", "Letter", */"Outbound Call/Coupon", "Outbound Call/Data" ,"Website"],
                datasets: [{
                    label: 'Source',
                    data:[
                        $scope.doorKnockingMonthTotal,      
                        // $scope.employeeMonthTotal,          
                      //  $scope.footfallMonthTotal,          
                        $scope.inboundCallMonthTotal,       
                        // $scope.leafletMonthTotal,           
                        // $scope.letterMonthTotal,            
                        $scope.outboundCallCouponMonthTotal,
                        $scope.outboundCallDataMonthTotal,
                         $scope.websiteMonthTotal          
                        ],
                    backgroundColor: [
                        '#ff0000',
                        // '#0083c6',
                     //   '#00aeef',
                        '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        // 'rgba(259, 80, 30, 1)',
                        'rgba(15, 79, 93, 1)',
                        'rgba(177, 84, 75, 1)',
                         '#60bb46' 
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
        
    }
    $scope.modal6(ctxSourceMonth);

    }).error(function(response) {});









    $scope.sinceStartTotalDoorKnocking = [];
    $scope.sinceStartTotalInboundCall = [];
    $scope.sinceStartTotalEmployee = [];
    $scope.sinceStartTotalWebsite = [];
    $scope.sinceStartTotalLetter = [];
    $scope.sinceStartTotalLeaflet = [];
    $scope.sinceStartTotalFootfall = [];
    $scope.sinceStartTotalOutboundCallCoupon = [];
    $scope.sinceStartTotalOutboundCallData = [];

    $http({
        method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
            "method_name" : "getAppointmentsByChannelByDate",
            "from"        : "2016-09-01",
            "to"          : formatDate(31, 12, thisYear)
        }
    }).success(function(response) {

        angular.forEach(response.data.data_array, function(value, key) {
            $scope.sinceStartTotalDoorKnocking.push(value['Door Knocking']);
            $scope.sinceStartTotalEmployee.push(value['Employee']);
            $scope.sinceStartTotalFootfall.push(value['Footfall']);
            $scope.sinceStartTotalInboundCall.push(value['Inbound Call']);
            $scope.sinceStartTotalLeaflet.push(value['Leaflet']);
            $scope.sinceStartTotalLetter.push(value['Letter']);
            $scope.sinceStartTotalOutboundCallCoupon.push(value['Outbound Call/Coupon']);
            $scope.sinceStartTotalOutboundCallData.push(value['Outbound Call/Data']);
            $scope.sinceStartTotalWebsite.push(value['Website']);
        });

       //pass in each day the value of the key 
       $scope.doorKnockingSinceStart            = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Door Knocking'])});
       $scope.employeeSinceStart                = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Employee'])});
       $scope.footfallSinceStart                = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Footfall'])});
       $scope.inboundCallSinceStart             = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Inbound Call'])});
       $scope.leafletSinceStart                 = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Leaflet'])});
       $scope.letterSinceStart                  = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Letter'])});
       $scope.outboundCallCouponSinceStart      = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Outbound Call/Coupon'])});
       $scope.outboundCallDataSinceStart        = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Outbound Call/Data'])});
       $scope.websiteSinceStart                 = Object.keys(response.data.data_array).map(function(k){return parseInt(response.data.data_array[k]['Website'])});

        console.log($scope.websiteSinceStart)


       $scope.doorKnockingSinceStartTotal        =  $scope.doorKnockingSinceStart.sum();
       $scope.employeeSinceStartTotal            =  $scope.employeeSinceStart.sum();
       $scope.footfallSinceStartTotal            =  $scope.footfallSinceStart.sum();
       $scope.inboundCallSinceStartTotal         =  $scope.inboundCallSinceStart.sum();
       $scope.leafletSinceStartTotal             =  $scope.leafletSinceStart.sum();
       $scope.letterSinceStartTotal              =  $scope.letterSinceStart.sum();
       $scope.outboundCallCouponSinceStartTotal  =  $scope.outboundCallCouponSinceStart.sum();
       $scope.outboundCallDataSinceStartTotal    =  $scope.outboundCallDataSinceStart.sum();
       $scope.websiteSinceStartTotal             =  $scope.websiteSinceStart.sum();



        var ctxSourceSinceStart = document.getElementsByClassName("total-appointments-source-since-start");
        $scope.modal6SinceStart = function(ctxSourceSinceStart) {

         var sourceSinceStart = new Chart(ctxSourceSinceStart, {
            type: 'doughnut',
            data: {
                labels: ["Door Knocking", /*"Employee", "Footfall",*/ "Inbound Call", /*"Leaflet",*/ "Letter", "Outbound Call/Coupon", "Outbound Call/Data", "Website"],
                datasets: [{
                    label: 'Source',
                    data:[
                        $scope.doorKnockingSinceStartTotal,      
                        // $scope.employeeMonthTotal,          
                       // $scope.footfallSinceStartTotal,          
                        $scope.inboundCallSinceStartTotal,       
                        // $scope.leafletMonthTotal,           
                        $scope.letterSinceStartTotal,            
                        $scope.outboundCallCouponSinceStartTotal,
                        $scope.outboundCallDataSinceStartTotal,
                         $scope.websiteSinceStartTotal
                        ],
                    backgroundColor: [
                        '#ff0000',
                        // '#0083c6',
                        //'#00aeef',
                        '#f8991d',
                        // 'rgba(153, 102, 255, 1)',
                        'rgba(259, 80, 30, 1)',
                        'rgba(15, 79, 93, 1)',
                        'rgba(177, 84, 75, 1)',
                         '#60bb46' 
                    ] 
                }]
            },
            options: {
                cutoutPercentage: 50,
                title:{
                    display:false,
                    text:"Monday"
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            display: false
                        }
                    }]
                }
            }
        });
        
    }
    $scope.modal6SinceStart(ctxSourceSinceStart);

    }).error(function(response) {})



    /* ========================================================== *
    *                          year chart
    * ========================================================== */

    $http({
         method: 'POST',
        url: 'statistics/api?token=' + localStorage.getItem('userToken'),
        data: {
           "method_name" : "getAppointmentStatisticsPerStatusGroupedPerMonth"
        }
    }).success(function(response) {
       
       $scope.yearChannels = response.channels;

        var yearChartCanvas = document.getElementsByClassName("year-status-chart");
        
            var yearChartData = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
                label: 'Door Knocking',
                backgroundColor: "#ff0000",
                 data: [
                     $scope.yearChannels[1]['Door Knocking'],
                     $scope.yearChannels[2]['Door Knocking'],
                     $scope.yearChannels[3]['Door Knocking'],
                     $scope.yearChannels[4]['Door Knocking'],
                     $scope.yearChannels[5]['Door Knocking'],
                     $scope.yearChannels[6]['Door Knocking'],
                     $scope.yearChannels[7]['Door Knocking'],
                     $scope.yearChannels[8]['Door Knocking'],
                     $scope.yearChannels[9]['Door Knocking'],
                     $scope.yearChannels[10]['Door Knocking'],
                     $scope.yearChannels[11]['Door Knocking'],
                     $scope.yearChannels[12]['Door Knocking']    
                 ]
            }, { 
                label: 'Footfall',
                backgroundColor: "#00aeef",
                data: [
                     $scope.yearChannels[1]['Footfall'],
                     $scope.yearChannels[2]['Footfall'],
                     $scope.yearChannels[3]['Footfall'],
                     $scope.yearChannels[4]['Footfall'],
                     $scope.yearChannels[5]['Footfall'],
                     $scope.yearChannels[6]['Footfall'],
                     $scope.yearChannels[7]['Footfall'],
                     $scope.yearChannels[8]['Footfall'],
                     $scope.yearChannels[9]['Footfall'],
                     $scope.yearChannels[10]['Footfall'],
                     $scope.yearChannels[11]['Footfall'],
                     $scope.yearChannels[12]['Footfall']
                 ]
            }, {
                label: 'Inbound Call',
                backgroundColor: "#f8991d",
                data: [
                     $scope.yearChannels[1]['Inbound Call'],
                     $scope.yearChannels[2]['Inbound Call'],
                     $scope.yearChannels[3]['Inbound Call'],
                     $scope.yearChannels[4]['Inbound Call'],
                     $scope.yearChannels[5]['Inbound Call'],
                     $scope.yearChannels[6]['Inbound Call'],
                     $scope.yearChannels[7]['Inbound Call'],
                     $scope.yearChannels[8]['Inbound Call'],
                     $scope.yearChannels[9]['Inbound Call'],
                     $scope.yearChannels[10]['Inbound Call'],
                     $scope.yearChannels[11]['Inbound Call'],
                     $scope.yearChannels[12]['Inbound Call']
                 ]
            }, {
                label: 'Outbound Call/Coupon',
                backgroundColor: "#0f4f5d",
                data: [
                     $scope.yearChannels[1]['Outbound Call/Coupon'],
                     $scope.yearChannels[2]['Outbound Call/Coupon'],
                     $scope.yearChannels[3]['Outbound Call/Coupon'],
                     $scope.yearChannels[4]['Outbound Call/Coupon'],
                     $scope.yearChannels[5]['Outbound Call/Coupon'],
                     $scope.yearChannels[6]['Outbound Call/Coupon'],
                     $scope.yearChannels[7]['Outbound Call/Coupon'],
                     $scope.yearChannels[8]['Outbound Call/Coupon'],
                     $scope.yearChannels[9]['Outbound Call/Coupon'],
                     $scope.yearChannels[10]['Outbound Call/Coupon'], 
                     $scope.yearChannels[11]['Outbound Call/Coupon'],
                     $scope.yearChannels[12]['Outbound Call/Coupon']
                 ]
            }, {
                label: 'Outbound Call/Data',
                backgroundColor: "#b1544b",
                data: [
                     $scope.yearChannels[1]['Outbound Call/Data'],
                     $scope.yearChannels[2]['Outbound Call/Data'],
                     $scope.yearChannels[3]['Outbound Call/Data'],
                     $scope.yearChannels[4]['Outbound Call/Data'],
                     $scope.yearChannels[5]['Outbound Call/Data'],
                     $scope.yearChannels[6]['Outbound Call/Data'],
                     $scope.yearChannels[7]['Outbound Call/Data'],
                     $scope.yearChannels[8]['Outbound Call/Data'],
                     $scope.yearChannels[9]['Outbound Call/Data'],
                     $scope.yearChannels[10]['Outbound Call/Data'],
                     $scope.yearChannels[11]['Outbound Call/Data'],
                     $scope.yearChannels[12]['Outbound Call/Data']
                ]
            }, {
                label: 'Website',
                backgroundColor: "#60bb46",
                data: [
                     $scope.yearChannels[1]['Website'],
                     $scope.yearChannels[2]['Website'],
                     $scope.yearChannels[3]['Website'],
                     $scope.yearChannels[4]['Website'],
                     $scope.yearChannels[5]['Website'],
                     $scope.yearChannels[6]['Website'],
                     $scope.yearChannels[7]['Website'],
                     $scope.yearChannels[8]['Website'],
                     $scope.yearChannels[9]['Website'],
                     $scope.yearChannels[10]['Website'],
                     $scope.yearChannels[11]['Website'],
                     $scope.yearChannels[12]['Website']
                ]
            }]
        };
      
            
            $scope.modal7 = function(yearChartCanvas) {
                var yearCtx = new Chart(yearChartCanvas, {
                    type: 'bar',
                    data: yearChartData,
                    options: {
                        title:{
                            display:false
                           // text:"Year Status"
                        },
                         legend: {
                            display: false
                        },
                        tooltips: {
                            mode: 'label'
                        },
                        responsive: true,
                        scales: {
                            xAxes: [{
                                stacked: true,
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                        }
                    }
                });
            }
            
    $scope.modal7(yearChartCanvas);
    }).error(function(response) {})


// chart modals
var canvas1 = document.getElementsByClassName('total-appointments-line1');
var ctx1 = document.getElementsByClassName("total-appointments-chart1");
var ctxStatus1 = document.getElementsByClassName("total-appointments-status1");
var ctxSourceWeek1 = document.getElementsByClassName("total-appointments-source1");
 var ctxStatusMonth1 = document.getElementsByClassName("total-appointments-status-month1");
 var ctxStatusSinceStart1 = document.getElementsByClassName("total-appointments-status-since-start1");
 var ctxSourceMonth1 = document.getElementsByClassName("total-appointments-source-month1");
 var ctxSourceSinceStart1 = document.getElementsByClassName("total-appointments-source-since-start1");
 var ctxYear = document.getElementsByClassName("year-status-chart1");


$scope.showModal1 = function() {
    $('#chartModal1').modal('show');
    setTimeout(function() {
        $scope.modal1(canvas1);
    },300) 
};      

$scope.showModal2 = function() {
  $('#chartModal2').modal('show');
  setTimeout(function() {
      $scope.modal2(ctx1);
  },300)
};

$scope.showModal3 = function() {
  $('#chartModal3').modal('show');
  setTimeout(function() {
      $scope.modal3(ctxStatus1);
  },300)
};

$scope.showModal4 = function() {
  $('#chartModal4').modal('show');
  setTimeout(function() {
      $scope.modal4(ctxSourceWeek1);
  },300)
};

$scope.showModal5 = function() {
  $('#chartModal5').modal('show');
  setTimeout(function() {
      $scope.modal5(ctxStatusMonth1);
  },300)
};

$scope.showModal5SinceStart = function() {
  $('#chartModal5SinceStart').modal('show');
  setTimeout(function() {
      $scope.modal5SinceStart(ctxStatusSinceStart1);
  },300)
};

$scope.showModal6 = function() {
  $('#chartModal6').modal('show');
  setTimeout(function() { 
      $scope.modal6(ctxSourceMonth1);
  },300)
};

$scope.showModal6SinceStart = function() {
  $('#chartModal6SinceStart').modal('show');
  setTimeout(function() { 
      $scope.modal6SinceStart(ctxSourceSinceStart1);
  },300)
};

$scope.showModal7 = function() {
  $('#chartModal7').modal('show');
  setTimeout(function() { 
      $scope.modal7(ctxYear);
  },300)
};





         
}]);    
        