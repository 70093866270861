hwecApp.controller('assignProductsToAppointmentsController', ['$APPOINTMENT_TIME_DETAIL','$window','$filter', '$scope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'Restangular', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', function ($APPOINTMENT_TIME_DETAIL,$window,$filter, $scope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, Restangular, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH)
{

    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    $scope.pageTitle = "Modify appointment products";
    $scope.backLink = '#/appointments';
         
    $scope.show_assign_product_button = true;
    $scope.show_assigning_product_button = false;
 
    // Start : get list of appointments -- PRIYA BHOJANI -- //
    $scope.appointmentData = {"records_per_page": 3000,"page_number": 1,"method_name": "listData"};
     
    $http
    ({
            method: 'POST',
            url: 'appointments/api?token=' + localStorage.getItem('userToken'),
            data: $scope.appointmentData,
            headers: {'Content-Type': 'application/json'}
    }) 
    .success(function (data) 
    {
        if (data.response_code === SUCCESS_CODE) 
        {
            $scope.appointments = data.appointments;
        }
        else if (data.response_code === FAILURE_CODE) 
        {
            window.location = "#/logout";
        }
    })
    .error(function (data) 
    {
        if (data.error) 
        {
            window.location = "#/logout";
        }
    });
    // End : get list of appointments -- PRIYA BHOJANI -- //
    
    
    
    
    
    
    
    
    
    // Start : get list of ALL products -- PRIYA BHOJANI -- //
    $http
    ({
        method: 'POST',
        url: 'products/api?token=' + localStorage.getItem('userToken'),
        data: '{"method_name": "listData"}',
        headers: {'Content-Type': 'application/json'}
    }) 
    .success(function (data) 
    {
        $scope.all_products = data.data;
    })
    .error(function (data) 
    {
        if (data.error) 
        {
            window.location = "#/logout";
        }
    });
    // End : get list of ALL products -- PRIYA BHOJANI -- //
    
    
    $scope.showAppointmentProducts = function ()
    {
        // Start : get list of appointment products -- PRIYA BHOJANI -- //
        $http
        ({
            method: 'POST',
            url: 'products/api?token=' + localStorage.getItem('userToken'),
            data: {"appointment_id": $scope.selected_appointment_id.appointment_id,"method_name": "getAppointmentProducts"},
            headers: {'Content-Type': 'application/json'}
        }) 
        .success(function (data) 
        {
            $scope.appointment_products = data.product_installed;
        })
        .error(function (data) 
        {
            if (data.error) 
            {
                window.location = "#/logout";
            }
        });
        // End : get list of appointment products -- PRIYA BHOJANI -- //
    };
    
    // Start : assignNewProductsToAppointment -- PRIYA BHOJANI -- //
    $scope.assignNewProductsToAppointment = function()
    {
        
        $scope.show_assign_product_button = false;
        $scope.show_assigning_product_button = true;
    
    
        // Start : un-assign selected products from appointment -- PRIYA BHOJANI -- //
        
        if($('.md-checked').length > 0)
        {
            if(window.confirm("Do you really want to un-assign from appointment ? "))
            {
                var products_to_unassign = $('.md-checked').map(function() 
                {
                    return $(this).attr("value");
                }).get().join(',');



                $scope.unassign_existing_products_data = {"products_to_unassign":products_to_unassign,"method_name": "unassignExistingProductsFromAppointment","appointment_id":$scope.selected_appointment_id.appointment_id};

                $http
                ({
                    method: 'POST',
                    url: 'products/api?token=' + localStorage.getItem('userToken'),
                    data: $scope.unassign_existing_products_data,
                    headers: {'Content-Type': 'application/json'}
                }) 
                .success(function (data) 
                {
                    console.log("Success");
                })
                .error(function (data) 
                {
                    if (data.error) 
                    {
                        window.location = "#/logout";
                    }
                });

                // End : un-assign selected products from appointment -- PRIYA BHOJANI -- //
            }
            else
            {
                alert("Don't worry , you products will not be un-assigned");
            }
        }
        
        
        // Start : assign selected products to appointment -- PRIYA BHOJANI -- //
       $scope.selected_products = [];
       $('.selected_products').each(function ()
       {
           if($.trim($(this).val()).length !== 0)
           {
                $scope.selected_products.push({
                    appointment_id: $scope.selected_appointment_id.appointment_id,
                    product_id: $(this).attr("data-product-id"),
                    product_quantity: $.trim($(this).val()),
                    product_status_by_installer: "Installed"
                });
            }
       });        
        
        $scope.assign_new_products_data = {"selected_products":$scope.selected_products,"method_name": "assignNewProductsToAppointment"};
        
        $http
        ({
            method: 'POST',
            url: 'products/api?token=' + localStorage.getItem('userToken'),
            data: $scope.assign_new_products_data,
            headers: {'Content-Type': 'application/json'}
        }) 
        .success(function (data) 
        {
            window.location = "#/products";            
        })
        .error(function (data) 
        {
            if (data.error) 
            {
                window.location = "#/logout";
            }
        });
        // Ens : assign selected products to appointment -- PRIYA BHOJANI -- //
        
    };
    // End : assignNewProductsToAppointment -- PRIYA BHOJANI -- //
    
    
    
    
    
    // Start : hwec question list -- PRIYA BHOJANI -- //
      
    $http
    ({
            method: 'POST',
            url: 'products/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "getInstallerQuestionsAndAnswers"},
            headers: {'Content-Type': 'application/json'}
    }) 
    .success(function (data) 
    {
        if (data.response_code === SUCCESS_CODE) 
        {
            $scope.installer_questions = data.data;            
        }
        else if (data.response_code === FAILURE_CODE) 
        {
            //window.location = "#/logout";
        }
    })
    .error(function (data) 
    {
        if (data.error) 
        {
            //window.location = "#/logout";
        }
    });
    
    
    // End : hwec question list -- PRIYA BHOJANI -- //
}]);

