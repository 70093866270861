hwecApp.controller('doorknockerWorkAreaController', ['PROVIDER','$filter', '$scope', '$rootScope', '$http', '$records_per_page', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', 'PROVIDER_ID', '$route', '$location', '$timeout', '$TIME_SLOT', 'AppointmentFactory', 'ProviderListFactory', 'CategoryListFactory', 'PRODUCT_PICTURE_PATH', '$APPOINTMENT_TIME_DETAIL', '$q', function (PROVIDER,$filter, $scope, $rootScope, $http, $records_per_page, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, PROVIDER_ID, $route, $location, $timeout, $TIME_SLOT, AppointmentFactory, ProviderListFactory, CategoryListFactory, PRODUCT_PICTURE_PATH, $APPOINTMENT_TIME_DETAIL, $q) { 
   
    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");
    
    $scope.add_doorknocker_work_area_button = true;
    $scope.hideBackBtn = true;
    
    $scope.pageTitle = "Doorknocker Work Area";
    $scope.add_doorknocker_work_area_link = "#/doorknocker/add_doorknocker_work_area";

    $scope.vm = {};
    $scope.delArray = [];

    $scope.data = {}
    $scope.doorDel = function(doorknockerDel,door) {


            if (doorknockerDel) {
                $scope.data[door] = door;
               // console.log($scope.data[door])
            }
            else if(!doorknockerDel){

                angular.forEach($scope.data,function(vals,keys){

                    if(vals == door){
                      delete $scope.data[door];
                    }
                });
            }
    }
    
    $scope.confirmDelete = function (data) {


        $http
        ({
            method: 'POST',
            url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
            data: {"method_name": "deleteDoorKnockerWorkAreaList","doorknocker_work_area_id":data},
            headers: {'Content-Type': 'application/json'}
        })
            .then(function successCallback(response)
                {
                    console.log(response.data)
                    loader.classList.add("hide-loader");
                    $scope.doorknocker_work_area_list = response.data.data;


                },
                function errorCallback(response)
                {
                   console.log(response.data);

                });
        
    }
    
    
    
    // Start : 
    $http
    ({
        method: 'POST',
        url: '/doorknockers/api?token=' + localStorage.getItem('userToken'),
        data: {"method_name": "doorknockerWorkAreaList"},
        headers: {'Content-Type': 'application/json'}
    })
    .then(function successCallback(response)
    {
        loader.classList.add("hide-loader");
        $scope.doorknocker_work_area_list = response.data.data;
        
    },
    function errorCallback(response) 
    {  
        window.location.href = "#/logout";
    });
    
}]);