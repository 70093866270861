hwecApp.controller('exportDataController', ['$filter', '$scope', '$http', 'SUCCESS_CODE', 'FAILURE_CODE', 'NOT_FOUND_CODE', '$route', '$location', 'ProviderListFactory', 'CategoryListFactory', function ($filter, $scope, $http, SUCCESS_CODE, FAILURE_CODE, NOT_FOUND_CODE, $route, $location, ProviderListFactory, CategoryListFactory, $window) {


 ///////////////////////////////Check current user logged //////////////////////////
///////////////////////////////////////////////////////////////////////////////////
    $scope.$user_id = localStorage.getItem('user_id');
 
    $http({
        method: 'GET',
        url: 'api/getAuthenticatedUser?token=' + localStorage.getItem('userToken'),
        data:null,
        headers: {'Content-Type': 'application/json'}
    }).success(function(data) {
        $scope.user_role_id = data["role"];

        console.log("rol de usuario " + $scope.user_role_id);
        console.log(data);
    }).error(function(data) {
        if(data.error) { window.location.href = "#/logout" };
    })  
 

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



    var loader = document.getElementById("loading-content");
    loader.classList.add("hide-loader");

    $scope.curentUrl = $location.path().replace('/', "").split('/')[0];
    $scope.itemId = $location.path().substr($location.path().lastIndexOf('/') + 1);

    //hide success message div 
    angular.element('#success_message_div').hide();

    //hide error message div
    angular.element('#error_message_div').hide();
        
    angular.element('#export_button').show();
    angular.element('#export_button_disabled').hide();
    
    $scope.exportData = function () 
    {
        loader.classList.remove("hide-loader");
        angular.element('#export_button').hide();
        angular.element('#export_button_disabled').show();
        
        var start_date = angular.element('#start_date').val();
        var end_date = angular.element('#end_date').val();       
        var api_name = angular.element('#report_type option:selected').val();
        
        if(start_date.length === 0 || end_date.length === 0)
        {
            loader.classList.add("hide-loader");
            angular.element('#error_message_div').show(); 
            angular.element('#error_message_span').html("Select 'Start Date' and 'End Date' to export the data");
            
            angular.element('#export_button').show();
            angular.element('#export_button_disabled').hide();
        }
        else
        {
            loader.classList.add("hide-loader");
            angular.element('#success_message_div').hide();
            angular.element('#error_message_div').hide();
                
            $scope.export_csv_data = {
                        "method_name": api_name,
                        "start_date" : start_date,
                        "end_date" : end_date                  
                    };
             $scope.export_csv_data = JSON.stringify($scope.export_csv_data);
   
            // start : send filters to server -- //
            $http
            ({
                method: 'POST',
                url: 'exportdata/api?token=' + localStorage.getItem('userToken'),
                data: $scope.export_csv_data,
                headers: {'Content-Type': 'application/json'}
            }) 
            .success(function(data) 
            {
                loader.classList.add("hide-loader");
                setTimeout(function()
                { 
                    angular.element('#export_button').hide();
                angular.element('#export_button_disabled').show();
                
                if (data.response_code === FAILURE_CODE)
                {
                   angular.element('#error_message_div').show(); 
                   angular.element('#error_message_span').html(data.response_message);
                }
                else
                {
                    angular.element('#success_message_div').show();
                    angular.element('#success_message_span').html(data.data.csv_file); 
                }

                angular.element('#export_button').show();
                angular.element('#export_button_disabled').hide();
                }, 5000);

 
                
            })
            .error(function(data) 
            { 
                loader.classList.add("hide-loader");
                if(data.error) { window.location.href = "#/logout" };
            });
            // start : send filters to server -- //
        }
    };
 
}]);