hwecApp.factory('CategoryListFactory', function($http) {
	var categoryList = {
		async: function() {
			var promise = 
			$http({
			  	method: 'POST', 
			    url: '/categories/api?token=' + localStorage.getItem('userToken'),
			  	data:{"method_name":"listData"},
				headers: {'Content-Type': 'application/json'} 
			  }).success(function (response) {
			  	return response;
			  	console.log(response);
			  }).error(function(response) {
			  	return response;
			  });
			return promise;  
		}
	};
	return categoryList;
});

